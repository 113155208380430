import { Card, CardContent, CircularProgress, Grid2 as Grid, Typography } from '@mui/material'
import useAuth from 'hooks/useAuth'

import { usePlan } from 'hooks/usePlans'
import { useFetchTemplate } from 'hooks/useTemplates'
import { useFetchUser } from 'hooks/useUsers'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { CreatePlanContextProvider } from '../../contexts/CreatePlanContextProvider'

import CreatePlanWizard from './CreatePlanWizard'

function CreatePlanForEmployee() {
  const [searchParams] = useSearchParams()
  const templateId = searchParams.get('templateId') || undefined
  const userId = searchParams.get('userId') || undefined
  const withUserId = searchParams.get('withUserId') || undefined
  const planId = searchParams.get('planId') || undefined
  const revision = searchParams.get('revision') || undefined
  const withBaseSalary =
    searchParams.get('withBaseSalary') ? parseFloat(searchParams.get('withBaseSalary')!) : undefined
  const withVariablePercent =
    searchParams.get('withVariablePercent') ? parseFloat(searchParams.get('withVariablePercent')!) : undefined
  const withVariableCap =
    searchParams.get('withVariableCap') ? parseFloat(searchParams.get('withVariableCap')!) : undefined
  const withStartDate = searchParams.get('withStartDate') || undefined
  const review = searchParams.get('review') === 'true'

  // Parse allocation and accelerator arrays
  const withAllocations = searchParams.get('withAllocations')?.split(',').map(Number) || undefined
  const withAccelerators = searchParams.get('withAccelerators')?.split(',').map(Number) || undefined

  const { token } = useAuth()

  const planParams = useMemo(() => {
    const params: Record<string, string> = {}
    if (planId) params.planId = planId
    if (revision) params.revision = revision
    return Object.keys(params).length > 0 ? params : null
  }, [planId, revision])

  const { compPlan, isLoading: planLoading, notFound } = usePlan(token!, userId, planParams)

  const templateParams = useMemo(() => {
    if (compPlan?.plan?.templateRevision) {
      return [['revision', compPlan.plan.templateRevision.toString()] as [string, string]]
    }
    return null
  }, [compPlan])

  const { compensationTemplate, isLoading: templateLoading } = useFetchTemplate(
    token!,
    templateId || compPlan?.plan?.templateId || undefined,
    templateParams
  )

  // Fetch user profile when in review mode
  const { userProfile, isLoading: userLoading } = useFetchUser(
    token!,
    userId || withUserId || null,
    [['explicitRolesOnly', 'true']],
    review
  )

  useEffect(() => {
    console.log('compensationTemplate', compensationTemplate)
  }, [compensationTemplate])

  const isLoading = planLoading || templateLoading || userLoading || (!compensationTemplate?.template.id && !notFound)

  if (isLoading) {
    return (
      <Grid
        container
        spacing={12}
      >
        <Grid
          size={{ xs: 12 }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress color='primary' />
          Loading
        </Grid>
      </Grid>
    )
  }

  if (!compPlan && userId) {
    return (
      <Grid
        container
        spacing={2}
      >
        <Grid size={{ xs: 12 }}>
          <Card>
            <CardContent>
              <Typography variant='h5'>No valid compensation plan found</Typography>
              <Typography variant='body1'>Please contact your manager or HR department for assistance.</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  const showOriginalEffectiveDate = planId !== undefined && revision !== undefined

  return (
    <>
      <CreatePlanContextProvider
        compensationTemplate={compensationTemplate}
        existingPlan={compPlan}
        showOriginalEffectiveDate={showOriginalEffectiveDate}
        withUserId={withUserId}
        withBaseSalary={withBaseSalary}
        withVariablePercent={withVariablePercent}
        withVariableCap={withVariableCap}
        withAllocations={withAllocations}
        withAccelerators={withAccelerators}
        withStartDate={withStartDate}
        review={review}
        employeeName={userProfile?.user ? `${userProfile.user.firstName} ${userProfile.user.lastName}` : undefined}
      >
        <CreatePlanWizard />
      </CreatePlanContextProvider>
    </>
  )
}

export default CreatePlanForEmployee
