import type { Theme } from '@mui/material'
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'

interface OperatorLegendProps {
  theme: Theme
}

const OperatorLegend: React.FC<OperatorLegendProps> = ({ theme }) => {
  const operators = [
    {
      category: 'Available Operators',
      operators: [
        { name: 'Parentheses ()', example: '(1 + 2) * 3 = 9 (enforces order of operations)' },
        { name: 'Addition (+)', example: '5 + 3 = 8' },
        { name: 'Subtraction (-)', example: '10 - 4 = 6' },
        { name: 'Division (/)', example: '15 / 3 = 5' },
        { name: 'Multiplication (*)', example: '4 * 3 = 12' },
        { name: 'Exponentiation (**)', example: '2 ** 3 = 8' },
        { name: 'Modulus (%)', example: '10 % 3 = 1 (remainder)' },
        // { name: 'Right Shift (>>)', example: '8 >> 1 = 4 (binary shift right)' },
        // { name: 'Left Shift (<<)', example: '1 << 2 = 4 (binary shift left)' },
        { name: 'Greater Than (>)', example: '5 > 3 is true' },
        { name: 'Greater Than or Equal (>=)', example: '5 >= 5 is true' },
        { name: 'Less Than (<)', example: '3 < 5 is true' },
        { name: 'Less Than or Equal (<=)', example: '5 <= 5 is true' },
        { name: 'Equal (==)', example: '5 == 5 is true' },
        { name: 'Not Equal (!=)', example: '5 != 3 is true' },
        // { name: 'Regex Match (=~)', example: '"hello" =~ "he" is true' },
        // { name: 'Regex Not Match (!~)', example: '"hello" !~ "xy" is true' },
      ],
    },
  ]

  return (
    <Box
      maxHeight='calc(65vh)' // 65% of viewport height
      overflow='auto'
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '15px',
        p: 0,
      }}
    >
      {operators.map((category, index) => (
        <React.Fragment key={category.category}>
          <Typography
            variant='h5'
            align='center'
            mt={2}
            mb={1}
          >
            {category.category}
          </Typography>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Operator</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Example</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {category.operators.map(op => (
                  <TableRow key={op.name}>
                    <TableCell>{op.name}</TableCell>
                    <TableCell>{op.example}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {index < operators.length - 1 && <Divider sx={{ my: 2 }} />}
        </React.Fragment>
      ))}
    </Box>
  )
}

export default OperatorLegend
