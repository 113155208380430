import type { TextFieldVariants } from '@mui/material'
import { TextField } from 'components/@extended/TextField'
import CurrencyField from 'components/CurrencyField'
import DecimalField from 'components/DecimalField'
import PercentageField from 'components/PercentageField'
import TrueFalseSelector from 'components/TrueFalseSelector'
import WholeNumberField from 'components/WholeNumberField'
import React from 'react'
import { ComponentSourceType, ComponentType } from 'types/components'

interface ComponentFieldProps {
  id?: string
  componentType: ComponentType
  sourceType?: ComponentSourceType
  label?: string
  value: string | number | boolean | null
  onChange: (value: any) => void
  name: string
  currency?: string
  currencyDecimals?: number
  decimalPlaces?: number
  disabled?: boolean
  placeholder?: string
  style?: React.CSSProperties
  variant?: TextFieldVariants
  pill?: boolean
  shrink?: boolean
}

const ComponentField: React.FC<ComponentFieldProps> = ({
  id,
  componentType,
  sourceType = ComponentSourceType.COMPONENT_SOURCE_MANUAL,
  label,
  value,
  onChange,
  name,
  currency = 'USD',
  currencyDecimals = 0,
  decimalPlaces = 2,
  disabled = false,
  placeholder,
  style,
  variant = 'outlined',
  pill = false,
  shrink = false,
}) => {
  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      return (
        <CurrencyField
          id={id}
          label={label}
          value={value?.toString() ?? ''}
          onChange={onChange}
          name={name}
          currency={currency}
          currencyDecimals={currencyDecimals}
          disabled={disabled}
          placeholder={placeholder}
          style={style}
          variant={variant}
          pill={pill}
          shrink={shrink}
          sourceType={sourceType}
        />
      )
    case ComponentType.COMPONENT_TYPE_INTEGER:
      return (
        <WholeNumberField
          id={id}
          label={label}
          value={value?.toString() ?? ''}
          onChange={onChange}
          name={name}
          style={style}
          variant={variant}
          pill={pill}
          shrink={shrink}
          // sourceType={sourceType}
        />
      )
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return (
        <DecimalField
          id={id}
          label={label}
          value={value?.toString() ?? ''}
          onChange={onChange}
          name={name}
          decimalPlaces={decimalPlaces}
          disabled={disabled}
          placeholder={placeholder}
          style={style}
          variant={variant}
          pill={pill}
          shrink={shrink}
          // sourceType={sourceType}
        />
      )
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return (
        <PercentageField
          id={id}
          label={label}
          value={value?.toString() ?? ''}
          onChange={onChange}
          name={name}
          style={style}
          variant={variant}
          pill={pill}
          shrink={shrink}
          // sourceType={sourceType}
        />
      )
    case ComponentType.COMPONENT_TYPE_BOOL:
      return (
        <TrueFalseSelector
          id={id}
          label={label}
          value={value as boolean}
          onChange={onChange}
          name={name}
          style={style}
          pill={pill}
          shrink={shrink}
          // sourceType={sourceType}
        />
      )
    default:
      return (
        <TextField
          id={id}
          label={label}
          variant={variant}
          fullWidth
          type='text'
          value={value?.toString() ?? ''}
          onChange={e => onChange(e.target.value)}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          style={style}
          pill={pill}
          shrink={shrink}
        />
      )
  }
}

export default ComponentField
