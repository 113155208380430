import { CardContent, Divider, Grid2 as Grid, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import ComponentDisplay from 'components/ComponentDisplay'
import React from 'react'
import type { ExpandedApprovalItem, UserMeta } from 'types/approvals'
import type { Component, Metric } from 'types/components'
import { ComponentType } from 'types/components'

interface MetricTableProps {
  organizationName: string
  items: ExpandedApprovalItem[]
}

interface MetricRowProps {
  component?: Component
  metric?: Metric
  periodLabel?: string
  rootPeriodLabel?: string
  user?: UserMeta
}

const MetricRow: React.FC<MetricRowProps> = ({ component, metric, periodLabel, rootPeriodLabel, user }) => {
  if (component == null || metric == null) {
    return <></>
  }

  // Parse sourceInfo if it's a string
  let sourceInfo = component.sourceInfo
  if (typeof sourceInfo === 'string') {
    sourceInfo = JSON.parse(sourceInfo)
  }

  const assignedLabel = component.isOrganizationLevel ? 'Team' : `${user?.firstName} ${user?.lastName}` || 'Unknown'

  return (
    <Grid
      container
      spacing={3}
      alignItems='center'
    >
      <Grid size={{ xs: 12, sm: 4 }}>
        <Typography variant='h6'>{component.label}</Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Typography align='center'>{assignedLabel}</Typography>
      </Grid>
      <Grid
        size={{ xs: 12, sm: 4 }}
        sx={{ textAlign: 'center' }}
      >
        <ComponentDisplay
          componentType={component.componentType as ComponentType}
          value={metric.value}
          sourceType={component.sourceType}
          compositeFunction={sourceInfo?.compositeFunction}
          periodLabel={periodLabel}
          fontSize='14px'
        />
      </Grid>
    </Grid>
  )
}

const MetricTable: React.FC<MetricTableProps> = ({ organizationName, items }) => {
  if (!items || items.length === 0) {
    return <></>
  }

  // Group items by period
  const groupedItems = items
    .filter(item => item.approvalType === 'metric')
    .filter(item => item.periodComponents != null && item.periodComponents.length > 0)
    .filter(item => item.components != null && item.components.length > 0)
    .reduce(
      (acc, item) => {
        const periodLabel = item.periodLabel || 'Unknown Period'
        if (!acc[periodLabel]) {
          acc[periodLabel] = []
        }
        acc[periodLabel].push(item)
        return acc
      },
      {} as Record<string, ExpandedApprovalItem[]>
    )

  // Custom sort function for period labels
  const sortPeriodLabels = (a: string, b: string) => {
    const order = [
      'FY',
      'H1',
      'H2',
      'Q1',
      'Q2',
      'Q3',
      'Q4',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const getOrderIndex = (label: string) => {
      for (let i = 0; i < order.length; i++) {
        if (label.includes(order[i])) {
          return i
        }
      }
      return order.length + label.localeCompare(label) // For alphabetical sorting of other values
    }

    return getOrderIndex(a) - getOrderIndex(b)
  }

  const sortedPeriodLabels = Object.keys(groupedItems).sort(sortPeriodLabels)

  return (
    <div style={{ width: '100%' }}>
      {sortedPeriodLabels.map(periodLabel => (
        <div
          key={periodLabel}
          style={{ marginBottom: '20px' }}
        >
          <Typography
            variant='h3'
            mb={2}
          >{`${organizationName} ${periodLabel} Actuals`}</Typography>
          <Paper sx={{ paddingTop: 1, width: '100%', marginBottom: '40px' }}>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                <Grid size={{ xs: 4 }}>
                  <Typography variant='h5'>Actual</Typography>
                </Grid>
                <Grid size={{ xs: 4 }}>
                  <Typography
                    variant='h5'
                    align='center'
                  >
                    Owner
                  </Typography>
                </Grid>
                <Grid size={{ xs: 4 }}>
                  <Typography
                    variant='h5'
                    align='center'
                  >
                    Value
                  </Typography>
                </Grid>
              </Grid>
              {groupedItems[periodLabel].map(item => {
                const component = item.components![0]
                const metric = item.periodComponents![0]
                const rootPeriodLabel = item.rootPeriodLabel
                const user = item.user // Assuming user is attached to the item

                const compositeId = `${component.id}_${metric.periodId}`

                return (
                  <div key={compositeId}>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <Divider />
                    </div>

                    <MetricRow
                      component={component}
                      metric={metric}
                      periodLabel={periodLabel}
                      rootPeriodLabel={rootPeriodLabel}
                      user={user} // Pass user to MetricRow
                    />
                  </div>
                )
              })}
            </CardContent>
          </Paper>
        </div>
      ))}
    </div>
  )
}

export default MetricTable
