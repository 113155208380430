import { DeleteOutlined, DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AnimateButton from 'components/@extended/AnimateButton'
import Card from 'components/@extended/Card'
import Paper from 'components/@extended/Paper'
import React, { FC, useEffect, useState } from 'react'

import { TextField } from 'components/@extended/TextField'
import ComponentDisplay from 'components/ComponentDisplay'
import ComponentField from 'components/ComponentField'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useSearchNeededTargetMetrics } from 'hooks/useComponents'
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { usePeriods } from 'hooks/usePeriods'
import { useSnackbar } from 'notistack'
import { startTransition } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  type ComponentType,
  type CompositeActualValue,
  type CreateMetricDTO,
  type NeededTargetMetric,
  type SearchNeededTargetMetricsParams,
  ComponentSourceType,
  getCompositeFunction,
} from 'types/components'
import type { OrganizationCoreSetting } from 'types/organization'
import { createIdLabelMap } from 'types/periods'
import type { UserProfile } from 'types/user-profile'
import { getOrganizationLabelMap } from 'types/user-profile'

const CreateMetrics: FC = () => {
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [isReviewMode, setIsReviewMode] = useState(false)
  const [metrics, setMetrics] = useState<NeededTargetMetric[]>([])
  const [coreSettingsMap, setCoreSettingsMap] = useState<Record<string, OrganizationCoreSetting>>({})
  const [limit, setLimit] = useState<number>(100)
  const [excludeMetricsEntered, setExcludeMetricsEntered] = useState<boolean>(true)
  const [createMetricDTOs, setCreateMetricDTOs] = useState<CreateMetricDTO[]>([])
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({})
  const [compositeValues, setCompositeValues] = useState<Record<number, CompositeActualValue>>({})

  const theme = useTheme()
  const { token, profile, isLoading: isLoadingProfile } = useAuth()
  const { purcentAppState } = useGlobalState()
  const { periods, isLoadingPeriods } = usePeriods(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || ''
  )

  const searchParams: SearchNeededTargetMetricsParams = {
    organizationIds: selectedOrganizations,
    periodIds: selectedPeriods,
    limit,
    excludeMetricsEntered,
  }

  const { targets, isLoading: isLoadingTargets } = useSearchNeededTargetMetrics(
    token!,
    searchParams,
    selectedOrganizations.length > 0 && selectedPeriods.length > 0
  )

  const { coreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    selectedOrganizations,
    token!
  )

  const periodLabelMap: Record<string, string> = (periods?.periods || []).reduce(
    (acc, period) => ({
      ...acc,
      ...createIdLabelMap(period),
    }),
    {}
  )
  const organizationLabelMap: Record<string, string> = getOrganizationLabelMap(profile as UserProfile)

  useEffect(() => {
    if (targets) {
      // Set blank newValue for each metric
      const metricsWithBlankNewValues = targets.map((target) => ({
        ...target,
        newValue: target.currentActualValue || '',
      }))
      setMetrics(metricsWithBlankNewValues)

      // Initialize composite values for composite metrics
      const initialCompositeValues: Record<number, CompositeActualValue> = {}
      metricsWithBlankNewValues.forEach((metric, index) => {
        if (metric.sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE) {
          if (metric.currentActualValue) {
            try {
              initialCompositeValues[index] = JSON.parse(metric.currentActualValue)
            } catch (e) {
              initialCompositeValues[index] = { values: [] }
            }
          } else {
            initialCompositeValues[index] = { values: [] }
          }
        }
      })
      setCompositeValues(initialCompositeValues)
    }
  }, [targets])

  useEffect(() => {
    if (coreSettings && coreSettings.length > 0) {
      const newCoreSettingsMap = coreSettings.reduce(
        (acc, setting) => {
          acc[setting.organizationId] = setting
          return acc
        },
        {} as Record<string, OrganizationCoreSetting>
      )
      setCoreSettingsMap(newCoreSettingsMap)
    }
  }, [coreSettings])

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
  }

  const handlePeriodChange = (newPeriods: string[]) => {
    setSelectedPeriods(newPeriods)
  }

  const handleMetricChange = (index: number, newValue: any) => {
    const updatedMetrics = [...metrics]
    updatedMetrics[index].newValue = newValue
    setMetrics(updatedMetrics)
  }

  const handleDeleteMetric = (index: number) => {
    const updatedMetrics = metrics.filter((_, i) => i !== index)
    setMetrics(updatedMetrics)
  }

  const handleReview = () => {
    const newCreateMetricDTOs = metrics
      .filter((metric) => metric.newValue !== undefined && metric.newValue !== '')
      .map((metric) => ({
        componentId: metric.componentId,
        organizationId: metric.organizationId,
        rootOrganizationId: metric.rootOrganizationId,
        periodId: metric.periodId,
        rootPeriodId: metric.rootPeriodId,
        userId: metric.userId,
        value: metric.newValue as string,
      }))
    setCreateMetricDTOs(newCreateMetricDTOs)
    setIsReviewMode(true)
  }

  const handleBack = () => {
    setIsReviewMode(false)
    setCreateMetricDTOs([])
  }

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = () => {
    const metricsToSubmit = createMetricDTOs.map((dto) => ({
      componentId: dto.componentId,
      rootOrganizationId: dto.rootOrganizationId,
      organizationId: dto.organizationId,
      rootPeriodId: dto.rootPeriodId,
      periodId: dto.periodId,
      userId: dto.userId,
      value: dto.value,
    }))

    postWithToken('components/metrics', token!, metricsToSubmit)
      .then((response) => {
        enqueueSnackbar('Actuals submitted successfully', { variant: 'success' })
        // Reset the form
        setIsReviewMode(false)
        setSelectedOrganizations([])
        setSelectedPeriods([])
        setMetrics([])
        setCreateMetricDTOs([])
      })
      .catch((error) => {
        const errorMessage = error.message || 'Failed to submit metrics'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
      .finally(() => startTransition(() => navigate('/admin/components/actuals')))
  }

  const toggleRowExpansion = (index: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const handleAddCompositeValue = (index: number) => {
    const currentValues = compositeValues[index] || { values: [] }
    const newCompositeValues = {
      ...compositeValues,
      [index]: {
        values: [
          ...currentValues.values,
          {
            label: `Value ${currentValues.values.length + 1}`,
            description: 'Enter description',
            value: '0',
          },
        ],
      },
    }
    setCompositeValues(newCompositeValues)

    // Update the metric's newValue with the JSON string
    const updatedMetrics = [...metrics]
    updatedMetrics[index].newValue = JSON.stringify(newCompositeValues[index])
    setMetrics(updatedMetrics)
  }

  const handleRemoveCompositeValue = (metricIndex: number, valueIndex: number) => {
    if (!compositeValues[metricIndex]) return

    const newValues = {
      ...compositeValues,
      [metricIndex]: {
        values: compositeValues[metricIndex].values.filter((_, i) => i !== valueIndex),
      },
    }
    setCompositeValues(newValues)

    // Update the metric's newValue with the JSON string
    const updatedMetrics = [...metrics]
    updatedMetrics[metricIndex].newValue = JSON.stringify(newValues[metricIndex])
    setMetrics(updatedMetrics)
  }

  const handleUpdateCompositeValue = (
    metricIndex: number,
    valueIndex: number,
    field: 'label' | 'description' | 'value',
    newValue: string
  ) => {
    if (!compositeValues[metricIndex]) return

    const newValues = {
      ...compositeValues,
      [metricIndex]: {
        values: compositeValues[metricIndex].values.map((item, i) =>
          i === valueIndex ? { ...item, [field]: newValue } : item
        ),
      },
    }
    setCompositeValues(newValues)

    // Update the metric's newValue with the JSON string
    const updatedMetrics = [...metrics]
    updatedMetrics[metricIndex].newValue = JSON.stringify(newValues[metricIndex])
    setMetrics(updatedMetrics)
  }

  const renderOrgPeriodConfig = () => {
    return (
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Team & Period Configuration
          </Typography>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, sm: 4 }}>
              {isLoadingProfile ? (
                <CircularProgress />
              ) : (
                <MultiOrganizationSelector
                  userProfile={profile!}
                  selectedOrganizationIds={selectedOrganizations}
                  handleChange={handleOrganizationChange}
                  label="Select Teams"
                  variant="outlined"
                  labelBackgroundColor={theme.palette.background.paper}
                />
              )}
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              {isLoadingPeriods ? (
                <CircularProgress />
              ) : (
                <MultiPeriodSelector
                  periods={periods.periods}
                  selectedPeriods={selectedPeriods}
                  handleChange={handlePeriodChange}
                  label="Select Periods"
                  timeFilter="pastOnly"
                  disableClosed
                  showLabelAbove
                  variant="outlined"
                  shrinkLabel
                  labelBackgroundColor={theme.palette.background.paper}
                />
              )}
            </Grid>
            <Grid size={{ xs: 12, sm: 1 }} sx={{ mt: -0.25 }}>
              <Typography variant="caption">Limit</Typography>
              <Select
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
                fullWidth
                size="small"
                variant="standard"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={1000}>1000</MenuItem>
              </Select>
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }} sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={excludeMetricsEntered}
                    onChange={(e) => setExcludeMetricsEntered(e.target.checked)}
                    color="primary"
                  />
                }
                label="Exclude Existing Actuals"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const renderMetricsList = () => {
    return (
      <>
        {isLoadingTargets || isLoadingCoreSettings ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper} sx={{ mb: 3 }}>
            <Table>
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  <TableCell sx={{ position: 'sticky !important' }}>Label</TableCell>
                  <TableCell sx={{ position: 'sticky !important' }}>Team / Employee</TableCell>
                  <TableCell sx={{ position: 'sticky !important' }}>Period</TableCell>
                  <TableCell sx={{ position: 'sticky !important' }}>Target</TableCell>
                  <TableCell sx={{ position: 'sticky !important' }}>Value</TableCell>
                  <TableCell sx={{ position: 'sticky !important' }} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metrics.map((metric, index) => {
                  const orgSettings = coreSettingsMap[metric.organizationId] || {
                    currency: 'USD',
                    currencyDecimals: 0,
                  }
                  const teamOrEmployee =
                    metric.firstName && metric.lastName
                      ? `${metric.firstName} ${metric.lastName}`
                      : metric.organizationId && organizationLabelMap[metric.organizationId]

                  const isComposite = metric.sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE
                  const isExpanded = expandedRows[index] || false

                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>{metric.label}</TableCell>
                        <TableCell>{teamOrEmployee}</TableCell>
                        <TableCell>{metric.periodId && periodLabelMap[metric.periodId]}</TableCell>
                        <TableCell>
                          <ComponentDisplay
                            componentType={metric.componentType as ComponentType}
                            value={metric.value}
                            currency={orgSettings.currency}
                            currencyDecimals={orgSettings.currencyDecimals}
                            decimalPlaces={2}
                          />
                        </TableCell>
                        <TableCell>
                          {isComposite ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
                              <ComponentDisplay
                                componentType={metric.componentType as ComponentType}
                                value={metric.newValue || '{"values":[]}'}
                                currency={orgSettings.currency}
                                currencyDecimals={orgSettings.currencyDecimals}
                                decimalPlaces={2}
                                sourceType={metric.sourceType}
                                compositeFunction={getCompositeFunction(metric.sourceInfo)}
                              />
                              <IconButton size="small" onClick={() => toggleRowExpansion(index)} sx={{ marginLeft: 2 }}>
                                {isExpanded ? <UpOutlined /> : <DownOutlined />}
                              </IconButton>
                            </Box>
                          ) : (
                            <ComponentField
                              componentType={metric.componentType as ComponentType}
                              label=""
                              value={metric.newValue || ''}
                              onChange={(value) => handleMetricChange(index, value)}
                              name={`metric-${index}`}
                              currency={orgSettings.currency}
                              currencyDecimals={orgSettings.currencyDecimals}
                              decimalPlaces={2}
                              sourceType={metric.sourceType}
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => handleDeleteMetric(index)} size="small">
                            <DeleteOutlined />
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      {/* Expandable row for composite metrics */}
                      {isComposite && (
                        <TableRow>
                          <TableCell colSpan={6} sx={{ padding: 0, border: 0 }}>
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                              <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mt: 2 }}>
                                  <Typography variant="h5">Composite Values for {metric.label}</Typography>
                                  <Button
                                    startIcon={<PlusOutlined />}
                                    size="small"
                                    onClick={() => handleAddCompositeValue(index)}
                                  >
                                    Add Value
                                  </Button>
                                </Box>
                                <TableContainer>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell width="20%">Label</TableCell>
                                        <TableCell width="50%">Description</TableCell>
                                        <TableCell width="20%">Value</TableCell>
                                        <TableCell width="10%" align="center">
                                          Actions
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {compositeValues[index]?.values.map((item, valueIndex) => (
                                        <TableRow key={valueIndex}>
                                          <TableCell>
                                            <TextField
                                              value={item.label}
                                              onChange={(e) =>
                                                handleUpdateCompositeValue(index, valueIndex, 'label', e.target.value)
                                              }
                                              fullWidth
                                              variant="outlined"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              value={item.description}
                                              onChange={(e) =>
                                                handleUpdateCompositeValue(
                                                  index,
                                                  valueIndex,
                                                  'description',
                                                  e.target.value
                                                )
                                              }
                                              fullWidth
                                              variant="outlined"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <ComponentField
                                              componentType={metric.componentType as ComponentType}
                                              label=""
                                              value={item.value}
                                              onChange={(value) =>
                                                handleUpdateCompositeValue(index, valueIndex, 'value', value)
                                              }
                                              name={`metric-${index}-composite-${valueIndex}`}
                                              currency={orgSettings.currency}
                                              currencyDecimals={orgSettings.currencyDecimals}
                                              decimalPlaces={2}
                                            />
                                          </TableCell>
                                          <TableCell align="center">
                                            <IconButton
                                              size="small"
                                              onClick={() => handleRemoveCompositeValue(index, valueIndex)}
                                            >
                                              <DeleteOutlined />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      {(!compositeValues[index] || compositeValues[index].values.length === 0) && (
                                        <TableRow>
                                          <TableCell colSpan={4} align="center">
                                            <Typography variant="body2" color="textSecondary">
                                              No values added yet. Click "Add Value" to begin.
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    )
  }

  const renderReviewConfig = () => {
    return (
      <>
        <Paper sx={{ mb: 3 }}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Label</TableCell>
                  <TableCell>Team / Employee</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {createMetricDTOs.map((dto, index) => {
                  const metric = metrics.find(
                    (m) => m.componentId === dto.componentId && m.periodId === dto.periodId && m.userId === dto.userId
                  )
                  if (!metric) return null

                  // Parse sourceInfo if it's a string
                  if (typeof metric.sourceInfo === 'string') {
                    metric.sourceInfo = JSON.parse(metric.sourceInfo)
                  }

                  const teamOrEmployee =
                    metric.firstName && metric.lastName
                      ? `${metric.firstName} ${metric.lastName}`
                      : metric.organizationId && organizationLabelMap[metric.organizationId]

                  const orgSettings = coreSettingsMap[metric.organizationId] || {
                    currency: 'USD',
                    currencyDecimals: 0,
                  }

                  return (
                    <TableRow key={index}>
                      <TableCell>{metric.label}</TableCell>
                      <TableCell>{teamOrEmployee}</TableCell>
                      <TableCell>{metric.periodId && periodLabelMap[metric.periodId]}</TableCell>
                      <TableCell>
                        <ComponentDisplay
                          componentType={metric.componentType as ComponentType}
                          value={metric.value}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                        />
                      </TableCell>
                      <TableCell>
                        <ComponentDisplay
                          componentType={metric.componentType as ComponentType}
                          value={dto.value}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                          sourceType={metric.sourceType}
                          compositeFunction={metric.sourceInfo?.compositeFunction}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    )
  }

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {isReviewMode ? 'Review Actuals' : 'Configure Actuals'}
      </Typography>
      {!isReviewMode && renderOrgPeriodConfig()}
      {!isReviewMode && renderMetricsList()}
      {!isReviewMode ? (
        <Box mt={3}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <AnimateButton>
              <Button
                disabled={isLoadingTargets}
                variant="contained"
                color="warning"
                onClick={() => {
                  startTransition(() => {
                    navigate('/admin/components/actuals')
                  })
                }}
              >
                Cancel
              </Button>
            </AnimateButton>
            <Button variant="contained" onClick={handleReview} disabled={isLoadingTargets}>
              Review
            </Button>
          </Stack>
        </Box>
      ) : (
        renderReviewConfig()
      )}
      {isReviewMode && (
        <Box mt={3}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <AnimateButton>
              <Button
                disabled={isLoadingTargets}
                variant="contained"
                color="warning"
                onClick={() => {
                  startTransition(() => {
                    navigate('/admin/components/actuals')
                  })
                }}
              >
                Cancel
              </Button>
            </AnimateButton>

            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Submit Metrics
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  )
}

export default CreateMetrics
