import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import AddIcon from '@mui/icons-material/Add'
import type { Theme } from '@mui/material'
import {
  Alert,
  alpha,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import AnimateButton from 'components/@extended/AnimateButton'
import { TextField } from 'components/@extended/TextField'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useFetchUser } from 'hooks/useUsers'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { LedgerAccount } from 'types/ledger-account'
import type { OrganizationSetting } from 'types/organization'
import { Permissions } from 'types/permissions'
import type { Role } from 'types/role'
import type { UserData } from 'types/user'
import type { UserProfile } from 'types/user-profile'
import { getUniqueOrganizationRoles } from 'types/user-profile'

interface CreateOrUpdateUserDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (userData: UserData) => void
  onDelete: (userId: string, organizationId: string) => Promise<void>
  organizationName: string
  organizationId: string
  user?: UserData
  theme: Theme
  roles: Role[]
  isLoading: boolean
  error: Error | null
  ledgerAccounts: LedgerAccount[]
  organizationSettings: OrganizationSetting[]
  userProfile: UserProfile
  token: string
}

const CreateOrUpdateUserDialog: FC<CreateOrUpdateUserDialogProps> = ({
  open,
  onClose,
  onSubmit,
  onDelete,
  organizationName,
  organizationId,
  user,
  theme,
  roles,
  isLoading,
  error,
  ledgerAccounts,
  organizationSettings,
  userProfile,
  token,
}) => {
  const defaultLedgerAccountSetting = organizationSettings.find(
    (setting) => setting.settingKey === 'default-ledger-account'
  )

  const defaultLedgerAccountId = defaultLedgerAccountSetting?.settingValue || ''

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [position, setPosition] = useState('')
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [ledgerAccountId, setLedgerAccountId] = useState('')
  const [teamAssignments, setTeamAssignments] = useState<UserData['roles']>([
    { organizationId, roleId: '', delete: false },
  ])
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const { userProfile: latestUserProfile, isLoading: isLoadingUser } = useFetchUser(
    token,
    user?.id || '',
    [['explicitRolesOnly', 'true']],
    !!user
  )

  useEffect(() => {
    if (latestUserProfile) {
      setFirstName(latestUserProfile.user.firstName)
      setLastName(latestUserProfile.user.lastName)
      setEmail(latestUserProfile.user.email)
      setPosition(latestUserProfile.user.position)
      setStartDate(dayjs(latestUserProfile.user.startDate))
      setEndDate(latestUserProfile.user.endDate ? dayjs(latestUserProfile.user.endDate) : null)
      setLedgerAccountId(latestUserProfile.user.ledgerAccountId || '')
      setTeamAssignments(
        latestUserProfile.roles.map((role) => ({
          organizationId: role.organization.id,
          roleId: role.role.id,
          delete: false,
        }))
      )
    } else {
      setFirstName('')
      setLastName('')
      setEmail('')
      setPosition('')
      setStartDate(null)
      setEndDate(null)
      setLedgerAccountId(defaultLedgerAccountId)
      setTeamAssignments([{ organizationId, roleId: '', delete: false }])
    }
  }, [latestUserProfile, defaultLedgerAccountId, organizationId])

  const canChangeRoles = (organizationId: string): string[] => {
    if (!userProfile) return []

    const roleForOrganization = getUniqueOrganizationRoles(userProfile).find(
      (userRole) => userRole.organization.id === organizationId
    )

    if (!roleForOrganization) return []

    // Greater than or equal to the role for the organization since lower # is higher ranking
    return roles.filter((role) => role.rank >= roleForOrganization.role.rank).map((role) => role.name)
  }

  const hasMemberWritePermission = (organizationId: string): boolean => {
    const roleForOrganization = getUniqueOrganizationRoles(userProfile).find(
      (userRole) => userRole.organization.id === organizationId
    )
    console.log('roleForOrganization', roleForOrganization)
    return roleForOrganization?.role.permissions.includes(Permissions.MEMBERS_WRITE) || false
  }

  const handleTeamAssignmentChange = (
    index: number,
    field: 'organizationId' | 'roleId' | 'delete',
    value: string | boolean
  ) => {
    const newAssignments = [...teamAssignments]
    if (field === 'delete') {
      newAssignments[index][field] = value as boolean
    } else {
      newAssignments[index][field] = value as string
      if (field === 'organizationId') {
        newAssignments[index].roleId = ''
      }
    }
    setTeamAssignments(newAssignments)
  }

  const addTeamAssignment = () => {
    setTeamAssignments([...teamAssignments, { organizationId: '', roleId: '', delete: false }])
  }

  const removeTeamAssignment = (index: number) => {
    const newAssignments = [...teamAssignments]
    newAssignments[index].delete = !newAssignments[index].delete
    setTeamAssignments(newAssignments)
  }

  const validateMemberRole = (assignments: UserData['roles'], roles: Role[]): boolean => {
    const memberRoleCount = assignments
      .filter((assignment) => !assignment.delete)
      .filter((assignment) => {
        const role = roles.find((r) => r.id === assignment.roleId)
        return role?.name === 'Member'
      }).length

    return memberRoleCount === 1
  }

  const isFormValid = () => {
    const hasMemberRole = validateMemberRole(teamAssignments, roles)

    return (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      email.trim() !== '' &&
      startDate !== null &&
      ledgerAccountId !== '' &&
      hasMemberRole &&
      teamAssignments.some((assignment) =>
        user
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      )
    )
  }

  const getValidationMessages = () => {
    const messages: string[] = []

    if (firstName.trim() === '') messages.push('First name is required')
    if (lastName.trim() === '') messages.push('Last name is required')
    if (email.trim() === '') messages.push('Email is required')
    if (!startDate) messages.push('Start date is required')
    if (ledgerAccountId === '') messages.push('Ledger account is required')

    const memberRoleCount = teamAssignments
      .filter((assignment) => !assignment.delete)
      .filter((assignment) => {
        const role = roles.find((r) => r.id === assignment.roleId)
        return role?.name === 'Member'
      }).length

    if (memberRoleCount === 0) messages.push('User must have one "Member" role')
    if (memberRoleCount > 1) messages.push('User cannot have more than one "Member" role')

    if (
      !teamAssignments.some((assignment) =>
        user
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      )
    ) {
      messages.push('At least one team assignment is required')
    }

    return messages.join('\n')
  }

  const handleSubmit = () => {
    if (!validateMemberRole(teamAssignments, roles)) {
      setSnackbar({
        open: true,
        message: 'User must have exactly one "Member" role',
        severity: 'error',
      })
      return
    }

    const userData: UserData = {
      id: user?.id,
      firstName,
      lastName,
      email,
      position,
      roles: teamAssignments.filter((assignment) =>
        user
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      ),
      ledgerAccountId,
      startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
      endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
    }

    try {
      onSubmit(userData)
      setSnackbar({
        open: true,
        message: `Successfully ${user ? 'updated' : 'created'} team member`,
        severity: 'success',
      })
      onClose()
    } catch (error) {
      console.error('Error handling user action:', error)
      setSnackbar({
        open: true,
        message: 'Error saving team member',
        severity: 'error',
      })
    }
  }

  const handleDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue)
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = () => {
    if (user?.id && onDelete) {
      onDelete(user.id, organizationId)
      setDeleteDialogOpen(false)
      onClose()
    }
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{user ? 'Edit' : 'Create New'} Team Member</Typography>
          {user && (
            <IconButton onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
              <DeleteOutlined />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          {isLoading || isLoadingUser ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  variant="outlined"
                  pill
                  shrink
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  variant="outlined"
                  pill
                  shrink
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  variant="outlined"
                  pill
                  shrink
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  disabled={!!user}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  variant="outlined"
                  pill
                  shrink
                  label="Position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <TextField
                  select
                  variant="outlined"
                  pill
                  shrink
                  label="Ledger Account"
                  value={ledgerAccountId}
                  onChange={(e) => setLedgerAccountId(e.target.value)}
                  fullWidth
                >
                  {ledgerAccounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleDateChange}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '21px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <DatePicker
                  label="End Date (optional)"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '21px',
                        },
                      },
                    },
                  }}
                />
              </Grid>

              <Grid size={{ xs: 12 }}>
                <Box sx={{ mt: 4, mb: 2 }}>
                  <Typography variant="h6">Add to Team(s)</Typography>
                </Box>
              </Grid>

              {teamAssignments.map((assignment, index) => {
                console.log('Processing Permissions to edit Role')
                const isNewRow = !assignment.delete && (!assignment.organizationId || !assignment.roleId)
                console.log('isNewRow', isNewRow)
                const canChangeRolesForOrg = assignment.organizationId ? canChangeRoles(assignment.organizationId) : []
                console.log('canChangeRolesForOrg', canChangeRolesForOrg)
                console.log('roles', roles)
                console.log('assignment.roleId', assignment.roleId)
                console.log('assignment.organizationId', assignment.organizationId)
                const hasWritePermission = isNewRow ? true : hasMemberWritePermission(assignment.organizationId)
                console.log('hasWritePermission', hasWritePermission)
                const currentRoleInAllowedRoles =
                  isNewRow || canChangeRolesForOrg.includes(roles.find((r) => r.id === assignment.roleId)?.name || '')
                console.log('currentRoleInAllowedRoles', currentRoleInAllowedRoles)
                const isDisabled =
                  assignment.delete || (!isNewRow && (!hasWritePermission || !currentRoleInAllowedRoles))
                console.log('isDisabled', isDisabled)
                const tooltipTitle = isDisabled ? 'Insufficient permissions or role has been disabled' : ''
                const rolesToShow = isDisabled
                  ? roles
                  : roles.filter((role) => canChangeRolesForOrg.includes(role.name))

                return (
                  <Grid size={{ xs: 12 }} key={index}>
                    <Tooltip title={tooltipTitle} arrow>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          alignItems: 'center',
                          opacity: isDisabled ? 0.5 : 1,
                          backgroundColor: isDisabled
                            ? (theme) => alpha(theme.palette.action.disabled, 0.1)
                            : 'transparent',
                        }}
                      >
                        <Box sx={{ width: '100%' }}>
                          <SingleOrganizationSelector
                            userProfile={userProfile}
                            selectedOrganizationId={assignment.organizationId}
                            handleChange={(value) => handleTeamAssignmentChange(index, 'organizationId', value)}
                            label="Select Team"
                            showLabelAbove
                            disabled={isDisabled}
                            // Added due to the background being an image of this color
                            labelBackgroundColor="#747474"
                          />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            // Added due to the background being an image of this color
                            sx={{
                              '& .MuiInputLabel-root': {
                                backgroundColor: '#747474',
                              },
                            }}
                          >
                            <InputLabel shrink variant="outlined">
                              Role
                            </InputLabel>
                            <Select
                              variant="outlined"
                              value={assignment.roleId}
                              onChange={(e) => handleTeamAssignmentChange(index, 'roleId', e.target.value)}
                              label="Role"
                              fullWidth
                              style={{ borderRadius: '21px' }}
                              disabled={isDisabled || !assignment.organizationId}
                            >
                              {assignment.organizationId &&
                                rolesToShow.map((role) => (
                                  <MenuItem key={role.id} value={role.id}>
                                    {role.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <IconButton
                          onClick={() => removeTeamAssignment(index)}
                          disabled={isDisabled || teamAssignments.length <= 1}
                        >
                          <DeleteOutlined color={isDisabled ? 'disabled' : 'inherit'} />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </Grid>
                )
              })}

              <Grid size={{ xs: 12 }}>
                <Button startIcon={<AddIcon />} onClick={addTeamAssignment} sx={{ mt: 1 }}>
                  Add Another Team
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <AnimateButton>
            <Button variant="contained" color="warning" onClick={onClose}>
              Cancel
            </Button>
          </AnimateButton>

          <Tooltip title={!isFormValid() ? getValidationMessages() : ''} arrow placement="top-start">
            <span>
              <AnimateButton>
                <Button
                  variant="contained"
                  startIcon={isLoading ? <CircularProgress size={20} /> : <PlusOutlined />}
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!isFormValid() || isLoading}
                >
                  {user ? 'Update' : 'Add'}
                </Button>
              </AnimateButton>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Archive Team Member</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to archive this team member? This action can be reversed by an administrator.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Archive
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CreateOrUpdateUserDialog
