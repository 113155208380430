import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ResponsiveStyleValue } from '@mui/system'
import React, { useState } from 'react'
import { ComponentSourceType, ComponentType, CompositeActualValue, CompositeFunction } from 'types/components'

interface ComponentDisplayProps {
  componentType: ComponentType
  value: string | number | boolean | null
  currency?: string
  currencyDecimals?: number
  decimalPlaces?: number
  typographyComponent?: React.ElementType
  fontSize?: ResponsiveStyleValue<string | number | undefined>
  fontWeight?: 'normal' | 'bold' | 'light' | 'medium' | number
  color?: 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error' | string
  sourceType?: ComponentSourceType
  compositeFunction?: CompositeFunction
  componentLabel?: string
  periodLabel?: string
}

const ComponentDisplay: React.FC<ComponentDisplayProps> = ({
  componentType,
  value,
  currency = 'USD',
  currencyDecimals = 0,
  decimalPlaces = 2,
  typographyComponent = 'span',
  fontWeight = 'normal',
  fontSize = 'medium',
  color = 'inherit',
  sourceType = ComponentSourceType.COMPONENT_SOURCE_MANUAL,
  compositeFunction = CompositeFunction.COMPOSITE_FUNCTION_NONE,
  componentLabel = 'Component',
  periodLabel,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [compositeData, setCompositeData] = useState<CompositeActualValue | null>(null)
  const theme = useTheme()

  const handleOpen = () => {
    if (sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE && typeof value === 'string') {
      try {
        const parsedValue = JSON.parse(value) as CompositeActualValue
        setCompositeData(parsedValue)
      } catch (error) {
        console.error('Error parsing composite value:', error)
      }
    }
    setDialogOpen(true)
  }

  const handleClose = () => setDialogOpen(false)

  const getFormattedValue = (inputValue: any, overrideType?: ComponentType, overrideDecimals?: number) => {
    const typeToUse = overrideType || componentType

    switch (typeToUse) {
      case ComponentType.COMPONENT_TYPE_CURRENCY:
        const currencyValue = Number(inputValue)
        // Check if the value is a whole number to determine if we need decimals
        const needsDecimals = currencyValue % 1 !== 0
        const decimalsToUse = needsDecimals ? (overrideDecimals !== undefined ? overrideDecimals : currencyDecimals) : 0

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: decimalsToUse,
          maximumFractionDigits: decimalsToUse,
        }).format(currencyValue)
      case ComponentType.COMPONENT_TYPE_INTEGER:
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(Number(inputValue))
      case ComponentType.COMPONENT_TYPE_FLOAT:
        const floatValue = Number(inputValue)
        // Check if the value is a whole number to determine if we need decimals
        const isWholeNumber = floatValue % 1 === 0
        const floatDecimals = isWholeNumber ? 0 : overrideDecimals !== undefined ? overrideDecimals : decimalPlaces

        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: floatDecimals,
          maximumFractionDigits: floatDecimals,
        }).format(floatValue)
      case ComponentType.COMPONENT_TYPE_PERCENTAGE:
        const percentValue = Number(inputValue)
        // Check if the percentage value (after multiplying by 100) is a whole number
        const isWholePercentage = (percentValue * 100) % 1 === 0

        return new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: isWholePercentage ? 0 : 2,
          maximumFractionDigits: isWholePercentage ? 0 : 2,
        }).format(percentValue)
      case ComponentType.COMPONENT_TYPE_BOOL:
        return inputValue ? 'True' : 'False'
      default:
        return inputValue?.toString() ?? ''
    }
  }

  const getCompositeDisplayValue = (inputValue: string) => {
    if (!inputValue) return ''

    try {
      // Parse the JSON string to get the composite value
      const parsedValue = JSON.parse(inputValue) as CompositeActualValue

      // Calculate the reduced value based on the composite function
      const numericValues = parsedValue.values.map((item) => Number(item.value))
      let reducedValue = 0

      // Determine if we need to show decimal places based on the function
      let useDecimalPlaces = false

      switch (compositeFunction) {
        case CompositeFunction.COMPOSITE_FUNCTION_AVERAGE:
          reducedValue = numericValues.reduce((acc, curr) => acc + curr, 0) / numericValues.length
          useDecimalPlaces = true
          break
        case CompositeFunction.COMPOSITE_FUNCTION_MEDIAN:
          const sortedValues = [...numericValues].sort((a, b) => a - b)
          reducedValue = sortedValues[Math.floor(sortedValues.length / 2)]
          break
        case CompositeFunction.COMPOSITE_FUNCTION_MODE:
          reducedValue =
            numericValues
              .sort((a, b) => numericValues.filter((v) => v === a).length - numericValues.filter((v) => v === b).length)
              .pop() || 0
          break
        case CompositeFunction.COMPOSITE_FUNCTION_STDDEV:
          const mean = numericValues.reduce((acc, curr) => acc + curr, 0) / numericValues.length
          const variance = numericValues.reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0) / numericValues.length
          reducedValue = Math.sqrt(variance)
          useDecimalPlaces = true
          break
        case CompositeFunction.COMPOSITE_FUNCTION_SUM:
          reducedValue = numericValues.reduce((acc, curr) => acc + curr, 0)
          break
        case CompositeFunction.COMPOSITE_FUNCTION_COUNT:
          reducedValue = numericValues.length
          break
        default:
          reducedValue = 0
      }

      // Check if reducedValue is NaN and default to 0 if it is
      if (isNaN(reducedValue)) {
        reducedValue = 0
      }

      // Format the reduced value with appropriate decimal places
      if (useDecimalPlaces) {
        if (componentType === ComponentType.COMPONENT_TYPE_CURRENCY) {
          // For currency, keep the currency type but use more decimal places
          return `${getFormattedValue(reducedValue, undefined, decimalPlaces)}*`
        } else if (componentType === ComponentType.COMPONENT_TYPE_INTEGER) {
          // For integers, convert to float to show decimal places
          return `${getFormattedValue(reducedValue, ComponentType.COMPONENT_TYPE_FLOAT)}*`
        } else {
          // For other types, use the original component type
          return `${getFormattedValue(reducedValue)}*`
        }
      } else {
        // For other functions, use the original component type formatting
        return `${getFormattedValue(reducedValue)}*`
      }
    } catch (error) {
      // Fallback to original input if parsing fails
      console.error('Error parsing composite value:', error)
      return inputValue
    }
  }

  const displayValue =
    sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE && typeof value === 'string'
      ? getCompositeDisplayValue(value)
      : getFormattedValue(value)

  return (
    <>
      <Typography
        variant="body1"
        component={typographyComponent}
        fontWeight={fontWeight}
        color={color}
        fontSize={fontSize}
        sx={
          sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE
            ? {
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                },
              }
            : {}
        }
        onClick={sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE ? handleOpen : undefined}
      >
        {displayValue}
      </Typography>

      {sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE && (
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="composite-details-dialog"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle
            id="composite-details-dialog"
            sx={{
              m: 0,
              p: 2,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {periodLabel ? `${componentLabel} - ${periodLabel}` : componentLabel}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ minWidth: '600px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="subtitle1">Composite Function: {compositeFunction}</Typography>
              {compositeData && (
                <Typography variant="subtitle1" fontWeight="medium" sx={{ mr: 3 }}>
                  Applied Value: {displayValue.replace('*', '')}
                </Typography>
              )}
            </Box>

            {compositeData && (
              <TableContainer sx={{ mt: 2 }}>
                <Table stickyHeader size="small" aria-label="composite values table">
                  <TableHead
                    sx={{
                      '& th': {
                        borderTop: `1px solid ${theme.palette.divider}`,
                        borderBottom: `2px solid ${theme.palette.divider} !important`,
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          minWidth: 170,
                          position: 'sticky !important',
                        }}
                      >
                        Label
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170,
                          position: 'sticky !important',
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          minWidth: 100,
                          position: 'sticky !important',
                        }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {compositeData.values.map((item, index) => (
                      <TableRow key={index} sx={{ py: 3 }} role="row" tabIndex={-1}>
                        <TableCell>{item.label}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell align="right">{getFormattedValue(item.value)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default ComponentDisplay
