import type { SelectChangeEvent } from '@mui/material'
import {
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material'
import Paper from 'components/@extended/Paper'
import CompPlanContent from 'components/CompPlanContent'
import SimpleTitle from 'components/SimpleTitle'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { usePlan } from 'hooks/usePlans'
import type { FC } from 'react'
import { Suspense, useEffect, useState } from 'react'
import type { ExpandedCompensationPlan } from 'types/comp-plan'
import type { LedgerAccount } from 'types/ledger-account'

dayjs.extend(utc)

const COMP_PLAN_TITLE = 'Compensation Plan'

const CompPlan: FC = () => {
  const { token, profile } = useAuth()
  const theme = useTheme()
  const [rootOrganizationId, setRootOrganizationId] = useState('')
  const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccount[] | undefined>([])
  const [profileInitialized, setProfileInitialized] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const [selectedRevision, setSelectedRevision] = useState<number | null>(null)
  const [plan, setPlan] = useState<ExpandedCompensationPlan | null>(null)

  useEffect(() => {
    if (profile?.plans && profile.plans.length > 0) {
      const firstPlan = profile.plans[0]
      setSelectedPlanId(firstPlan.id || null)
      setSelectedRevision(firstPlan.revision || null)
    }
  }, [profile?.plans])

  const { compPlan, isLoading: planLoading } = usePlan(
    token!,
    profile?.user?.id,
    selectedPlanId && selectedRevision ? { planId: selectedPlanId, revision: selectedRevision.toString() } : null,
    profileInitialized
  )

  const { ledgerAccounts: fetchedLedgerAccounts, isLoading: isLoadingLedgerAccounts } = useFetchLedgerAccounts(
    rootOrganizationId,
    token!,
    rootOrganizationId != null
  )

  const isLoading = planLoading || isLoadingLedgerAccounts

  useEffect(() => {
    if (profile) {
      setProfileInitialized(true)
    }
  }, [profile])

  useEffect(() => {
    if (compPlan) {
      setPlan(compPlan)
      setRootOrganizationId(compPlan.plan.rootOrganizationId)
    } else {
      console.log('No Compensation Plan available')
    }
  }, [compPlan])

  useEffect(() => {
    if (fetchedLedgerAccounts) {
      setLedgerAccounts(fetchedLedgerAccounts)
    }
  }, [fetchedLedgerAccounts])

  const handlePlanChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value
    setSelectedPlanId(selectedId || null)

    const selectedPlan = profile?.plans?.find(p => p.id === selectedId)
    if (selectedPlan) {
      setSelectedRevision(selectedPlan.revision || null)
      setRootOrganizationId(selectedPlan.rootOrganizationId || '')
    }
  }

  if (isLoading) {
    return <LoadingView />
  }

  if (!plan?.plan) {
    console.log('Rendering NoPlanView because plan is not available')
    return <NoPlanView />
  }

  return (
    <Suspense fallback={<CircularProgress color='primary' />}>
      <Grid
        container
        pb={4}
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid size={{ xs: 10 }}>
          <SimpleTitle title={COMP_PLAN_TITLE} />
        </Grid>
        <Grid size={{ xs: 2 }}>
          {profile?.plans && profile.plans.length > 1 ?
            <FormControl
              variant='outlined'
              fullWidth
            >
              <InputLabel
                shrink
                variant='outlined'
                sx={{
                  '&.MuiInputLabel-shrink': {
                    background: theme.palette.background.default,
                  },
                }}
              >
                Select Plan
              </InputLabel>
              <Select
                labelId='plan-select-label'
                value={selectedPlanId || ''}
                variant='outlined'
                style={{ borderRadius: '21px' }}
                onChange={handlePlanChange}
                label='Select Plan'
              >
                {profile.plans.map(p => (
                  <MenuItem
                    key={p.id || ''}
                    value={p.id || ''}
                  >
                    {`${dayjs(p.effectiveDate).format('M/D/YYYY')} - ${
                      p.endDate ? dayjs.utc(p.endDate).format('M/D/YYYY') : 'Present'
                    }`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          : <></>}
        </Grid>
      </Grid>
      <CompPlanContent
        user={profile?.user}
        compPlan={plan!}
        ledgerAccounts={ledgerAccounts}
      />
    </Suspense>
  )
}

const LoadingView: FC = () => (
  <Grid
    container
    spacing={12}
    style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}
  >
    <SimpleTitle title={COMP_PLAN_TITLE} />
    <CircularProgress color='primary' />
    Loading
  </Grid>
)

const NoPlanView: FC = () => (
  <Grid size={{ xs: 12 }}>
    <SimpleTitle title={COMP_PLAN_TITLE} />
    <Paper elevation={0}>
      <Typography variant='h5'>No valid compensation plan found</Typography>
      <Typography variant='body1'>Please contact your manager or HR department for assistance.</Typography>
    </Paper>
  </Grid>
)

export default CompPlan
