import type { NavItemType } from 'types/menu'
import type { ColorMap } from './index'

// assets
import { BorderOutlined, DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons'
// material-ui
import {
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import { handlerActiveItem, useGetMenuMaster } from 'api/menu'
// project import
import Dot from 'components/@extended/Dot'
import IconButton from 'components/@extended/IconButton'
import Paper from 'components/@extended/Paper'
import Transitions from 'components/@extended/Transitions'

import SimpleBar from 'components/third-party/SimpleBar'
import useConfig from 'hooks/useConfig'

import React, { useEffect, useMemo, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'
// types
import { MenuOrientation } from 'types/config'
import NavItem from './NavItem'

interface VirtualElement {
  getBoundingClientRect: () => ClientRect | DOMRect
  contextElement?: Element
}

type ListItemClick =
  | React.MouseEvent<HTMLButtonElement>
  | React.MouseEvent<HTMLAnchorElement>
  | React.MouseEvent<HTMLDivElement, MouseEvent>
  | undefined

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '&[data-popper-placement="right-end"]': {
    '.MuiPaper-root': {
      marginBottom: -8,
    },
    '&:before': {
      top: 'auto',
      bottom: 5,
    },
  },
}))

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

interface Props {
  menu: NavItemType
  level: number
  parentId: string
  setSelectedItems: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedItems: string | undefined
  setSelectedLevel: React.Dispatch<React.SetStateAction<number>>
  selectedLevel: number
  colorMap: ColorMap
}

function NavCollapse({
  menu,
  level,
  parentId,
  setSelectedItems,
  selectedItems,
  setSelectedLevel,
  selectedLevel,
  colorMap,
}: Props) {
  const theme = useTheme()
  const { menuMaster } = useGetMenuMaster()
  const drawerOpen = menuMaster.isDashboardDrawerOpened

  const downLG = useMediaQuery(theme.breakpoints.down('lg'))

  const { menuOrientation } = useConfig()
  const navigation = useNavigate()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<string | null | undefined>(null)
  const [anchorEl, setAnchorEl] = useState<VirtualElement | (() => VirtualElement) | null | undefined>(null)

  const [anchorElCollapse, setAnchorElCollapse] = React.useState<null | HTMLElement>(null)

  const openCollapse = Boolean(anchorElCollapse)
  const handleClickCollapse = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorElCollapse(event.currentTarget)
  }
  const handleCloseCollapse = () => {
    setAnchorElCollapse(null)
  }

  const handleClick = (event: ListItemClick, isRedirect: boolean) => {
    setAnchorEl(null)
    setSelectedLevel(level)
    if (drawerOpen) {
      setOpen(!open)
      setSelected(!selected ? menu.id : null)
      setSelectedItems(!selected ? menu.id : '')
      if (menu.url && isRedirect) navigation(`${menu.url}`)
    } else {
      setAnchorEl(event?.currentTarget)
    }
  }

  const handlerIconLink = () => {
    if (!drawerOpen) {
      if (menu.url) navigation(`${menu.url}`)
      setSelected(menu.id)
    }
  }

  const handleHover = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined
  ) => {
    setAnchorEl(event?.currentTarget)
    if (!drawerOpen) {
      setSelected(menu.id)
    }
  }

  const miniMenuOpened = Boolean(anchorEl)

  const handleClose = () => {
    setOpen(false)
    if (!miniMenuOpened) {
      if (!menu.url) {
        setSelected(null)
      }
    }
    setAnchorEl(null)
  }

  useMemo(() => {
    if (selected === selectedItems) {
      if (level === 1) {
        setOpen(true)
      }
    } else {
      if (level === selectedLevel) {
        setOpen(false)
        if (!miniMenuOpened && !drawerOpen && !selected) {
          setSelected(null)
        }
        if (drawerOpen) {
          setSelected(null)
        }
      }
    }
  }, [selectedItems, level, selected, miniMenuOpened, drawerOpen, selectedLevel])

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === menu.url) {
      setSelected(menu.id)
    }
    // eslint-disable-next-line
  }, [pathname])

  const checkOpenForParent = (child: NavItemType[], id: string) => {
    child.forEach((item: NavItemType) => {
      if (item.url === pathname) {
        setOpen(true)
        setSelected(id)
      }
    })
  }

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false)
    !miniMenuOpened ? setSelected(null) : setAnchorEl(null)
    if (menu.children) {
      menu.children.forEach((item: NavItemType) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id!)
        }

        if (item.url === pathname) {
          setSelected(menu.id)
          setOpen(true)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children])

  useEffect(() => {
    if (menu.url === pathname) {
      handlerActiveItem(menu.id!)
      setSelected(menu.id)
      setAnchorEl(null)
      setOpen(true)
    }
  }, [pathname, menu])

  const navCollapse = menu.children?.map(item => {
    switch (item.type) {
      case 'collapse':
        return (
          <NavCollapse
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            menu={item}
            level={level + 1}
            parentId={parentId}
            colorMap={colorMap}
          />
        )
      case 'item':
        return (
          <NavItem
            key={item.id}
            item={item}
            level={level + 1}
            colorMap={colorMap}
          />
        )
      default:
        return (
          <Typography
            key={item.id}
            variant='h6'
            color='error'
            align='center'
          >
            Fix - Collapse or Item
          </Typography>
        )
    }
  })

  const isSelected = selected === menu.id
  const borderIcon = level === 1 ? <BorderOutlined style={{ fontSize: '1rem' }} /> : false
  const Icon = menu.icon!
  const menuIcon = menu.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : borderIcon
  const popperId = miniMenuOpened ? `collapse-pop-${menu.id}` : undefined
  const FlexBox = { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }

  const getColors = () => {
    const mode = theme.palette.mode
    const isCollapsed = !drawerOpen
    return isCollapsed ? colorMap[`${mode}Collapsed`] : colorMap[`${mode}Expanded`]
  }

  const colors = getColors()

  return (
    <>
      {menuOrientation === MenuOrientation.VERTICAL || downLG ?
        <>
          <ListItemButton
            id={`${menu.id}-button`}
            selected={selected === menu.id}
            {...(!drawerOpen && {
              onMouseEnter: (e: ListItemClick) => handleClick(e, true),
              onMouseLeave: handleClose,
            })}
            onClick={(e: ListItemClick) => handleClick(e, true)}
            sx={{
              pl: drawerOpen ? `${level * 28}px` : 1.5,
              py: !drawerOpen && level === 1 ? 1.25 : 1,
              bgcolor: selected === menu.id ? colors.itemSelectedBackground : colors.itemBackground,
              color: selected === menu.id ? colors.itemSelectedText : colors.itemText,
              '&:hover': {
                bgcolor: colors.itemHoverBackground,
              },
              ...(!drawerOpen && {
                '&:hover': {
                  bgcolor: 'transparent',
                },
                '&.Mui-selected': {
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                  bgcolor: 'transparent',
                },
              }),
            }}
            {...(drawerOpen &&
              menu.isDropdown && {
                'aria-controls': openCollapse ? `${menu.id}-menu` : undefined,
                'aria-haspopup': true,
                'aria-expanded': openCollapse ? 'true' : undefined,
                onClick: handleClickCollapse,
              })}
          >
            {menuIcon && (
              <ListItemIcon
                onClick={handlerIconLink}
                sx={{
                  minWidth: 28,
                  color: selected === menu.id ? colors.itemSelectedIcon : colors.itemIcon,
                  ...(!drawerOpen && {
                    borderRadius: 1.5,
                    width: 36,
                    height: 36,
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      bgcolor: colors.itemHoverBackground,
                    },
                  }),
                  ...(!drawerOpen &&
                    selected === menu.id && {
                      bgcolor: colors.itemSelectedBackground,
                      '&:hover': {
                        bgcolor: colors.itemHoverBackground,
                      },
                    }),
                }}
              >
                {menuIcon}
              </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
              <ListItemText
                sx={{ ml: 1 }}
                primary={
                  <Typography
                    variant='h6'
                    color={selected === menu.id ? colors.itemSelectedText : colors.itemText}
                  >
                    {menu.title}
                  </Typography>
                }
                secondary={
                  menu.caption && (
                    <Typography
                      variant='caption'
                      color='secondary'
                    >
                      {menu.caption}
                    </Typography>
                  )
                }
              />
            )}

            {(drawerOpen || (!drawerOpen && level !== 1)) &&
              (menu?.url ?
                <IconButton
                  onClick={(event: ListItemClick) => {
                    event?.stopPropagation()
                    handleClick(event, false)
                  }}
                  color='secondary'
                  variant='outlined'
                  sx={{
                    width: 20,
                    height: 20,
                    color: colors.itemIcon,
                    borderColor: open ? colors.itemSelectedIcon : colors.itemIcon,
                    '&:hover': { borderColor: open ? colors.itemSelectedIcon : colors.itemHoverBackground },
                  }}
                >
                  {miniMenuOpened || open ?
                    <UpOutlined style={{ fontSize: '0.625rem', color: colors.itemSelectedIcon }} />
                  : <DownOutlined style={{ fontSize: '0.625rem', color: colors.itemIcon }} />}
                </IconButton>
              : <>
                  {miniMenuOpened || open ?
                    <UpOutlined style={{ fontSize: '0.625rem', marginLeft: 1, color: colors.itemSelectedIcon }} />
                  : <DownOutlined style={{ fontSize: '0.625rem', marginLeft: 1, color: colors.itemIcon }} />}
                </>)}

            {!drawerOpen && (
              <PopperStyled
                open={miniMenuOpened}
                anchorEl={anchorEl}
                placement='right-start'
                style={{
                  zIndex: 2001,
                }}
                popperOptions={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-12, 1],
                      },
                    },
                  ],
                }}
              >
                {({ TransitionProps }) => (
                  <Transitions
                    in={miniMenuOpened}
                    {...TransitionProps}
                  >
                    <Paper
                      sx={{
                        overflow: 'hidden',
                        mt: 1.5,
                        boxShadow: theme.customShadows.z1,
                        backgroundImage: 'none',
                        border: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <div>
                          <SimpleBar
                            sx={{
                              overflowX: 'hidden',
                              overflowY: 'auto',
                              maxHeight: '50vh',
                            }}
                          >
                            {navCollapse}
                          </SimpleBar>
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
                )}
              </PopperStyled>
            )}
          </ListItemButton>
          {drawerOpen && !menu?.isDropdown && (
            <Collapse
              in={open}
              timeout='auto'
              unmountOnExit
            >
              <List sx={{ p: 0 }}>{navCollapse}</List>
            </Collapse>
          )}

          {drawerOpen && menu?.isDropdown && (
            <Menu
              id={`${menu.id}-menu`}
              aria-labelledby={`${menu.id}-button`}
              anchorEl={anchorElCollapse}
              open={openCollapse}
              onClose={handleCloseCollapse}
              onClick={handleCloseCollapse}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiPaper-root': { boxShadow: theme.shadows[2] },
                '& .MuiListItemButton-root': { pl: 2 },
              }}
            >
              {navCollapse}
            </Menu>
          )}
        </>
      : <>
          <ListItemButton
            {...(menu?.url && { component: Link, to: menu.url })}
            id={`boundary-${popperId}`}
            disableRipple
            selected={isSelected}
            onMouseEnter={handleHover}
            onMouseLeave={handleClose}
            onClick={handleHover}
            aria-describedby={popperId}
            sx={{
              '&.Mui-selected': {
                bgcolor: 'transparent',
              },
            }}
          >
            <Box
              onClick={handlerIconLink}
              sx={FlexBox}
            >
              {menuIcon && (
                <ListItemIcon sx={{ my: 'auto', minWidth: 28, color: theme.palette.secondary.dark }}>
                  {menuIcon}
                </ListItemIcon>
              )}
              {!menuIcon && level !== 1 && (
                <ListItemIcon sx={{ my: 'auto', bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}>
                  <Dot
                    size={4}
                    color={isSelected ? 'primary' : 'secondary'}
                  />
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography
                    variant='body1'
                    color='inherit'
                    sx={{ my: 'auto' }}
                  >
                    {menu.title}
                  </Typography>
                }
              />
              {miniMenuOpened ?
                <RightOutlined />
              : <DownOutlined />}
            </Box>

            {anchorEl && (
              <PopperStyled
                id={popperId}
                open={miniMenuOpened}
                anchorEl={anchorEl}
                placement='right-start'
                style={{
                  zIndex: 2001,
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-10, 0],
                    },
                  },
                ]}
              >
                {({ TransitionProps }) => (
                  <Transitions
                    in={miniMenuOpened}
                    {...TransitionProps}
                  >
                    <Paper
                      sx={{
                        overflow: 'hidden',
                        mt: 1.5,
                        py: 0.5,
                        boxShadow: theme.shadows[8],
                        backgroundImage: 'none',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <div>
                          <SimpleBar
                            sx={{
                              overflowX: 'hidden',
                              overflowY: 'auto',
                              maxHeight: '50vh',
                            }}
                          >
                            {navCollapse}
                          </SimpleBar>
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
                )}
              </PopperStyled>
            )}
          </ListItemButton>
        </>
      }
    </>
  )
}

export default NavCollapse
