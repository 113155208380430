import { Grid2 as Grid } from '@mui/material'
import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'

import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

import PlanAllocationTables from './PlanAllocationTables'
import PlanComponentSummary from './PlanComponentSummary'
import PlanTargets from './PlanTargets'

function ReviewPlan() {
  const { plan, employeeName, compensationTemplate } = useCreatePlanContext()

  // Check if we have all required data for the summary card
  const hasRequiredData = Boolean(
    employeeName &&
      plan.position &&
      plan.effectiveDate &&
      compensationTemplate?.period &&
      plan.periodId &&
      plan.compensationModel &&
      plan.baseSalary &&
      plan.variablePercent &&
      plan.variableCap &&
      plan.periodProration
  )

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems='center'
      >
        <Grid size={{ xs: 12 }}>
          {hasRequiredData && (
            <CompensationSummaryCard
              compensationModel={plan.compensationModel}
              variablePercent={plan.variablePercent}
              baseSalary={plan.baseSalary}
              variableCap={plan.variableCap}
              name={employeeName}
              position={plan.position}
              effectiveDate={plan.effectiveDate}
              period={compensationTemplate?.period}
              periodId={plan.periodId}
              periodProration={plan.periodProration}
            />
          )}
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanComponentSummary />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanTargets reviewOnly />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanAllocationTables reviewOnly />
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewPlan
