// material-ui
import {
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material'
import SimpleTitle from 'components/SimpleTitle'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useAuth from 'hooks/useAuth'
import { useFetchPlansWithAccruals } from 'hooks/usePlans'
import React, { useEffect, useState } from 'react'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { Permissions } from 'types/permissions'
import { getOrganizationIdsWithPermissions } from 'types/user-profile'
import EmployeeDashboard from './EmployeeDashboard'
import ManagerDashboard from './ManagerDashboard'

dayjs.extend(utc)

const Dashboard: React.FC = () => {
  const { token, profile } = useAuth()

  const organizationIdsCanViewManagerDashboard =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.MANAGER_DASHBOARD_READ]) : []

  const showManagerDashboard = organizationIdsCanViewManagerDashboard.length > 0

  const [view, setView] = useState(showManagerDashboard ? 'manager' : 'employee')
  const theme = useTheme()
  const [isReady, setIsReady] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const [selectedPlan, setSelectedPlan] = useState<ExpandedCompensationPlan | undefined>(undefined)

  const DASHBOARD_TITLE = view === 'manager' ? 'Team Dashboard' : 'Employee Dashboard'

  const userId = profile?.user?.id
  const {
    compPlans,
    isLoading: isLoadingPlans,
    isError: isErrorPlans,
  } = useFetchPlansWithAccruals(token!, userId!, null)

  useEffect(() => {
    if (compPlans?.plans?.length > 0) {
      setSelectedPlanId(compPlans.plans[0].plan.id || null)
    }
  }, [compPlans])

  useEffect(() => {
    if (compPlans && !isLoadingPlans && !isErrorPlans) {
      setIsReady(true)
    }
  }, [compPlans, isLoadingPlans, isErrorPlans])

  useEffect(() => {
    if (selectedPlanId) {
      setSelectedPlan(compPlans?.plans.find(p => p.plan.id === selectedPlanId) || undefined)
    }
  }, [selectedPlanId, compPlans])

  console.log('isLoadingPlans', isLoadingPlans)
  console.log('isReady', isReady)

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView !== null) {
      setView(newView)
    }
  }

  if (isLoadingPlans || !isReady || !profile) {
    return <Typography>Loading...</Typography>
  }

  const handlePlanChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value
    const selectedPlan = profile?.plans?.find(p => p.id === selectedId)
    if (selectedPlan) {
      setSelectedPlanId(selectedId)
    }
  }

  return (
    <>
      <Grid
        container
        pb={2}
        spacing={2}
      >
        <Grid size={{ xs: view === 'employee' ? 7 : 9 }}>
          <SimpleTitle title={DASHBOARD_TITLE} />
        </Grid>
        {view === 'employee' && (
          <Grid
            size={{ xs: 2 }}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <FormControl
              variant='outlined'
              fullWidth
            >
              <InputLabel
                shrink
                variant='outlined'
                sx={{
                  '&.MuiInputLabel-shrink': {
                    background: theme.palette.background.default,
                  },
                }}
              >
                Select Plan
              </InputLabel>
              <Select
                labelId='plan-select-label'
                id='plan-select'
                variant='outlined'
                value={selectedPlanId || ''}
                label='Select Plan'
                style={{ borderRadius: '21px' }}
                onChange={handlePlanChange}
              >
                {profile.plans?.map(p => (
                  <MenuItem
                    key={p.id || ''}
                    value={p.id || ''}
                  >
                    {`${dayjs(p.effectiveDate).format('M/D/YYYY')} - ${
                      p.endDate ? dayjs.utc(p.endDate).format('M/D/YYYY') : 'Present'
                    }`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {showManagerDashboard && (
          <Grid
            size={{ xs: 3 }}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label='View toggle'
              size='small'
              sx={{ width: '100%', height: 32, mt: 1 }}
            >
              <ToggleButton
                value='manager'
                aria-label='Manager View'
                sx={{ width: '50%' }}
              >
                Manager View
              </ToggleButton>
              <ToggleButton
                value='employee'
                aria-label='Employee View'
                sx={{ width: '50%' }}
              >
                Employee View
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
      </Grid>
      {!showManagerDashboard || view === 'employee' ?
        <EmployeeDashboard
          key='employee-dashboard'
          user={profile.user}
          plan={selectedPlan}
          theme={theme}
          status={isErrorPlans ? 400 : 200}
        />
      : <ManagerDashboard />}
    </>
  )
}

export default Dashboard
