import type { Theme } from '@mui/material'
import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

interface AchievementCalculationGuideProps {
  theme: Theme
}

const AchievementCalculationGuide: React.FC<AchievementCalculationGuideProps> = ({ theme }) => {
  const calculations = [
    {
      name: 'Standard',
      formula: 'Actual / Target',
      description: 'Best for metrics where higher values are better (e.g., sales, revenue).',
      example: 'If target is $100,000 and actual is $120,000, achievement is 120,000 / 100,000 = 1.2 (120%).',
      notes: [
        'When Actual = Target: Achievement = 1 (100%)',
        'When Actual > Target: Achievement > 1 (payout increases)',
        'When Actual < Target: Achievement < 1 (payout decreases)',
      ],
      implementation: 'Actual/Target',
    },
    {
      name: 'Inverse with Adjustment',
      formula: '(Target + c) / (Actual + c)',
      description:
        'Best for metrics where lower values are better (e.g., accidents, errors). Constant c prevents division by zero.',
      example: 'If target is 5 accidents and actual is 1, with c = 1, achievement is (5+1) / (1+1) = 6 / 2 = 3 (300%).',
      notes: [
        'When Actual = Target: Achievement = 1 (100%)',
        'When Actual < Target: Achievement > 1 (payout increases)',
        'When Actual > Target: Achievement < 1 (payout decreases)',
        'When Actual = 0: Achievement = (Target + c) / c',
      ],
      implementation: '(Target+c)/(Actual+c) where c is a constant (typically 1)',
    },
    {
      name: 'Simple Inverse',
      formula: 'Target / Actual',
      description:
        'Simpler calculation for metrics where lower values are better, but requires special handling when Actual = 0.',
      example: 'If target is 10 defects and actual is 2, achievement is 10 / 2 = 5 (500%).',
      notes: [
        'When Actual = Target: Achievement = 1 (100%)',
        'When Actual < Target: Achievement > 1 (payout increases)',
        'When Actual > Target: Achievement < 1 (payout decreases)',
        'When Actual = 0: Special case - typically set to a maximum value',
      ],
      implementation: 'Target/Actual',
    },
    {
      name: 'Linear Adjustment',
      formula: 'c - (Actual / Target)',
      description:
        'Alternative approach for metrics where lower values are better. With default value of 2, caps maximum achievement at 200%.',
      example: 'If target is 5 errors and actual is 2, achievement is 2 - (2 / 5) = 2 - 0.4 = 1.6 (160%).',
      notes: [
        'When Actual = Target: Achievement = c - 1',
        'When Actual < Target: Achievement > (c - 1) (payout increases)',
        'When Actual > Target: Achievement < (c - 1) (payout decreases)',
        'When Actual = 0: Achievement = c',
      ],
      implementation: 'c-(Actual/Target) where c is a constant (typically 2)',
    },
  ]

  return (
    <Box
      maxHeight='calc(65vh)' // 65% of viewport height
      overflow='auto'
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '15px', // Updated to match Card component
        p: 0,
      }}
    >
      <Typography
        variant='h5'
        align='center'
        mt={2}
        mb={1}
      >
        Achievement Calculation Methods
      </Typography>
      <Typography
        variant='body2'
        sx={{ px: 2, pb: 1 }}
      >
        When creating formulas, you can use these common achievement calculation patterns to determine how performance
        is measured relative to target.
      </Typography>

      {calculations.map((calc, index) => (
        <React.Fragment key={calc.name}>
          <Box sx={{ px: 2, py: 1, bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper' }}>
            <Typography
              variant='subtitle1'
              fontWeight='bold'
            >
              {calc.name}: {calc.formula}
            </Typography>
            <Typography
              variant='body2'
              paragraph
            >
              {calc.description}
            </Typography>
            <Typography
              variant='body2'
              paragraph
            >
              <strong>Example:</strong> {calc.example}
            </Typography>
            <Typography
              variant='body2'
              paragraph
            >
              <strong>Implementation:</strong> {calc.implementation}
            </Typography>
            <Typography variant='body2'>
              <strong>Behavior:</strong>
            </Typography>
            <ul style={{ marginTop: 4 }}>
              {calc.notes.map((note, i) => (
                <li key={i}>
                  <Typography variant='body2'>{note}</Typography>
                </li>
              ))}
            </ul>
          </Box>
          {index < calculations.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  )
}

export default AchievementCalculationGuide
