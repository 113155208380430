import { useAuth0 } from '@auth0/auth0-react'
import useAuth from 'hooks/useAuth'
import useLocalStorage from 'hooks/useLocalStorage'
import { useOrganizationStatus } from 'hooks/useOrganizationStatus'
import React, { createContext, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import type { Organization } from 'types/organization'
import type { PurcentAlertType, PurcentAppState } from 'types/purcent-app-state'
import { checkPermissions, getRootOrganizationId } from 'types/user-profile'

const initialState: PurcentAppState = {
  selectedOrganization: null,
  alert: null,
}

const GlobalStateContext = createContext<GlobalStateContextValue>({
  purcentAppState: null,
  onChangeOrganizationSelection: () => {},
  setAlert: () => {},
  refetchStatus: () => {},
})

interface GlobalStateWrapperProps {
  children: React.ReactNode
}

interface GlobalStateContextValue {
  purcentAppState: PurcentAppState | null
  onChangeOrganizationSelection: (org: Organization) => void
  setAlert: (alert: PurcentAlertType | null) => void
  refetchStatus: () => void
}

const GlobalStateProvider: React.FC<GlobalStateWrapperProps> = ({ children }) => {
  const [purcentAppState, setPurcentAppState] = useLocalStorage<PurcentAppState>('purcent-app-state', initialState)
  const {
    auth0User: user,
    token,
    isLoggedIn,
    isLoading: isLoadingAuth,
    profile,
    isLoading,
    errorProfile: isError,
    requestStatus: status,
  } = useAuth()
  const { loginWithRedirect } = useAuth0()
  const {
    organizationStatus,
    isLoadingOrganizationStatus,
    isError: isErrorOrganizationStatus,
    handleRefetch,
  } = useOrganizationStatus(token!, getRootOrganizationId(profile!) || null)
  const navigate = useNavigate()

  const refetchStatus = () => {
    handleRefetch()
  }

  useEffect(() => {
    if (!isLoadingOrganizationStatus && !isErrorOrganizationStatus) {
      console.log('organizationStatus information')
      console.log(organizationStatus)
      if (organizationStatus && organizationStatus.alerts && organizationStatus.alerts.length > 0) {
        setAlert({
          level: organizationStatus.alerts[0].level as PurcentAlertType['level'],
          message: organizationStatus.alerts[0].message,
          actionMessage: organizationStatus.alerts[0].actionMessage,
          link: organizationStatus.alerts[0].actionLink,
        })
      } else {
        setAlert(null)
      }
    }
  }, [organizationStatus, isLoadingOrganizationStatus, isErrorOrganizationStatus])

  useEffect(() => {
    if (!isLoadingOrganizationStatus && !isErrorOrganizationStatus && profile && !isLoading) {
      console.log('organizationStatus information - check for the organization status')
      console.log(organizationStatus)
      if (organizationStatus && organizationStatus.status === 'SETUP') {
        console.log('organizationStatus is SETUP')
        // Check if user is owner or admin
        console.log(profile?.roles)
        const isAdmin = checkPermissions(profile!, ['settings.write', 'organization.write'])
        if (!isAdmin) {
          navigate('/setup-in-progress')
        }
      }
    }
  }, [organizationStatus, isLoadingOrganizationStatus, isErrorOrganizationStatus, profile, isLoading])

  // Handle global redirection for errors
  useEffect(() => {
    if (isLoadingAuth || isLoggedIn) {
      return
    }
    if (isError || status === 401) {
      localStorage.clear()
      loginWithRedirect()
    }
  }, [isError, status, navigate])

  // Add new effect for handling inactive users
  useEffect(() => {
    if (!isLoading && profile) {
      if (profile.error === 'User is not active') {
        // Redirect inactive users to the not-active page
        console.log('User is not active')
        navigate('/not-active', { replace: true })
      }
    }
  }, [profile, isLoading, navigate])

  useEffect(() => {
    if (!isLoading && !isError && user && profile) {
      // Skip organization selection if user is not active
      if (profile.error === 'User is not active') {
        return
      }

      const firstOrg = profile.roles && profile.roles.length > 0 ? profile.roles[0]?.organization : null
      if (
        firstOrg &&
        (!purcentAppState.selectedOrganization || purcentAppState.selectedOrganization.id !== firstOrg.id)
      ) {
        setPurcentAppState((prevState: PurcentAppState) => ({
          ...prevState,
          selectedOrganization: firstOrg,
        }))
      } else if (!firstOrg && purcentAppState.selectedOrganization) {
        setPurcentAppState(initialState)
      }
    }
  }, [user, profile, isLoading, isError, setPurcentAppState, purcentAppState.selectedOrganization])

  const onChangeOrganizationSelection = (org: Organization) => {
    setPurcentAppState({
      ...purcentAppState,
      selectedOrganization: org,
    })
  }

  const setAlert = (alert: PurcentAlertType | null) => {
    setPurcentAppState({
      ...purcentAppState,
      alert,
    })
  }

  return (
    <GlobalStateContext value={{ purcentAppState, onChangeOrganizationSelection, setAlert, refetchStatus }}>
      {children}
    </GlobalStateContext>
  )
}

function useGlobalState() {
  const context = useContext(GlobalStateContext)
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateContext')
  }
  return context
}

export { GlobalStateProvider, useGlobalState }
