import { useEffect, useState } from 'react'
import useSWR from 'swr'
import type {
  ArchivePlanParams,
  ArchivePlanResponse,
  CompensationPlansResponse,
  ExpandedCompensationPlan,
  ExpandedCompensationPlansResponse,
} from 'types/comp-plan'
import { fetchWithToken, fetchWithTokenWithStatus, postWithTokenWithStatus } from './http'

export function usePlan(
  token: string,
  userId: string | undefined,
  params: Record<string, string> | null,
  shouldFetch: boolean = true
) {
  const [isLoading, setIsLoading] = useState(false)
  const shouldFetchData = shouldFetch && userId != null && token.trim() !== ''

  const { data, error, mutate } = useSWR(
    shouldFetchData ? [getUrl(userId, params), token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, null)
    // {
    //   suspense: false,
    //   revalidateIfStale: true,
    //   revalidateOnFocus: true,
    //   revalidateOnMount: true,
    // }
  )

  useEffect(() => {
    if (shouldFetchData) {
      setIsLoading(true)
      mutate().finally(() => {
        setIsLoading(false)
      })
    }
  }, [shouldFetchData, mutate])

  const handleRefetch = () => {
    setIsLoading(true)
    mutate().finally(() => {
      setIsLoading(false)
    })
  }

  const plan = data as ExpandedCompensationPlan | undefined
  const notFound = !plan || !plan.plan || (data && 'error' in data && data.error === 'CompensationPlan not found')

  return {
    compPlan: notFound ? undefined : plan,
    isLoading,
    isError: error || (data && 'error' in data), // Consider both SWR error and API error
    notFound,
    errorMessage: (error as Error)?.message || (data && 'error' in data ? data.error : undefined),
    handleRefetch,
  }
}

function getUrl(userId: string, params: Record<string, string> | null): string {
  if (params && params.planId && params.revision) {
    return `compensation/plans/user/${userId}/${params.planId}/${params.revision}`
  }
  return `compensation/plans/user/${userId}/latest`
}

export function usePlanWithStatus(
  token: string,
  userId: string | undefined,
  params: Record<string, string> | null,
  shouldFetch: boolean = true
) {
  const [isLoading, setIsLoading] = useState(false)
  const shouldFetchData = shouldFetch && userId != null && token.trim() !== ''

  const { data, error, mutate } = useSWR(
    shouldFetchData ? [getUrl(userId, params), token, params] : null,
    ([url, token, params]) => fetchWithTokenWithStatus(url, token, null),
    {
      shouldRetryOnError: false,
    }
  )

  useEffect(() => {
    if (shouldFetchData) {
      setIsLoading(true)
      mutate().finally(() => {
        setIsLoading(false)
      })
    }
  }, [shouldFetchData, mutate])

  const handleRefetch = () => {
    setIsLoading(true)
    mutate().finally(() => {
      setIsLoading(false)
    })
  }

  const { data: responseData, status } = data || { data: undefined, status: undefined }
  const plan = responseData as ExpandedCompensationPlan | undefined
  const notFound =
    !plan ||
    !plan.plan ||
    (responseData && 'error' in responseData && responseData.error === 'CompensationPlan not found')

  return {
    compPlan: notFound ? undefined : plan,
    isLoading,
    isError: error || (data && 'error' in data), // Consider both SWR error and API error
    notFound,
    errorMessage: (error as Error)?.message || (data && 'error' in data ? data.error : undefined),
    status: status || 0,
    handleRefetch,
  }
}

export function useArchivePlan(token: string) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const archivePlan = async (params: ArchivePlanParams) => {
    setIsLoading(true)
    setError(null)

    try {
      const { data, status } = await postWithTokenWithStatus('compensation/plans/archive', token, params)

      if (status >= 400) {
        throw new Error(data.error || 'Failed to archive plan')
      }

      return data as ArchivePlanResponse
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to archive plan')
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  return {
    archivePlan,
    isLoading,
    error,
  }
}

export function useFetchPlans(token: string, userId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`compensation/plans/user/${userId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    compPlans: data as CompensationPlansResponse,
    isLoading,
    isError: error,
  }
}

export function useFetchPlansWithAccruals(token: string, userId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`dashboard/expanded-plans/${userId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    compPlans: data as ExpandedCompensationPlansResponse,
    isLoading,
    isError: error,
  }
}

export function useFetchOrganizationPlans(token: string, orgId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`compensation/plans/organization/${orgId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    plans: data?.plans ? (data?.plans as ExpandedCompensationPlan[]) : [],
    isLoading,
    isError: error,
  }
}

interface ClosePlanParams {
  planId: string
  endDate: string
}

export function useClosePlan(token: string) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const closePlan = async (params: ClosePlanParams) => {
    setIsLoading(true)
    setError(null)

    try {
      const { data, status } = await postWithTokenWithStatus('compensation/plans/close', token, params)

      if (status >= 400) {
        throw new Error(data.error || 'Failed to close plan')
      }

      return data as ExpandedCompensationPlan
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to close plan')
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  return {
    closePlan,
    isLoading,
    error,
  }
}

export function usePlanRevision(
  token: string,
  targetUserId: string | undefined,
  planId: string | undefined,
  revision: number | undefined,
  shouldFetch: boolean = true
) {
  const [isLoading, setIsLoading] = useState(false)
  const shouldFetchData =
    shouldFetch && targetUserId != null && planId != null && revision != null && token.trim() !== ''

  const { data, error, mutate } = useSWR(
    shouldFetchData ? [`compensation/plans/user/${targetUserId}/${planId}/${revision}`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null)
  )

  useEffect(() => {
    if (shouldFetchData) {
      setIsLoading(true)
      mutate().finally(() => {
        setIsLoading(false)
      })
    }
  }, [shouldFetchData, mutate])

  const handleRefetch = () => {
    setIsLoading(true)
    mutate().finally(() => {
      setIsLoading(false)
    })
  }

  const plan = data as ExpandedCompensationPlan | undefined
  const notFound = !plan || !plan.plan || (data && 'error' in data && data.error === 'CompensationPlan not found')

  return {
    compPlan: notFound ? undefined : plan,
    isLoading,
    isError: error || (data && 'error' in data),
    notFound,
    errorMessage: (error as Error)?.message || (data && 'error' in data ? data.error : undefined),
    handleRefetch,
  }
}
