import { CardContent, Divider, Grid2 as Grid, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import ComponentDisplay from 'components/ComponentDisplay'
import type { ExpandedCompensationPlan } from 'types/comp-plan'
import type { Component, MetricDTO, TargetDTO } from 'types/components'
import { getCompositeFunction } from 'types/components'
import type { Period } from 'types/periods'
import { createIdLabelMap } from 'types/periods'

interface PlanTargetActualsRowProps {
  componentId: string
  component?: Component
  target?: TargetDTO
  metric?: MetricDTO
  reviewOnly?: boolean
  periodLabel?: string
}

const PlanTargetActualsRow: React.FC<PlanTargetActualsRowProps> = ({
  componentId,
  component,
  target,
  metric,
  periodLabel,
}) => {
  if (component == null || target == null) {
    return <></>
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems='center'
    >
      <Grid size={{ xs: 12, sm: 3 }}>
        <Typography variant='h6'>{component.label}</Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <Typography align='center'>{periodLabel ?? ''}</Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <Typography align='center'>
          <ComponentDisplay
            componentType={component.componentType}
            value={target.value}
            fontSize='inherit'
          />
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <Typography align='center'>
          {metric ?
            <ComponentDisplay
              componentType={component.componentType}
              value={metric.value}
              sourceType={component.sourceType}
              compositeFunction={getCompositeFunction(component.sourceInfo)}
              componentLabel={component.label}
              periodLabel={periodLabel}
              fontSize='inherit'
            />
          : '-'}
        </Typography>
      </Grid>
    </Grid>
  )
}

interface PlanTargetActualsProps {
  plan: ExpandedCompensationPlan
  period: Period
}

const PlanTargetActualsCard: React.FC<PlanTargetActualsProps> = ({ plan, period }) => {
  if (plan == null) {
    return <></>
  }

  const componentsMap = plan.components.reduce((acc: Record<string, Component>, component) => {
    if (component.id != null) {
      acc[component.id] = component
    }
    return acc
  }, {})

  // Updated to use a composite key of componentId and periodId
  const targetsComponentIdMap = plan.targets.reduce((acc: Record<string, TargetDTO>, target) => {
    const key = `${target.componentId}_${target.periodId}`
    acc[key] = target
    return acc
  }, {})

  const metricsComponentIdMap = plan.metrics.reduce((acc: Record<string, MetricDTO>, metric) => {
    const key = `${metric.componentId}_${metric.periodId}`
    acc[key] = metric
    return acc
  }, {})

  const activeTargetComponentIds = plan.plan.variableAllocations.reduce((acc: Set<string>, table) => {
    const activeTargetsForTable = table.rows.reduce((rowAcc: Set<string>, row) => {
      if (!(row.disabled ?? false)) {
        ;(row.componentIds ?? []).forEach(id => {
          const periodId = row.periodId // Assuming periodId is available in row
          const compositeKey = `${id}_${periodId}`
          rowAcc.add(compositeKey)
        })
      }
      return rowAcc
    }, new Set<string>())

    activeTargetsForTable.forEach(id => acc.add(id))
    return acc
  }, new Set<string>())
  // Convert Set back to array if needed elsewhere as array
  const uniqueActiveTargetComponentIds = Array.from(activeTargetComponentIds)

  const periodIdLabelMap = createIdLabelMap(period)

  return (
    <div style={{ width: '100%' }}>
      <Paper sx={{ paddingTop: 1, width: '100%' }}>
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid size={{ xs: 3 }}>
              <Typography variant='h3'>Component</Typography>
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Typography
                variant='h5'
                align='center'
              >
                Period
              </Typography>
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Typography
                variant='h5'
                align='center'
              >
                Target
              </Typography>
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Typography
                variant='h5'
                align='center'
              >
                Actual
              </Typography>
            </Grid>
          </Grid>
          {uniqueActiveTargetComponentIds.map((compositeId, index) => {
            const [componentId, periodId] = compositeId.split('_')
            const component = componentsMap[componentId]
            const target = targetsComponentIdMap[compositeId]
            const metric = metricsComponentIdMap[compositeId]
            const periodLabel = periodIdLabelMap[periodId]

            if (component === undefined || target === undefined) {
              return <></>
            }

            return (
              <div key={compositeId}>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Divider />
                </div>

                <PlanTargetActualsRow
                  componentId={componentId}
                  component={component}
                  target={target}
                  metric={metric}
                  periodLabel={periodLabel}
                />
              </div>
            )
          })}
        </CardContent>
      </Paper>
    </div>
  )
}

export default PlanTargetActualsCard
