import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import ComponentField from 'components/ComponentField'
import SinglePeriodSelector from 'components/SinglePeriodSelector'
import useAuth from 'hooks/useAuth'
import { usePlanRevision } from 'hooks/usePlans'
import {
  ExpandedCompensationTemplate,
  PrepareRolloverResponse,
  TargetPair,
  useTemplateRollover,
} from 'hooks/useRollovers'
import { useSnackbar } from 'notistack'
import { createContext, FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { CompensationTemplate } from 'types/comp-template'
import { ComponentType } from 'types/components'
import type { Period } from 'types/periods'
import { findPeriodById } from 'types/periods'

interface RolloverPlanDialogProps {
  open: boolean
  onClose: () => void
  onRollover: (periodId: string) => void
  periods: Period[]
  isLoading?: boolean
  theme?: Theme
  userName?: string
  fromPeriodLabel?: string
  fromPeriodId?: string
  templateId: string
  revision: number
  userId: string
  planId: string
  periodIdLabelMap: Record<string, string>
  showSelectPeriodStep?: boolean
}

interface RolloverContext {
  promise: Promise<void> | null
  setPromise: (promise: Promise<void> | null) => void
}

const RolloverContext = createContext<RolloverContext>({
  promise: null,
  setPromise: () => {},
})

type Step = 'select-period' | 'target-review' | 'confirmation'

const RolloverPlanDialog: FC<RolloverPlanDialogProps> = ({
  open,
  onClose,
  onRollover,
  periods,
  isLoading = false,
  theme,
  userName,
  fromPeriodLabel,
  fromPeriodId,
  templateId,
  revision,
  userId,
  planId,
  periodIdLabelMap,
  showSelectPeriodStep = true,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { token } = useAuth()
  const { prepareTemplateRollover, executeTemplateRollover } = useTemplateRollover(token!)
  const { compPlan, isLoading: isPlanLoading } = usePlanRevision(token!, userId, planId, revision, open)
  const navigate = useNavigate()

  const [selectedPeriodId, setSelectedPeriodId] = useState<string>('')
  const [activeStep, setActiveStep] = useState<Step>('select-period')
  const [isPreparingRollover, setIsPreparingRollover] = useState(false)
  const [isExecutingRollover, setIsExecutingRollover] = useState(false)
  const [targetPairs, setTargetPairs] = useState<TargetPair[]>([])
  const [prepareResponse, setPrepareResponse] = useState<PrepareRolloverResponse | null>(null)
  const [selectedPeriodLabel, setSelectedPeriodLabel] = useState<string>('Selected Period')
  const [validationError, setValidationError] = useState<string>('')
  const [template, setTemplate] = useState<ExpandedCompensationTemplate | CompensationTemplate | null>(null)
  const [rolloverPromise, setRolloverPromise] = useState<Promise<void> | null>(null)

  // Reset states when dialog opens/closes
  useEffect(() => {
    if (!open) {
      setSelectedPeriodId('')
      setActiveStep('select-period')
      setSelectedPeriodLabel('Selected Period')
      setValidationError('')
      setTargetPairs([])
      setPrepareResponse(null)
      setTemplate(null)
      setRolloverPromise(null)
    }
  }, [open])

  // Update selected period label when period ID changes
  useEffect(() => {
    if (selectedPeriodId && periods.length > 0) {
      const selectedPeriod = findPeriodById(periods, selectedPeriodId)
      if (selectedPeriod?.label) {
        setSelectedPeriodLabel(selectedPeriod.label)
      } else {
        setSelectedPeriodLabel('Selected Period')
      }
    } else {
      setSelectedPeriodLabel('Selected Period')
    }

    // Validate that selected period is not the same as fromPeriodId
    if (selectedPeriodId && fromPeriodId && selectedPeriodId === fromPeriodId) {
      setValidationError('Cannot rollover to the same period')
    } else {
      setValidationError('')
    }
  }, [selectedPeriodId, periods, fromPeriodId])

  // Effect to handle rollover promise completion
  useEffect(() => {
    if (rolloverPromise) {
      rolloverPromise
        .then(() => {
          setActiveStep('confirmation')
        })
        .catch(error => {
          console.error('Error in rollover process:', error)
          enqueueSnackbar('Failed to complete rollover process', { variant: 'error' })
        })
    }
  }, [rolloverPromise, enqueueSnackbar])

  const handlePrepareRollover = () => {
    setIsPreparingRollover(true)

    const selectedPeriod = findPeriodById(periods, selectedPeriodId)
    if (!selectedPeriod?.rootPeriodId) {
      enqueueSnackbar('Invalid period selection', { variant: 'error' })
      setIsPreparingRollover(false)
      return
    }

    prepareTemplateRollover(templateId, selectedPeriod.rootPeriodId, selectedPeriodId, revision)
      .then(response => {
        setPrepareResponse(response)

        if (response.existingTemplate) {
          // Case 1: Template exists, go directly to confirmation
          setTemplate(response.existingTemplate.template)
          setActiveStep('confirmation')
        } else if (!response.targetPairs.some(pair => 'nextTarget' in pair && pair.nextTarget !== null)) {
          // Case 2: No template, no nextTargets or all nextTargets are undefined/null
          if (!selectedPeriod.rootPeriodId || !fromPeriodId) {
            enqueueSnackbar('Invalid period data', { variant: 'error' })
            return
          }

          const request = {
            targetPairs: response.targetPairs,
            rootOrganizationId: response.organizationId,
            templateId: response.templateId,
            templateRevision: revision,
            nextRootPeriodId: response.nextRootPeriodId,
            nextPeriodId: response.nextPeriodId,
            prevRootPeriodId: selectedPeriod.rootPeriodId,
            prevPeriodId: fromPeriodId,
          }

          executeTemplateRollover(templateId, request)
            .then(rolloverResponse => {
              setTemplate(rolloverResponse.template)
              setActiveStep('confirmation')
            })
            .catch(error => {
              console.error('Error executing template rollover:', error)
              enqueueSnackbar('Failed to execute template rollover', { variant: 'error' })
            })
        } else {
          // Case 3: No template, at least one nextTarget exists, go to target review
          const pairsWithNextTarget = response.targetPairs.filter(
            pair => 'nextTarget' in pair && pair.nextTarget !== null
          )
          setTargetPairs(pairsWithNextTarget)
          setActiveStep('target-review')
        }
      })
      .catch(error => {
        console.error('Error preparing template rollover:', error)
        enqueueSnackbar('Failed to prepare template rollover', { variant: 'error' })
      })
      .finally(() => {
        setIsPreparingRollover(false)
      })
  }

  const handleExecuteRollover = () => {
    if (!prepareResponse || !selectedPeriodId || !fromPeriodId) return

    setIsExecutingRollover(true)

    const selectedPeriod = findPeriodById(periods, selectedPeriodId)
    if (!selectedPeriod?.rootPeriodId) {
      enqueueSnackbar('Invalid period selection', { variant: 'error' })
      setIsExecutingRollover(false)
      return
    }

    const fromPeriod = findPeriodById(periods, fromPeriodId)
    if (!fromPeriod?.rootPeriodId) {
      enqueueSnackbar('Invalid source period', { variant: 'error' })
      setIsExecutingRollover(false)
      return
    }

    const request = {
      targetPairs,
      rootOrganizationId: prepareResponse.organizationId,
      templateId: prepareResponse.templateId,
      templateRevision: revision,
      nextRootPeriodId: selectedPeriod.rootPeriodId,
      nextPeriodId: selectedPeriodId,
      prevRootPeriodId: fromPeriod.rootPeriodId,
      prevPeriodId: fromPeriodId,
    }

    executeTemplateRollover(templateId, request)
      .then(rolloverResponse => {
        setTemplate(rolloverResponse.template)
        setActiveStep('confirmation')
      })
      .catch(error => {
        console.error('Error executing template rollover:', error)
        enqueueSnackbar('Failed to execute template rollover', { variant: 'error' })
      })
      .finally(() => {
        setIsExecutingRollover(false)
      })
  }

  const handleContinue = () => {
    handlePrepareRollover()
  }

  const handleBack = () => {
    if (activeStep === 'confirmation' || activeStep === 'target-review') {
      setActiveStep('select-period')
      setPrepareResponse(null)
      setTemplate(null)
      setTargetPairs([])
      if (showSelectPeriodStep) {
        setSelectedPeriodId('')
        setValidationError('')
      }
    }
  }

  const handleMoveToConfirmation = async () => {
    await handleExecuteRollover()
  }

  const handleTargetChange = (targetIndex: number, newValue: any) => {
    const newTargetPairs = [...targetPairs]
    if (newTargetPairs[targetIndex].nextTarget) {
      newTargetPairs[targetIndex] = {
        ...newTargetPairs[targetIndex],
        nextTarget: {
          ...newTargetPairs[targetIndex].nextTarget!,
          value: newValue,
        },
      }
      setTargetPairs(newTargetPairs)
    }
  }

  const handleEditPlan = () => {
    if (template) {
      const templateId = 'template' in template ? template.template.id : template.id

      // Get the selected period to get its start date
      const selectedPeriod = findPeriodById(periods, selectedPeriodId)
      if (!selectedPeriod) {
        enqueueSnackbar('Invalid period selection', { variant: 'error' })
        return
      }

      // Collect allocations and accelerators from all rows
      const allocations: number[] = []
      const accelerators: number[] = []

      compPlan?.plan.variableAllocations.forEach(table => {
        table.rows.forEach(row => {
          if (row.rowType !== 'DISPLAY_ROW' && !row.disabled) {
            allocations.push(row.allocationPercentage)
            accelerators.push(row.maxAllocationPercentage || 0)
          }
        })
      })

      const params = new URLSearchParams({
        templateId: templateId || '',
        withUserId: userId,
        withBaseSalary: compPlan?.plan.baseSalary?.toString() || '',
        withVariablePercent: compPlan?.plan.variablePercent?.toString() || '',
        withVariableCap: compPlan?.plan.variableCap?.toString() || '',
        withAllocations: allocations.join(','),
        withAccelerators: accelerators.join(','),
        withStartDate: selectedPeriod.startDate,
        review: 'true',
      })

      navigate(`/create-comp-plan?${params.toString()}`)
      onClose()
    }
  }

  const renderTargetReview = () => (
    <Box>
      <Alert
        severity='info'
        sx={{ mb: 3 }}
      >
        Please review and adjust the target values that will be rolled over to the new period.
      </Alert>

      <TableContainer>
        <Table
          size='small'
          aria-label='target-review-table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='center'>Component</TableCell>
              <TableCell align='center'>Previous Period</TableCell>
              <TableCell align='center'>New Period</TableCell>
              <TableCell align='center'>Previous Target</TableCell>
              <TableCell align='center'>New Target</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {targetPairs.map((pair, index) => {
              if (!pair.nextTarget) return null

              const originalPeriodLabel =
                pair.originalTarget.periodId ? periodIdLabelMap[pair.originalTarget.periodId] : ''
              const nextPeriodLabel = pair.nextTarget.periodId ? periodIdLabelMap[pair.nextTarget.periodId] : ''

              const component = compPlan?.components?.find(c => c.id === pair.originalTarget.componentId)

              return (
                <TableRow key={`target-${index}`}>
                  <TableCell align='center'>{component?.label}</TableCell>
                  <TableCell align='center'>{originalPeriodLabel}</TableCell>
                  <TableCell align='center'>{nextPeriodLabel}</TableCell>
                  <TableCell align='center'>{pair.originalTarget.value}</TableCell>
                  <TableCell align='center'>
                    <ComponentField
                      componentType={ComponentType.COMPONENT_TYPE_FLOAT}
                      value={pair.nextTarget.value}
                      onChange={value => handleTargetChange(index, value)}
                      name={`target-${index}`}
                      style={{ width: '70%' }}
                      variant='outlined'
                      pill
                      shrink
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )

  return (
    <RolloverContext.Provider value={{ promise: rolloverPromise, setPromise: setRolloverPromise }}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='md'
        fullWidth
        PaperProps={{
          sx: {
            height: '80vh',
            maxHeight: 800,
          },
        }}
      >
        <DialogTitle color='primary'>{`Rollover ${userName}'s ${fromPeriodLabel} Plan`}</DialogTitle>
        <DialogContent>
          {showSelectPeriodStep && activeStep === 'select-period' ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                maxWidth: '600px',
                margin: '0 auto',
                pt: 4,
              }}
            >
              <Box sx={{ mb: 3 }}>
                <Typography
                  variant='h5'
                  color='primary'
                  gutterBottom
                  sx={{ mb: 2 }}
                >
                  Select Target Period to Rollover Plan
                </Typography>
                <Typography
                  variant='body1'
                  gutterBottom
                >
                  This will create a new plan based on the latest approved revision of the current plan. The new plan
                  will be created for the selected period.
                </Typography>
                <Typography
                  variant='body1'
                  color='text.secondary'
                  gutterBottom
                >
                  Note: Any pending changes in unapproved revisions will not be included in the rollover.
                </Typography>
              </Box>
              <Box sx={{ width: '100%', maxWidth: '400px' }}>
                <SinglePeriodSelector
                  periods={periods}
                  onChange={setSelectedPeriodId}
                  value={selectedPeriodId}
                  label='Select Target Period'
                  sortOrder='asc'
                  variant='outlined'
                  pill
                  shrink
                  disableClosed
                  labelBackgroundColor={theme?.palette.background.paper}
                />
                {validationError && (
                  <Typography
                    variant='body2'
                    color='error'
                    sx={{ mt: 1 }}
                  >
                    {validationError}
                  </Typography>
                )}
              </Box>
            </Box>
          : activeStep === 'target-review' ?
            renderTargetReview()
          : activeStep === 'confirmation' &&
            template && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  maxWidth: '600px',
                  margin: '0 auto',
                  pt: 4,
                }}
              >
                <Typography
                  variant='h5'
                  color='primary'
                  gutterBottom
                  sx={{ mb: 2 }}
                >
                  Rollover Plan Prepared
                </Typography>

                <Grid
                  container
                  spacing={2}
                >
                  <Grid size={4}>
                    <Typography
                      variant='subtitle2'
                      color='text.secondary'
                    >
                      Team Member
                    </Typography>
                    <Typography variant='body1'>{userName || 'Team Member'}</Typography>
                  </Grid>

                  <Grid size={4}>
                    <Typography
                      variant='subtitle2'
                      color='text.secondary'
                    >
                      From Period
                    </Typography>
                    <Typography variant='body1'>{fromPeriodLabel || 'Current Period'}</Typography>
                  </Grid>

                  <Grid size={4}>
                    <Typography
                      variant='subtitle2'
                      color='text.secondary'
                    >
                      To Period
                    </Typography>
                    <Typography variant='body1'>{selectedPeriodLabel}</Typography>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />

                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ mt: 2 }}
                >
                  The latest approved revision of the plan and associated targets will be used as the basis for the new
                  plan.
                </Typography>
              </Box>
            )
          }
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button
            onClick={onClose}
            disabled={isLoading || isPreparingRollover || isExecutingRollover || isPlanLoading}
            variant='contained'
            color='warning'
            sx={{ marginRight: 'auto' }}
          >
            Cancel
          </Button>
          {activeStep === 'select-period' ?
            <Button
              onClick={handleContinue}
              color='primary'
              variant='contained'
              disabled={
                !selectedPeriodId ||
                isLoading ||
                isPreparingRollover ||
                isExecutingRollover ||
                isPlanLoading ||
                !!validationError
              }
              startIcon={isPreparingRollover ? <CircularProgress size={20} /> : undefined}
            >
              Next
            </Button>
          : <>
              <Button
                onClick={handleBack}
                disabled={isLoading || isPreparingRollover || isExecutingRollover || isPlanLoading}
                variant='outlined'
                color='primary'
              >
                Back
              </Button>
              <Button
                onClick={activeStep === 'target-review' ? handleMoveToConfirmation : handleEditPlan}
                color='primary'
                variant='contained'
                disabled={isLoading || isPreparingRollover || isExecutingRollover || isPlanLoading}
                startIcon={isLoading || isExecutingRollover ? <CircularProgress size={20} /> : undefined}
                sx={{ ml: 1 }}
              >
                {activeStep === 'target-review' ? 'Next' : 'Review Plan'}
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </RolloverContext.Provider>
  )
}

export default RolloverPlanDialog
