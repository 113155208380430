import type { TextFieldVariants } from '@mui/material'
import { InputAdornment } from '@mui/material'
import React from 'react'
import { NumericFormat } from 'react-number-format'
import { ComponentSourceType } from 'types/components'
import { currencySymbols } from 'utils/currency'
import { TextField } from '../@extended/TextField'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatCustom = function NumericFormatCustom({
  ref,
  ...props
}: CustomProps & { ref?: React.RefObject<HTMLInputElement | null> }) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: string }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
    />
  )
}

interface CurrencyFieldProps {
  id?: string
  label?: string
  value: string
  onChange: (value: any) => void
  name: string
  currency: string
  currencyDecimals?: number
  disabled?: boolean
  placeholder?: string
  style?: React.CSSProperties
  variant?: TextFieldVariants
  pill?: boolean
  shrink?: boolean
  sourceType?: ComponentSourceType
}

const CurrencyField: React.FC<CurrencyFieldProps> = ({
  id,
  label,
  value,
  onChange,
  name,
  currency,
  currencyDecimals,
  disabled = false,
  placeholder,
  style,
  variant = 'outlined',
  pill = false,
  shrink = false,
  sourceType = ComponentSourceType.COMPONENT_SOURCE_MANUAL,
}) => {
  const symbol = currencySymbols[currency] || currency

  if (sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE) {
    // Handle composite input - allow list of values
    return (
      <TextField
        id={id}
        key={`${id}-currency-field-composite`}
        fullWidth
        label={label}
        value={value?.toString() ?? ''}
        onChange={e => {
          console.log('new value', e.target.value)
          onChange(e.target.value)
        }}
        name={name}
        rows={4}
        disabled={disabled}
        placeholder={placeholder || 'Enter values, separated by commas'}
        style={style}
        variant={variant}
        pill={pill}
        shrink={shrink}
      />
    )
  }

  // Original implementation for manual input
  return (
    <TextField
      id={id}
      key={`${id}-currency-field`}
      fullWidth
      label={label}
      value={value?.toString() ?? ''}
      onChange={e => {
        console.log('e', e)
        onChange(e.target.value)
      }}
      name={name}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: {
          decimalScale: currencyDecimals,
          fixedDecimalScale: currencyDecimals !== 0,
        },
        startAdornment: <InputAdornment position='start'>{symbol}</InputAdornment>,
      }}
      disabled={disabled}
      placeholder={placeholder}
      style={style}
      variant={variant}
      pill={pill}
      shrink={shrink}
    />
  )
}

export default CurrencyField
