import { CopyOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import type { Theme } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import { useSearch } from 'contexts/SearchContext'
import useAuth from 'hooks/useAuth'
import { useSearchExpandedTargets } from 'hooks/useComponents'
import type { FC } from 'react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { RowType } from 'types/comp-allocations'
import type { ExpandedTarget } from 'types/components'
import { getTargetCompositeId } from 'types/components'
import { ThemeMode } from 'types/config'
import AchievementCalculationGuide from './AchievementCalculationGuide'
import AllocationAdjuster from './AlocationAdjuster'
import OperatorLegend from './OperatorLegend'

function getSteps(rowType: RowType) {
  return rowType === RowType.DISPLAY_ROW ?
      ['Select Components', 'Customize Formulas']
    : ['Select Components', 'Customize Formulas', 'Adjust Allocations']
}

interface AdvancedRowEditorProps {
  tableIndex: number
  rowIndex: number
  organizationLabels: Record<string, string>
  periodLabel: string
  periodId: string
  exampleSalary: number
  variableCompensation: number
  currentTargetsUsed: ExpandedTarget[]
  currentRequirements: string[]
  currentOptions: string[]
  allocationPercentage: number
  minAllocationPercentage: number
  maxAllocationPercentage: number
  defaultPayoutPercent: number
  minPayoutPercent: number
  maxPayoutPercent: number
  acceleratorInitial: string
  handleUpdateAccelerator: (tableIndex: number, rowIndex: number, accelerator: string) => void
  handleFormulaChange: (requirements: string[], options: string[]) => void
  handleUpdateAllocations: (
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number
  ) => void
  handleCloseModal: () => void // Add this new prop
  handleRowUpdate: (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[],
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number,
    newAccelerator: string
  ) => void
  addTargetIfNotExists: (newTarget: ExpandedTarget) => void
  theme: Theme
  currency: string
  currencyDecimals: number
  rowType: RowType
}

const AdvancedRowEditor: FC<AdvancedRowEditorProps> = ({
  tableIndex,
  rowIndex,
  organizationLabels,
  periodLabel,
  periodId,
  exampleSalary,
  variableCompensation,
  currentTargetsUsed,
  currentRequirements,
  currentOptions,
  allocationPercentage,
  minAllocationPercentage,
  maxAllocationPercentage,
  defaultPayoutPercent,
  minPayoutPercent,
  maxPayoutPercent,
  acceleratorInitial,
  handleFormulaChange,
  handleUpdateAllocations,
  handleUpdateAccelerator,
  handleCloseModal,
  handleRowUpdate,
  addTargetIfNotExists,
  theme,
  currency,
  currencyDecimals,
  rowType,
}) => {
  const { token, profile } = useAuth()
  const [activeStep, setActiveStep] = useState(0)
  const { search, setSearch } = useSearch()
  const [selectedTargets, setSelectedTargets] = useState<ExpandedTarget[]>(currentTargetsUsed)
  const [allocationValues, setAllocationValues] = useState([
    minAllocationPercentage,
    allocationPercentage,
    maxAllocationPercentage,
  ]) // Example values for min, default, max
  const [maxPayout, setMaxPayout] = useState([minPayoutPercent, defaultPayoutPercent, maxPayoutPercent]) // Default value for max payout
  const [formulas, setFormulas] = useState(
    currentRequirements.map((requirement, index) => ({ requirement, formula: currentOptions[index] }))
  )
  const [editMode, setEditMode] = useState<number | null>(null)
  const [variableMap, setVariableMap] = useState<
    Record<string, { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }>
  >(
    {} as Record<
      string,
      { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }
    >
  )
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const { targets, isLoading, isError } = useSearchExpandedTargets(
    token!,
    {
      searchTerm: search,
      organizationIds: selectedOrganizations,
      periodId: [periodId],
      userIds: ['00000000-0000-0000-0000-000000000000'],
    },
    true
  )
  const [accelerator, setAccelerator] = useState(acceleratorInitial)
  const [legendToggle, setLegendToggle] = useState<'achievement' | 'operators'>('operators')

  const formulaRefs = useRef<(HTMLInputElement | null)[]>([])

  const hoverColor = theme.palette.mode == ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  const handleEditFormula = (index: number, field: 'requirement' | 'formula', value: string) => {
    const newFormulas = [...formulas]
    newFormulas[index][field] = replaceSlugsWithVariables(value)
    setFormulas(newFormulas)
  }

  useEffect(() => {
    if (selectedTargets.length > 0) {
      initializeFormulasWithVariables()
    }
  }, [variableMap]) // Runs only once on component mount

  useEffect(() => {
    mapVariablesToTargets()
    initializeFormulasWithVariables()
  }, [selectedTargets])

  const mapVariablesToTargets = () => {
    const varOptions = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ]
    const newVariableMap: Record<
      string,
      { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }
    > = {}
    selectedTargets.forEach((target, index) => {
      const targetId = getTargetCompositeId(target.target)
      newVariableMap[targetId] = {
        targetId,
        targetVar: `Target ${varOptions[index]}`,
        metricVar: `Actual ${varOptions[index]}`,
        targetSlug: target.target.slug,
        metricSlug: target.component.slug,
      }
    })
    setVariableMap(newVariableMap)
  }

  const initializeFormulasWithVariables = () => {
    const updatedFormulas = currentRequirements.map((requirement, index) => {
      const formula = currentOptions[index]
      return {
        requirement: replaceSlugsWithVariables(requirement),
        formula: replaceSlugsWithVariables(formula),
      }
    })
    setFormulas(updatedFormulas)
  }

  const replaceSlugsWithVariables = (text: string) => {
    Object.keys(variableMap).forEach(key => {
      const { targetSlug, metricSlug, targetVar, metricVar } = variableMap[key]
      text = text.replace(new RegExp(`\\b${targetSlug}\\b`, 'g'), targetVar)
      text = text.replace(new RegExp(`\\b${metricSlug}\\b`, 'g'), metricVar)
    })
    return text
  }

  const replaceVariablesWithSlugs = (text: string) => {
    Object.keys(variableMap).forEach(key => {
      const { targetSlug, metricSlug, targetVar, metricVar } = variableMap[key]
      text = text.replace(new RegExp(`\\b${targetVar}\\b`, 'g'), targetSlug)
      text = text.replace(new RegExp(`\\b${metricVar}\\b`, 'g'), metricSlug)
    })
    return text
  }

  const updateFormulasOnRowChange = (newFormulas: { requirement: string; formula: string }[]) => {
    const updatedRequirements = newFormulas.map(formula => formula.requirement).map(replaceVariablesWithSlugs)
    const updatedOptions = newFormulas.map(formula => formula.formula).map(replaceVariablesWithSlugs)

    handleFormulaChange(updatedRequirements, updatedOptions)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleNext = () => {
    if (activeStep === 0 && selectedTargets.length === 0) {
      // Don't proceed if no targets are selected on the first step
      return
    }

    if (activeStep === 1) {
      updateFormulasOnRowChange(formulas)
    }
    if (activeStep === steps.length - 1) {
      // Handle finish action here
      const updatedRequirements = formulas.map(formula => formula.requirement).map(replaceVariablesWithSlugs)
      const updatedOptions = formulas.map(formula => formula.formula).map(replaceVariablesWithSlugs)

      handleRowUpdate(
        tableIndex,
        rowIndex,
        updatedRequirements,
        updatedOptions,
        rowType === RowType.DISPLAY_ROW ? 0 : allocationValues[1],
        rowType === RowType.DISPLAY_ROW ? 0 : allocationValues[0],
        rowType === RowType.DISPLAY_ROW ? 0 : allocationValues[2],
        rowType === RowType.DISPLAY_ROW ? 0 : maxPayout[1],
        rowType === RowType.DISPLAY_ROW ? 0 : maxPayout[0],
        rowType === RowType.DISPLAY_ROW ? 0 : maxPayout[2],
        rowType === RowType.DISPLAY_ROW ? '1.00' : accelerator
      )

      handleCloseModal()
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
  }

  const getTargetLabel = (target: ExpandedTarget) => {
    const targetLabel = target.target.label
    const orgLabel = organizationLabels[target.target.organizationId!]
    const targetWithOrgRemoved = targetLabel.replace(orgLabel, '')
    return `${orgLabel} ${targetWithOrgRemoved}`
  }

  const selectComponentsStep = () => {
    const handleToggle = (targetId: string) => () => {
      const selectedIndex = selectedTargets.findIndex(target => getTargetCompositeId(target.target) === targetId)
      let newSelected: ExpandedTarget[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          selectedTargets,
          targets.targets.find(target => getTargetCompositeId(target.target) === targetId)!
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedTargets.slice(1))
      } else if (selectedIndex === selectedTargets.length - 1) {
        newSelected = newSelected.concat(selectedTargets.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedTargets.slice(0, selectedIndex),
          selectedTargets.slice(selectedIndex + 1)
        )
      }

      setSelectedTargets(newSelected)
      addTargetIfNotExists(targets.targets.find(target => getTargetCompositeId(target.target) === targetId)!)
    }

    const handleClearSelected = () => {
      setSelectedTargets([])
    }

    const customList = (items: ExpandedTarget[], listHeight: string) => (
      <Box
        sx={{
          height: listHeight,
          overflow: 'auto',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '4px',
          mb: 1,
        }}
      >
        <List
          dense
          component='div'
          role='list'
        >
          {items.map(target => {
            const labelId = `transfer-list-item-${getTargetCompositeId(target.target)}-label`

            return (
              <ListItem
                key={getTargetCompositeId(target.target)}
                role='listitem'
                disableGutters
                onClick={handleToggle(getTargetCompositeId(target.target))}
                sx={{
                  '&:hover': {
                    backgroundColor: hoverColor,
                  },
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      selectedTargets.findIndex(
                        item => getTargetCompositeId(item.target) === getTargetCompositeId(target.target)
                      ) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    sx={{
                      '&:hover': {
                        backgroundColor: hoverColor,
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={getTargetLabel(target)}
                />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Box>
    )

    const filteredTargets = isLoading || isError ? [] : targets.targets

    return (
      <Grid
        container
        spacing={5}
        alignItems='stretch'
        sx={{ height: '100%' }}
      >
        <Grid size={{ xs: 6 }}>
          <Typography
            variant='h5'
            gutterBottom
            align='center'
          >
            Available Components
          </Typography>

          <Grid>{customList(filteredTargets, '410px')}</Grid>
          <Grid mt={2}>
            <TextField
              fullWidth
              placeholder='Search components...'
              value={search}
              onChange={handleSearchChange}
              variant='standard'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid mt={2}>
            <MultiOrganizationSelector
              selectedOrganizationIds={selectedOrganizations}
              handleChange={handleOrganizationChange}
              userProfile={profile!}
            />
          </Grid>
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Typography
            variant='h5'
            gutterBottom
            align='center'
          >
            {`Selected Components (${selectedTargets.length})`}
          </Typography>
          {customList(selectedTargets, '450px')}
          <Button
            sx={{ width: '100%' }}
            variant='outlined'
            size='large'
            onClick={handleClearSelected}
            disabled={selectedTargets.length === 0}
            aria-label='clear selected'
          >
            Clear Selected
          </Button>
        </Grid>
      </Grid>
    )
  }

  const customizeFormulasStep = () => {
    const handleAddFormula = () => {
      setFormulas([...formulas, { requirement: '', formula: '' }])
    }

    const handleDeleteFormula = (index: number) => {
      const newFormulas = formulas.filter((_, i) => i !== index)
      setFormulas(newFormulas)
      updateFormulasOnRowChange(newFormulas)
    }

    const handleToggleEditMode = (index: number) => {
      setEditMode(editMode === index ? null : index)
    }

    const handleClickAway = (event: MouseEvent | TouchEvent | React.KeyboardEvent) => {
      // Check if the click occurred within the variable legend
      const variableLegend = document.getElementById('variable-legend')
      if (variableLegend && variableLegend.contains(event.target as Node)) {
        return // Do nothing if the click was inside the variable legend
      }
      setEditMode(null)
    }

    const getFormulatColumnLabel = (rowType: RowType) => {
      if (rowType === RowType.CALCULATION_ROW) {
        return 'Formula'
      }

      if (rowType === RowType.DISPLAY_ROW) {
        return 'Display Value'
      }

      return 'Payout Value'
    }

    return (
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
      >
        <Box
          width='70%'
          pr={2}
        >
          <Box
            mb={2}
            width='100%'
          >
            {variableLegend()}
          </Box>
          <Box
            mt={2}
            width='100%'
          >
            <Grid
              container
              spacing={2}
            >
              <Grid size={{ xs: 5 }}>
                <Typography
                  variant='h5'
                  align='center'
                >
                  Requirement
                </Typography>
              </Grid>
              <Grid size={{ xs: 5 }}>
                <Typography
                  variant='h5'
                  align='center'
                >
                  {getFormulatColumnLabel(rowType)}
                </Typography>
              </Grid>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Grid
                  container
                  spacing={2}
                  p={2}
                  sx={{
                    width: '100%',
                    flexWrap: formulas.length === 1 ? 'nowrap' : 'wrap',
                  }}
                >
                  {formulas.map((formula, index) => (
                    <Fragment key={index}>
                      <Grid
                        size={{ xs: 5 }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: formulas.length === 1 ? '41.66%' : undefined,
                        }}
                      >
                        {editMode === index ?
                          <TextField
                            fullWidth
                            variant='outlined'
                            multiline
                            value={formula.requirement}
                            onChange={e => handleEditFormula(index, 'requirement', e.target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                handleClickAway(e)
                              }
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                textAlign: 'center',
                              },
                            }}
                          />
                        : <Box
                            onClick={() => handleToggleEditMode(index)}
                            sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              cursor: 'pointer',
                            }}
                          >
                            <Box sx={{ width: '90%', textAlign: 'center' }}>
                              {formula.requirement ?
                                <Typography
                                  fontStyle='italic'
                                  align='center'
                                >
                                  {formula.requirement}
                                </Typography>
                              : <Box>
                                  <Typography
                                    align='center'
                                    fontStyle='italic'
                                    color='textSecondary'
                                    fontSize='0.75rem'
                                  >
                                    Click to edit
                                  </Typography>
                                  <Typography
                                    align='center'
                                    fontStyle='italic'
                                    color='textSecondary'
                                    fontSize='0.75rem'
                                  >
                                    {`(example: Actual A < 50)`}
                                  </Typography>
                                </Box>
                              }
                            </Box>
                            <EditOutlined />
                          </Box>
                        }
                      </Grid>
                      <Grid
                        size={{ xs: 5 }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: formulas.length === 1 ? '41.66%' : undefined,
                        }}
                      >
                        {editMode === index ?
                          <TextField
                            fullWidth
                            variant='outlined'
                            multiline
                            value={formula.formula}
                            onChange={e => handleEditFormula(index, 'formula', e.target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                handleClickAway(e)
                              }
                            }}
                            inputRef={el => (formulaRefs.current[index] = el)}
                            sx={{
                              '& .MuiInputBase-input': {
                                textAlign: 'center',
                              },
                            }}
                          />
                        : <Box
                            onClick={() => handleToggleEditMode(index)}
                            sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              cursor: 'pointer',
                            }}
                          >
                            <Box sx={{ width: '90%', textAlign: 'center' }}>
                              {formula.formula ?
                                <Typography
                                  fontStyle='italic'
                                  align='center'
                                >
                                  {formula.formula}
                                </Typography>
                              : <Box>
                                  <Typography
                                    align='center'
                                    fontStyle='italic'
                                    color='textSecondary'
                                    fontSize='0.75rem'
                                  >
                                    Click to edit
                                  </Typography>
                                  <Typography
                                    align='center'
                                    fontStyle='italic'
                                    color='textSecondary'
                                    fontSize='0.75rem'
                                  >
                                    (example: Actual A/Target A or 0.9)
                                  </Typography>
                                </Box>
                              }
                            </Box>
                            <EditOutlined />
                          </Box>
                        }
                      </Grid>
                      <Grid
                        size={{ xs: 2 }}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: formulas.length === 1 ? '16.66%' : undefined,
                        }}
                      >
                        <IconButton onClick={() => handleDeleteFormula(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Grid>
                      {index < formulas.length - 1 && (
                        <Grid size={{ xs: 12 }}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  ))}
                </Grid>
              </ClickAwayListener>
              <Grid size={{ xs: 12 }}>
                <Button onClick={handleAddFormula}>Add Another Formula</Button>
              </Grid>
            </Grid>
            <Grid
              size={{ xs: 12 }}
              mt={6}
            >
              <Typography
                variant='body2'
                color='textSecondary'
                align='left'
              >
                Important notes:
              </Typography>
              <ul>
                <li>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                  >
                    The list of conditions will be evaluated sequentially.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                  >
                    The first condition that matches your situation will be used in the payout calculation.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                  >
                    Subsequent conditions will not be considered once a match is found.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                  >
                    Fixed payout values are still subject to variable max payout limits.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Box>
        </Box>
        <Box
          width='30%'
          pl={2}
        >
          <Box
            mb={2}
            display='flex'
            justifyContent='center'
          >
            <ToggleButtonGroup
              value={legendToggle}
              exclusive
              onChange={handleLegendToggleChange}
              aria-label='Legend toggle'
              size='small'
              sx={{ width: '100%', height: 32 }}
            >
              <ToggleButton
                value='operators'
                aria-label='Available Operators'
                sx={{ width: '50%' }}
              >
                Available Operators
              </ToggleButton>
              <ToggleButton
                value='achievement'
                aria-label='Achievement Calculations'
                sx={{ width: '50%' }}
              >
                Achievement Calculations
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {legendToggle === 'operators' ?
            <OperatorLegend theme={theme} />
          : <AchievementCalculationGuide theme={theme} />}
        </Box>
      </Box>
    )
  }

  const variableLegend = () => {
    const handleCopyVariable = (variable: string) => {
      navigator.clipboard
        .writeText(variable)
        .then(() => {
          // Optionally, you can show a success message here
          console.log('Variable copied to clipboard')
        })
        .catch(err => {
          console.error('Failed to copy variable: ', err)
        })
    }

    return (
      <Accordion
        defaultExpanded
        id='variable-legend'
        sx={{ borderRadius: '15px', overflow: 'hidden' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{ backgroundColor: theme.palette.divider }} // Example of using theme color
        >
          <Typography
            variant='h5'
            align='center'
            color={theme.palette.text.primary}
          >
            Component Variables Legend
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            m={1}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid size={{ xs: 6 }}>
                <Typography variant='h5'>Component</Typography>
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Typography variant='h5'>Target Variable</Typography>
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Typography variant='h5'>Actual Variable</Typography>
              </Grid>
              {selectedTargets.map(target => {
                const targetId = getTargetCompositeId(target.target)
                const targetLabel = getTargetLabel(target)
                return (
                  <Fragment key={targetId}>
                    <Grid size={{ xs: 6 }}>
                      <Typography variant='body2'>{targetLabel}</Typography>
                    </Grid>
                    <Grid
                      size={{ xs: 3 }}
                      container
                      alignItems='center'
                    >
                      <Typography
                        variant='body2'
                        mr={1}
                      >
                        {variableMap[targetId].targetVar}
                      </Typography>
                      <Tooltip title='Copy Target Variable'>
                        <IconButton
                          size='small'
                          onClick={() => handleCopyVariable(variableMap[targetId].targetVar)}
                        >
                          <CopyOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid
                      size={{ xs: 3 }}
                      container
                      alignItems='center'
                    >
                      <Typography
                        variant='body2'
                        mr={1}
                      >
                        {variableMap[targetId].metricVar}
                      </Typography>
                      <Tooltip title='Copy Actual Variable'>
                        <IconButton
                          size='small'
                          onClick={() => handleCopyVariable(variableMap[targetId].metricVar)}
                        >
                          <CopyOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Fragment>
                )
              })}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  const adjustAllocationsStep = () => (
    <AllocationAdjuster
      allocationValues={allocationValues}
      setAllocationValues={setAllocationValues}
      maxPayout={maxPayout}
      setMaxPayout={setMaxPayout}
      exampleSalary={exampleSalary}
      variableCompensation={variableCompensation}
      accelerator={accelerator}
      setAccelerator={setAccelerator}
      theme={theme}
      currency={currency}
      currencyDecimals={currencyDecimals}
      rowType={rowType}
    />
  )

  const steps = getSteps(rowType)

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return selectComponentsStep()
      case 1:
        return customizeFormulasStep()
      case 2:
        return rowType !== RowType.DISPLAY_ROW ? adjustAllocationsStep() : null
      default:
        return <div>Unknown step</div>
    }
  }

  const handleLegendToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: 'achievement' | 'operators') => {
    if (newValue !== null) {
      setLegendToggle(newValue)
    }
  }

  return (
    <Box
      id='advanced-row-editor-box'
      display='flex'
      flexDirection='column'
      height='100%'
    >
      <Tabs
        value={activeStep}
        onChange={(event, newValue) => setActiveStep(newValue)}
        aria-label='Horizontal tabs example'
        variant='fullWidth'
        sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}
      >
        {steps.map((label, index) => {
          if (selectedTargets.length === 0 && index === 1) {
            return (
              <Tab
                key={index}
                label={label}
                disabled
              />
            )
          }

          return (
            <Tab
              key={index}
              label={label}
            />
          )
        })}
      </Tabs>
      <Box
        mt={2}
        flexGrow={1}
        height='100%'
        overflow='auto'
      >
        {renderStepContent(activeStep)}
      </Box>
      <Box
        mt={0}
        display='flex'
        justifyContent='space-between'
      >
        <Button
          variant='contained'
          color='warning'
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Box>
          <Button
            variant='outlined'
            sx={{ mr: 3 }}
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleNext}
            disabled={activeStep === 0 && selectedTargets.length === 0}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AdvancedRowEditor
