import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import { TextField } from 'components/@extended/TextField'
import ComponentField from 'components/ComponentField'
import SimpleTitle from 'components/SimpleTitle'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useGetComponentLabels } from 'hooks/useComponents' // Import the new hook
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { useSnackbar } from 'notistack'
import type { FC } from 'react'
import { startTransition, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ComponentSourceType,
  ComponentType,
  CompositeFunction,
  getInputFormatFromComponentType,
} from 'types/components'
import { ThemeMode } from 'types/config'

const CreateComponent: FC = () => {
  const theme = useTheme()
  const { token, profile, isLoading: isLoadingProfile } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { purcentAppState } = useGlobalState()

  const [selectedOrganization, setSelectedOrganization] = useState<string>('')
  const [label, setLabel] = useState<string>('')
  const [componentType, setComponentType] = useState<ComponentType>(ComponentType.COMPONENT_TYPE_INTEGER)
  const [isOrganizationLevel, setIsOrganizationLevel] = useState<boolean>(true)
  const [isTargetEditable, setIsTargetEditable] = useState<boolean>(true)
  const [restrictedVisibility, setRestrictedVisibility] = useState<boolean>(false)
  const [targetMin, setTargetMin] = useState<string>('')
  const [targetMax, setTargetMax] = useState<string>('')
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [isLabelUsed, setIsLabelUsed] = useState<boolean>(false) // New state for label validation
  const [sourceType, setSourceType] = useState<ComponentSourceType>(ComponentSourceType.COMPONENT_SOURCE_MANUAL)
  const [compositeFunction, setCompositeFunction] = useState<CompositeFunction>(
    CompositeFunction.COMPOSITE_FUNCTION_NONE
  )
  const [lowerIsBetter, setLowerIsBetter] = useState<boolean>(false)

  const { coreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    selectedOrganization ? [selectedOrganization] : [],
    token!
  )

  const { labels, isLoading: isLoadingLabels } = useGetComponentLabels(token!, selectedOrganization) // Use the new hook

  const validateForm = () => {
    setIsFormValid(
      selectedOrganization !== '' &&
        label !== '' &&
        targetMin !== '' &&
        targetMax !== '' &&
        !isLabelUsed &&
        Number(targetMin) <= Number(targetMax) &&
        (sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE ?
          compositeFunction !== CompositeFunction.COMPOSITE_FUNCTION_NONE
        : true)
    )
  }

  useEffect(() => {
    validateForm()
  }, [selectedOrganization, label, targetMin, targetMax, isLabelUsed, sourceType, compositeFunction])

  useEffect(() => {
    if (labels.includes(label)) {
      setIsLabelUsed(true)
    } else {
      setIsLabelUsed(false)
    }
  }, [label, labels])

  const handleSubmit = () => {
    const componentDTO = {
      rootOrganizationId: purcentAppState?.selectedOrganization?.rootOrganizationId || '',
      organizationId: selectedOrganization,
      ledgerAccountId: null,
      isOrganizationLevel,
      isTargetEditable,
      restrictedVisibility,
      targetMin,
      targetMax,
      label,
      format: getInputFormatFromComponentType(componentType),
      sourceType: sourceType,
      componentType,
      lowerIsBetter,
      sourceInfo: JSON.stringify({
        compositeFunction:
          sourceType === ComponentSourceType.COMPONENT_SOURCE_COMPOSITE ?
            compositeFunction
          : CompositeFunction.COMPOSITE_FUNCTION_NONE,
      }),
    }

    postWithToken('components', token!, [componentDTO])
      .then(() => {
        enqueueSnackbar('Component created successfully', { variant: 'success' })
        startTransition(() => {
          navigate('/admin/components/manage')
        })
      })
      .catch(error => {
        const errorMessage = error.message || 'Failed to create component'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
  }

  return (
    <Box>
      <SimpleTitle title='Create Component' />
      <Box mb={3}>
        {isLoadingProfile || isLoadingCoreSettings || isLoadingLabels ?
          <CircularProgress />
        : <Box>
            <Grid
              container
              spacing={3}
            >
              <Grid size={{ xs: 12, sm: 6 }}>
                <SingleOrganizationSelector
                  userProfile={profile!}
                  selectedOrganizationId={selectedOrganization}
                  handleChange={value => {
                    setSelectedOrganization(value)
                    validateForm()
                  }}
                  label='Select Organization'
                  showLabelAbove
                  style={{ marginTop: 3, paddingBottom: 0 }}
                  labelBackgroundColor={theme.palette.background.default}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  label='Component Label'
                  key='label-field'
                  variant='outlined'
                  pill
                  shrink
                  fullWidth
                  value={label}
                  onChange={e => {
                    setLabel(e.target.value)
                    validateForm()
                  }}
                  error={isLabelUsed}
                  helperText={isLabelUsed ? 'Label is already used' : ''}
                  style={{ marginTop: 3, paddingBottom: 0 }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 2.4 }}>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel
                    shrink
                    variant='outlined'
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    Format
                  </InputLabel>
                  <Select
                    id='component-format-select'
                    value={componentType}
                    onChange={e => setComponentType(e.target.value as ComponentType)}
                    fullWidth
                    variant='outlined'
                    style={{ borderRadius: '21px' }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor:
                              theme.palette.mode === ThemeMode.DARK ?
                                theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={ComponentType.COMPONENT_TYPE_INTEGER}>Integer</MenuItem>
                    <MenuItem value={ComponentType.COMPONENT_TYPE_CURRENCY}>Currency</MenuItem>
                    <MenuItem value={ComponentType.COMPONENT_TYPE_PERCENTAGE}>Percentage</MenuItem>
                    <MenuItem value={ComponentType.COMPONENT_TYPE_BOOL}>Boolean</MenuItem>
                    <MenuItem value={ComponentType.COMPONENT_TYPE_FLOAT}>Float</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 2.4 }}>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel
                    shrink
                    variant='outlined'
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    Type
                  </InputLabel>
                  <Select
                    id='component-type-select'
                    value={isOrganizationLevel}
                    onChange={e => setIsOrganizationLevel(e.target.value === 'true')}
                    fullWidth
                    variant='outlined'
                    style={{ borderRadius: '21px' }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor:
                              theme.palette.mode === ThemeMode.DARK ?
                                theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value='true'>Team</MenuItem>
                    <MenuItem value='false'>Individual</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 2.4 }}>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel
                    shrink
                    variant='outlined'
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    Restricted Visibility
                  </InputLabel>
                  <Select
                    id='component-restricted-visibility-select'
                    value={restrictedVisibility}
                    onChange={e => setRestrictedVisibility(e.target.value === 'true')}
                    fullWidth
                    variant='outlined'
                    style={{ borderRadius: '21px' }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor:
                              theme.palette.mode === ThemeMode.DARK ?
                                theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value='true'>Yes</MenuItem>
                    <MenuItem value='false'>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 2.4 }}>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel
                    shrink
                    variant='outlined'
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    Is Target Editable
                  </InputLabel>
                  <Select
                    id='component-is-target-editable-select'
                    value={isTargetEditable}
                    onChange={e => setIsTargetEditable(e.target.value === 'true')}
                    fullWidth
                    variant='outlined'
                    style={{ borderRadius: '21px' }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor:
                              theme.palette.mode === ThemeMode.DARK ?
                                theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value='true'>Yes</MenuItem>
                    <MenuItem value='false'>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 2.4 }}>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel
                    shrink
                    variant='outlined'
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    Lower Is Better
                  </InputLabel>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                      id='component-lower-is-better-select'
                      value={lowerIsBetter}
                      onChange={e => setLowerIsBetter(e.target.value === 'true')}
                      fullWidth
                      variant='outlined'
                      style={{ borderRadius: '21px' }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .MuiMenuItem-root:hover': {
                              backgroundColor:
                                theme.palette.mode === ThemeMode.DARK ?
                                  theme.palette.primary.darker
                                : theme.palette.secondary.dark,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value='true'>Yes</MenuItem>
                      <MenuItem value='false'>No</MenuItem>
                    </Select>
                    <Tooltip
                      title="Enable this when a lower value is considered better performance. For example, 'Number of Errors' or 'Response Time' where lower values indicate better results."
                      placement='right'
                    >
                      <InfoIcon
                        fontSize='small'
                        sx={{ ml: 1, color: theme.palette.grey[500] }}
                      />
                    </Tooltip>
                  </Box>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 3 }}>
                <ComponentField
                  id='component-target-min-field'
                  componentType={componentType}
                  label='Target Min'
                  value={targetMin}
                  onChange={value => {
                    setTargetMin(value)
                    validateForm()
                  }}
                  name='targetMin'
                  disabled={!selectedOrganization || isLoadingCoreSettings}
                  currency={coreSettings?.[0]?.currency || 'USD'}
                  currencyDecimals={coreSettings?.[0]?.currencyDecimals || 0}
                  decimalPlaces={2}
                  style={{ marginTop: 4, paddingBottom: 0 }}
                  pill
                  shrink
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 3 }}>
                <ComponentField
                  id='component-target-max-field'
                  componentType={componentType}
                  label='Target Max'
                  value={targetMax}
                  onChange={value => {
                    setTargetMax(value)
                    validateForm()
                  }}
                  name='targetMax'
                  disabled={!selectedOrganization || isLoadingCoreSettings}
                  currency={coreSettings?.[0]?.currency || 'USD'}
                  currencyDecimals={coreSettings?.[0]?.currencyDecimals || 0}
                  decimalPlaces={2}
                  style={{ marginTop: 4, paddingBottom: 0 }}
                  pill
                  shrink
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 3 }}>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel
                    shrink
                    variant='outlined'
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    Source Type
                  </InputLabel>
                  <Select
                    id='component-source-type-select'
                    value={sourceType}
                    onChange={e => {
                      setSourceType(e.target.value as ComponentSourceType)
                    }}
                    fullWidth
                    variant='outlined'
                    style={{ borderRadius: '21px' }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor:
                              theme.palette.mode === ThemeMode.DARK ?
                                theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={ComponentSourceType.COMPONENT_SOURCE_MANUAL}>Manual</MenuItem>
                    <MenuItem value={ComponentSourceType.COMPONENT_SOURCE_COMPOSITE}>Composite</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 3 }}>
                <FormControl
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel
                    shrink
                    variant='outlined'
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    Composite Function
                  </InputLabel>
                  <Select
                    id='component-composite-function-select'
                    value={compositeFunction}
                    onChange={e => setCompositeFunction(e.target.value as CompositeFunction)}
                    fullWidth
                    variant='outlined'
                    style={{ borderRadius: '21px' }}
                    disabled={sourceType !== ComponentSourceType.COMPONENT_SOURCE_COMPOSITE}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor:
                              theme.palette.mode === ThemeMode.DARK ?
                                theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={CompositeFunction.COMPOSITE_FUNCTION_AVERAGE}>Average</MenuItem>
                    <MenuItem value={CompositeFunction.COMPOSITE_FUNCTION_MEDIAN}>Median</MenuItem>
                    <MenuItem value={CompositeFunction.COMPOSITE_FUNCTION_MODE}>Mode</MenuItem>
                    <MenuItem value={CompositeFunction.COMPOSITE_FUNCTION_COUNT}>Count</MenuItem>
                    <MenuItem value={CompositeFunction.COMPOSITE_FUNCTION_STDDEV}>Standard Deviation</MenuItem>
                    <MenuItem value={CompositeFunction.COMPOSITE_FUNCTION_SUM}>Sum</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Stack
                direction='row'
                justifyContent='space-between'
                spacing={2}
              >
                <AnimateButton>
                  <Button
                    variant='contained'
                    color='warning'
                    onClick={() => {
                      startTransition(() => {
                        navigate('/admin/components/manage')
                      })
                    }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                  >
                    Create Component
                  </Button>
                </AnimateButton>
              </Stack>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default CreateComponent
