import {
  Box,
  Chip,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import Paper from 'components/@extended/Paper'
import { NumericField } from 'components/NumericField'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { ComponentType } from 'types/components'
import type { OrganizationSettingDTO } from 'types/organization'

interface TemplateDefaultsProps {
  settings: OrganizationSettingDTO[]
  handleChange: (index: number, value: string) => void
}

const TemplateDefaults: FC<TemplateDefaultsProps> = ({ settings, handleChange }) => {
  const getSetting = (name: string) => settings.find((s) => s.settingName === name)?.settingValue || ''

  const [variableCompensation, setVariableCompensation] = useState<number[]>([0.1, 0.2, 0.3])
  const [maxPayout, setMaxPayout] = useState<number[]>([1.2, 1.5, 2.0])
  const [defaultTables, setDefaultTables] = useState<string[]>([])
  const [defaultCompensationModel, setDefaultCompensationModel] = useState<string>('BASE_PLUS')
  const [newTable, setNewTable] = useState('')

  // Initialize state values from settings
  useEffect(() => {
    // Parse variable compensation values
    const varCompSetting = getSetting('Default Variable Compensation')
    if (varCompSetting) {
      const varComp = varCompSetting.split(',').map(Number)
      if (varComp.length === 3 && !varComp.some(isNaN)) {
        setVariableCompensation(varComp)
      }
    }

    // Parse max payout values
    const maxPaySetting = getSetting('Default Max Payout')
    if (maxPaySetting) {
      const maxPay = maxPaySetting.split(',').map(Number)
      if (maxPay.length === 3 && !maxPay.some(isNaN)) {
        setMaxPayout(maxPay)
      }
    }

    // Parse default tables
    const tablesSetting = getSetting('Default Tables')
    if (tablesSetting) {
      const tables = tablesSetting.split(',').filter(Boolean)
      setDefaultTables(tables)
    }

    // Parse default compensation model
    const modelSetting = getSetting('Default Compensation Model')
    if (modelSetting && ['BASE_PLUS', 'BONUS_ONLY', 'BONUS_INCLUDED'].includes(modelSetting)) {
      setDefaultCompensationModel(modelSetting)
    }
  }, [settings])

  // Handle variable compensation slider change
  const handleVariableCompChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const decimalValues = newValue.map((v) => v / 100)
      setVariableCompensation(decimalValues)

      const settingIndex = settings.findIndex((s) => s.settingName === 'Default Variable Compensation')
      if (settingIndex !== -1) {
        handleChange(settingIndex, decimalValues.join(','))
      }
    }
  }

  // Handle max payout slider change
  const handleMaxPayoutChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const decimalValues = newValue.map((v) => v / 100)
      setMaxPayout(decimalValues)

      const settingIndex = settings.findIndex((s) => s.settingName === 'Default Max Payout')
      if (settingIndex !== -1) {
        handleChange(settingIndex, decimalValues.join(','))
      }
    }
  }

  // Handle adding a new table
  const handleAddTable = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && newTable.trim()) {
      const updatedTables = [...defaultTables, newTable.trim()]
      setDefaultTables(updatedTables)

      const settingIndex = settings.findIndex((s) => s.settingName === 'Default Tables')
      if (settingIndex !== -1) {
        handleChange(settingIndex, updatedTables.join(','))
      }

      setNewTable('')
    }
  }

  // Handle deleting a table
  const handleDeleteTable = (tableToDelete: string) => {
    const updatedTables = defaultTables.filter((table) => table !== tableToDelete)
    setDefaultTables(updatedTables)

    const settingIndex = settings.findIndex((s) => s.settingName === 'Default Tables')
    if (settingIndex !== -1) {
      handleChange(settingIndex, updatedTables.join(','))
    }
  }

  // Handle compensation model change
  const handleCompensationModelChange = (value: string) => {
    setDefaultCompensationModel(value)

    const settingIndex = settings.findIndex((s) => s.settingName === 'Default Compensation Model')
    if (settingIndex !== -1) {
      handleChange(settingIndex, value)
    }
  }

  // Format percentage for display
  const formatPercentage = (value: number) => `${(value * 100).toFixed(0)}%`
  const formatTooltipPercentage = (value: number) => `${value.toFixed(0)}%`

  // Get default example compensation value
  const defaultExampleCompensation = Number(getSetting('Default Example Compensation')) || 100000

  // Handle example compensation change
  const handleExampleCompensationChange = (value: any) => {
    const settingIndex = settings.findIndex((s) => s.settingName === 'Default Example Compensation')
    if (settingIndex !== -1) {
      handleChange(settingIndex, value)
    }
  }

  return (
    <Paper elevation={0} sx={{ mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        Template Defaults
      </Typography>
      <Grid container spacing={3} p={2}>
        <Grid size={{ xs: 12, sm: 3 }}>
          <FormControl fullWidth>
            <InputLabel>Compensation Type</InputLabel>
            <Select
              value={defaultCompensationModel}
              onChange={(e) => handleCompensationModelChange(e.target.value as string)}
              variant="outlined"
              style={{ borderRadius: '21px' }}
              label="Compensation Type"
            >
              <MenuItem value="BASE_PLUS">Base Plus</MenuItem>
              <MenuItem value="BONUS_ONLY">Bonus Only</MenuItem>
              <MenuItem value="BONUS_INCLUDED">Bonus Included</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <NumericField
            componentFormat={ComponentType.COMPONENT_TYPE_CURRENCY}
            label="Default Example Compensation"
            value={defaultExampleCompensation}
            onValueChange={handleExampleCompensationChange}
            variant="outlined"
            pill
            shrink
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography gutterBottom>Default Variable Compensation</Typography>
          <Grid container justifyContent="left" spacing={2}>
            <Grid size={{ xs: 12 }}>
              <Slider
                value={variableCompensation.map((v) => v * 100)}
                onChange={handleVariableCompChange}
                valueLabelDisplay="auto"
                valueLabelFormat={formatTooltipPercentage}
                min={0}
                max={100}
                marks={[
                  { value: 0, label: '0%' },
                  { value: 100, label: '100%' },
                  { value: variableCompensation[0] * 100, label: formatPercentage(variableCompensation[0]) },
                  { value: variableCompensation[1] * 100, label: formatPercentage(variableCompensation[1]) },
                  { value: variableCompensation[2] * 100, label: formatPercentage(variableCompensation[2]) },
                ]}
                step={1}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography gutterBottom>Default Max Payout</Typography>
          <Grid container justifyContent="left" spacing={2}>
            <Grid size={{ xs: 12 }}>
              <Slider
                value={maxPayout.map((v) => v * 100)}
                onChange={handleMaxPayoutChange}
                valueLabelDisplay="auto"
                valueLabelFormat={formatTooltipPercentage}
                min={100}
                max={300}
                marks={[
                  { value: 100, label: '100%' },
                  { value: 300, label: '300%' },
                  { value: maxPayout[0] * 100, label: formatPercentage(maxPayout[0]) },
                  { value: maxPayout[1] * 100, label: formatPercentage(maxPayout[1]) },
                  { value: maxPayout[2] * 100, label: formatPercentage(maxPayout[2]) },
                ]}
                step={1}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography gutterBottom>Default Tables</Typography>
          <TextField
            fullWidth
            variant="standard"
            value={newTable}
            onChange={(e) => setNewTable(e.target.value)}
            onKeyDown={handleAddTable}
            placeholder="Add a table and press Enter"
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {defaultTables.map((table) => (
              <Chip key={table} label={table} onDelete={() => handleDeleteTable(table)} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TemplateDefaults
