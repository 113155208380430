import type { NavItemType } from 'types/menu'

import { Box, Divider, List, Typography, useMediaQuery } from '@mui/material'
import type { UserProfile } from 'types/user-profile'

import { useTheme } from '@mui/material/styles'
import { useGetMenuMaster } from 'api/menu'
import { HORIZONTAL_MAX_ITEM } from 'config'

import useAuth from 'hooks/useAuth'
import useConfig from 'hooks/useConfig'
import menuItem from 'menu-items'

import { useLayoutEffect, useState } from 'react'
import { MenuOrientation } from 'types/config'
import { checkFeatureFlag } from 'types/feature-flag'
import { hasPermissions } from 'types/permissions'
import NavGroup from './NavGroup'
import NavItem from './NavItem'

interface ColorTheme {
  itemBackground: string
  itemHoverBackground: string
  itemSelectedBackground: string
  itemText: string
  itemSelectedText: string
  itemIcon: string
  itemSelectedIcon: string
}

export interface ColorMap {
  darkCollapsed: ColorTheme
  darkExpanded: ColorTheme
  lightCollapsed: ColorTheme
  lightExpanded: ColorTheme
}

function Navigation() {
  const theme = useTheme()
  const { menuOrientation } = useConfig()
  const { menuMaster } = useGetMenuMaster()

  const { profile, isLoading: profileIsLoading } = useAuth()

  const drawerOpen = menuMaster.isDashboardDrawerOpened
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))

  const [selectedItems, setSelectedItems] = useState<string | undefined>('')
  const [selectedLevel, setSelectedLevel] = useState<number>(0)
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] })

  const checkVisibility = (item: NavItemType, profile: UserProfile, profileIsLoading: boolean) => {
    return (
      hasPermissions(item, profile, profileIsLoading) &&
      (!item.featureFlag || checkFeatureFlag(item.featureFlag, profile, profileIsLoading))
    )
  }

  useLayoutEffect(() => {
    setMenuItems(menuItem)
    // eslint-disable-next-line
  }, [menuItem, profile])

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null
  let lastItemIndex = menuItems.items.length - 1
  let remItems: NavItemType[] = []
  let lastItemId: string

  //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
  // item and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id!
    lastItemIndex = lastItem - 1
    remItems = menuItems.items
      .slice(lastItem - 1, menuItems.items.length)
      .filter(item => checkVisibility(item, profile!, profileIsLoading))
      .map(item => ({
        title: item.title,
        elements: item.children?.filter(child => checkVisibility(child, profile!, profileIsLoading)),
        icon: item.icon,
        ...(item.url && {
          url: item.url,
        }),
      }))
  }

  const colorMap: ColorMap = {
    darkCollapsed: {
      itemBackground: 'transparent',
      itemHoverBackground: theme.palette.action.hover,
      itemSelectedBackground: theme.palette.secondary.darker,
      itemText: theme.palette.text.primary,
      itemSelectedText: theme.palette.primary.main,
      itemIcon: theme.palette.text.primary,
      itemSelectedIcon: theme.palette.common.black,
    },
    darkExpanded: {
      itemBackground: 'transparent',
      itemHoverBackground: theme.palette.action.hover,
      itemSelectedBackground: theme.palette.secondary.darker,
      itemText: theme.palette.text.primary,
      itemSelectedText: theme.palette.primary.main,
      itemIcon: theme.palette.text.primary,
      itemSelectedIcon: theme.palette.primary.main,
    },
    lightCollapsed: {
      itemBackground: 'transparent',
      itemHoverBackground: theme.palette.action.hover,
      itemSelectedBackground: theme.palette.primary.lighter,
      itemText: theme.palette.text.primary,
      itemSelectedText: theme.palette.primary.darker,
      itemIcon: theme.palette.text.primary,
      itemSelectedIcon: theme.palette.primary.main,
    },
    lightExpanded: {
      itemBackground: 'transparent',
      itemHoverBackground: theme.palette.action.hover,
      itemSelectedBackground: theme.palette.divider,
      itemText: theme.palette.text.primary,
      itemSelectedText: theme.palette.primary.darker,
      itemIcon: theme.palette.text.primary,
      itemSelectedIcon: theme.palette.primary.main,
    },
  }

  const navGroups = menuItems.items
    .slice(0, lastItemIndex + 1)
    .filter(item => checkVisibility(item, profile!, profileIsLoading))
    .map((item, index) => {
      switch (item.type) {
        case 'group':
          if (item.url && item.id !== lastItemId) {
            return (
              <List
                key={item.id}
                {...(isHorizontal && { sx: { mt: 0.5 } })}
              >
                {!isHorizontal && index !== 0 && <Divider sx={{ my: 0.5 }} />}
                <NavItem
                  item={item}
                  level={1}
                  isParents
                  colorMap={colorMap}
                />
              </List>
            )
          }

          return (
            <NavGroup
              key={item.id}
              setSelectedItems={setSelectedItems}
              setSelectedLevel={setSelectedLevel}
              selectedLevel={selectedLevel}
              selectedItems={selectedItems}
              lastItem={lastItem!}
              remItems={remItems}
              lastItemId={lastItemId}
              item={item}
              profile={profile!}
              profileIsLoading={profileIsLoading}
              colorMap={colorMap}
            />
          )
        default:
          return (
            <Typography
              key={item.id}
              variant='h6'
              color='error'
              align='center'
            >
              Fix - Navigation Group
            </Typography>
          )
      }
    })

  return (
    <Box
      sx={{
        pt:
          drawerOpen ?
            isHorizontal ? 0
            : 2
          : 0,
        ...(!isHorizontal && {
          '& > ul:first-of-type': { mt: 0 },
        }),
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block',
      }}
    >
      {navGroups}
    </Box>
  )
}

export default Navigation
