import type {
  ID,
  Label,
  OrganizationIDs,
  PaginationInfo,
  PeriodIDs,
  Revision,
  Timestamps,
  UserID,
  Value,
} from './common'
import type { DefaultQueryParams } from './default-params'

export enum ComponentType {
  COMPONENT_TYPE_INTEGER = 'int',
  COMPONENT_TYPE_CURRENCY = 'currency',
  COMPONENT_TYPE_PERCENTAGE = 'percent',
  COMPONENT_TYPE_BOOL = 'bool',
  COMPONENT_TYPE_FLOAT = 'float',
}

export enum InputSourceType {
  INPUT_SOURCE_TYPE_USER = 'user',
  INPUT_SOURCE_TYPE_ORGANIZATION = 'organization',
}

export interface ComponentID {
  componentId: string
}

export interface Slug {
  slug: string
}

export enum ProrationMode {
  PRORATION_MODE_NONE = 'none',
  PRORATION_MODE_AMORTIZE = 'amortize',
  PRORATION_MODE_CUMULATIVE = 'cumulative',
  PRORATION_MODE_AVERAGE = 'average',
}

export enum CompositeFunction {
  COMPOSITE_FUNCTION_NONE = 'none',
  COMPOSITE_FUNCTION_AVERAGE = 'average',
  COMPOSITE_FUNCTION_MEDIAN = 'median',
  COMPOSITE_FUNCTION_MODE = 'mode',
  COMPOSITE_FUNCTION_COUNT = 'count',
  COMPOSITE_FUNCTION_STDDEV = 'stddev',
  COMPOSITE_FUNCTION_SUM = 'sum',
}

export enum ComponentSourceType {
  COMPONENT_SOURCE_MANUAL = 'manual',
  COMPONENT_SOURCE_COMPOSITE = 'composite',
}

export interface Component extends ID, OrganizationIDs, Label, Slug, Timestamps {
  ledgerAccountId: string | null
  isOrganizationLevel: boolean
  isTargetEditable: boolean
  restrictedVisibility: boolean
  targetMin: string
  targetMax: string
  sourceType: ComponentSourceType
  componentType: ComponentType
  sourceInfo?: {
    compositeFunction?: CompositeFunction
  }
  lowerIsBetter: boolean
}

export interface TargetDTO extends OrganizationIDs, UserID, PeriodIDs, ComponentID, Label, Value, Slug {}

export interface CreateTargetDTO {
  organizationId: string
  rootOrganizationId: string
  periodId: string
  rootPeriodId: string
  label: string
  isOrganizationLevel: boolean
  isRestricted: boolean
  componentType: ComponentType
  targetMin: string
  targetMax: string
  value: string
  sourceType: ComponentSourceType
  sourceInfo: string
  lowerIsBetter?: boolean
}

export interface CreateMetricDTO {
  componentId: string
  organizationId: string
  rootOrganizationId: string
  periodId: string
  rootPeriodId: string
  userId: string
  value: string
}

export interface Target extends TargetDTO, Slug, Revision, Pick<Timestamps, 'createdAt'> {
  componentType: ComponentType | null
}

export interface MetricDTO extends OrganizationIDs, PeriodIDs, UserID, ComponentID, Value {}

export interface Metric extends MetricDTO, Revision, Pick<Timestamps, 'createdAt'> {
  label: string | null
  componentType: ComponentType | null
}

export interface ExpandedTarget {
  target: Target
  component: Component
}

export interface ComponentSearchParams {
  periodId?: string[]
  organizationIds?: string[]
  userIds?: string[]
  searchTerm?: string
  perPage?: number
  page?: number
}

export interface ComponentQueryParams extends DefaultQueryParams {
  organizationIds: string[]
  periodIds: string[]
  userIds: string[]
}

export interface ComponentSearchResponse {
  components: Component[]
  paginationInfo: PaginationInfo
}

export interface TargetQueryParams extends DefaultQueryParams {
  organizationIds: string[]
  periodIds: string[]
}

export interface TargetSearchResponse {
  targets: Target[]
  paginationInfo: PaginationInfo
}

export interface MetricQueryParams extends DefaultQueryParams {
  organizationIds: string[]
  periodIds: string[]
}

export interface ExpandedTargetSearchResponse {
  targets: ExpandedTarget[]
  paginationInfo: PaginationInfo
}

export interface MetricSearchResponse {
  metrics: Metric[]
  paginationInfo: PaginationInfo
}

export function formatValue(
  value: string | number,
  componentType: ComponentType,
  includeCurrencySymbol: boolean = true
): string {
  const parsedValue = typeof value === 'string' ? Number.parseFloat(value) : value

  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_INTEGER:
      return parsedValue.toLocaleString()
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      if (!includeCurrencySymbol) {
        return parsedValue.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      }
      return parsedValue.toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return `${parsedValue.toFixed(2)}%`
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return parsedValue.toFixed(2)
    case ComponentType.COMPONENT_TYPE_BOOL:
      return value.toLocaleString().toLocaleLowerCase() === 'true' ? 'True' : 'False'
    default:
      return value.toString()
  }
}

export function unformatValue(formattedValue: string, componentType: ComponentType): string | number {
  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_INTEGER:
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      return formattedValue.replace(/[$,]/g, '')
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return Number.parseFloat(formattedValue.replace('%', ''))
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return Number.parseFloat(formattedValue)
    case ComponentType.COMPONENT_TYPE_BOOL:
      return formattedValue.toLocaleLowerCase() === 'yes' || formattedValue.toLocaleLowerCase() === 'true' ?
          'true'
        : 'false'
    default:
      return formattedValue
  }
}

export function getTargetCompositeId(target: Target): string {
  return `${target.componentId}_${target.periodId}_${target.userId}`
}

export interface NeededTargetMetric {
  componentId: string
  rootOrganizationId: string
  organizationId: string
  rootPeriodId: string
  componentType: ComponentType
  periodId: string
  userId: string
  label: string
  value: string
  firstName: string
  lastName: string
  newValue?: string
  currentActualValue?: string
  sourceType?: ComponentSourceType
  sourceInfo?: SourceInfo
}

export interface SourceInfo {
  compositeFunction?: CompositeFunction
}

export interface SearchNeededTargetMetricsParams {
  organizationIds: string[]
  periodIds: string[]
  excludeMetricsEntered?: boolean
  limit?: number
}

export interface TargetDetailsParams {
  componentId: string
  organizationId: string
  periodId: string
  userId?: string | null
}

export interface MetricDetailsParams {
  componentId: string
  organizationId: string
  periodId: string
  userId?: string | null
}

interface TargetRevision {
  componentId: string
  rootOrganizationId: string
  organizationId: string
  rootPeriodId: string
  periodId: string
  userId: string | null
  label: string
  slug: string
  revision: number
  value: string
  createdAt: string
  approvalBatchId: string | null
  approvalStatus: string
}

interface TargetComponent {
  id: string
  rootOrganizationId: string
  organizationId: string
  ledgerAccountId: string | null
  label: string
  isOrganizationLevel: boolean
  isTargetEditable: boolean
  restrictedVisibility: boolean
  slug: string
  targetMin: string
  targetMax: string
  format: string
  sourceType: string
  componentType: string
  createdAt: string
  updatedAt: string
  sourceInfo?: SourceInfo
}

interface TemplateInfo {
  id: string
  revision: number
  label: string
}

interface PlanInfo {
  id: string
  revision: number
  userId: string
  firstName: string
  lastName: string
  approvalBatchId: string | null
  approvalStatus: string
}

export interface TargetDetailsResponse {
  revisions: TargetRevision[]
  component: TargetComponent
  templates: TemplateInfo[]
  plans: PlanInfo[]
  organization: {
    id: string
    name: string
    parentId: string | null
    rootOrganizationId: string
    createdAt: string
    updatedAt: string
  }
  period: {
    id: string
    parentId: string | null
    organizationId: string
    label: string
    startDate: string
    endDate: string
    payDate: string | null
    createdAt: string
    updatedAt: string
  }
  user: {
    id: string
    email: string
    firstName: string
    lastName: string
    startDate: string
    endDate: string | null
    position: string
    metadata: Record<string, unknown>
    ledgerAccountId: string | null
    createdAt: string
    updatedAt: string
  } | null
  coreSettings: {
    organizationId: string
    defaultLedgerAccount: string
    currency: string
    currencyDecimals: number
    defaultBasePlus: boolean
    defaultExampleCompensation: number
    defaultVariableCompensation: string
    defaultMaxPayout: string
    defaultTemplateTables: string
    targetApprovalChain: string
    metricApprovalChain: string
    planApprovalChain: string
    accrualApprovalChain: string
  }
}

export interface MetricDetailsResponse {
  revisions: MetricRevision[]
  component: TargetComponent
  templates: TemplateInfo[]
  plans: PlanInfo[]
  organization: {
    id: string
    name: string
    parentId: string | null
    rootOrganizationId: string
    createdAt: string
    updatedAt: string
  }
  period: {
    id: string
    parentId: string | null
    organizationId: string
    label: string
    startDate: string
    endDate: string
    payDate: string | null
    createdAt: string
    updatedAt: string
  }
  user: {
    id: string
    email: string
    firstName: string
    lastName: string
    startDate: string
    endDate: string | null
    position: string
    metadata: Record<string, unknown>
    ledgerAccountId: string | null
    createdAt: string
    updatedAt: string
  } | null
  coreSettings: {
    organizationId: string
    defaultLedgerAccount: string
    currency: string
    currencyDecimals: number
    defaultBasePlus: boolean
    defaultExampleCompensation: number
    defaultVariableCompensation: string
    defaultMaxPayout: string
    defaultTemplateTables: string
    targetApprovalChain: string
    metricApprovalChain: string
    planApprovalChain: string
    accrualApprovalChain: string
  }
}

export interface MetricRevision {
  componentId: string
  rootOrganizationId: string
  organizationId: string
  rootPeriodId: string
  periodId: string
  userId: string
  revision: number
  value: string
  approvalBatchId: string
  createdAt: string
  approvalStatus: string
}

export function getInputFormatFromComponentType(componentType: ComponentType): ComponentType {
  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_INTEGER:
      return ComponentType.COMPONENT_TYPE_INTEGER
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      return ComponentType.COMPONENT_TYPE_CURRENCY
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return ComponentType.COMPONENT_TYPE_PERCENTAGE
    case ComponentType.COMPONENT_TYPE_BOOL:
      return ComponentType.COMPONENT_TYPE_BOOL
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return ComponentType.COMPONENT_TYPE_FLOAT
    default:
      throw new Error(`Unknown component type: ${componentType}`)
  }
}

export interface LabelResponse {
  labels: string[]
}

export interface ComponentDetails {
  component: Component
  approvedTargets: number
  approvedMetrics: number
  templates: TemplateInfo[]
  plans: PlanInfo[]
}

export function getCompositeFunction(compositeFunctionStr?: string | object): CompositeFunction {
  try {
    console.log('compositeFunctionStr', compositeFunctionStr)

    if (!compositeFunctionStr) {
      return CompositeFunction.COMPOSITE_FUNCTION_NONE
    }

    // Handle case when compositeFunctionStr is already an object
    if (typeof compositeFunctionStr === 'object') {
      return (compositeFunctionStr as any).compositeFunction || CompositeFunction.COMPOSITE_FUNCTION_NONE
    }

    const parsed = JSON.parse(compositeFunctionStr)
    return parsed.compositeFunction || CompositeFunction.COMPOSITE_FUNCTION_NONE
  } catch (e) {
    return CompositeFunction.COMPOSITE_FUNCTION_NONE
  }
}

export interface MetricReductionResult {
  functionUsed: CompositeFunction
  rawValue: string
  reducedValue: number
}

export interface CompositeActualValueItem {
  label: string
  description: string
  value: string
}

export interface CompositeActualValue {
  values: CompositeActualValueItem[]
  // Future fields can be added here
}

export function reduceMetricValue(metric: Metric, component: Component): MetricReductionResult {
  let functionUsed = CompositeFunction.COMPOSITE_FUNCTION_NONE
  const rawValue = metric.value
  let reducedValue = 0

  if (component.sourceType == ComponentSourceType.COMPONENT_SOURCE_COMPOSITE) {
    console.log('sourceInfo', component.sourceInfo)
    functionUsed = getCompositeFunction(component.sourceInfo)
    console.log('compositeFunction', functionUsed)

    // Parse the value as the new JSON structure
    const compositeValue = JSON.parse(metric.value) as CompositeActualValue
    const values = compositeValue.values.map(item => Number(item.value))

    switch (functionUsed) {
      case CompositeFunction.COMPOSITE_FUNCTION_AVERAGE:
        reducedValue = values.reduce((acc, curr) => acc + curr, 0) / values.length
        break
      case CompositeFunction.COMPOSITE_FUNCTION_MEDIAN:
        reducedValue = values.sort((a, b) => a - b)[Math.floor(values.length / 2)]
        break
      case CompositeFunction.COMPOSITE_FUNCTION_MODE:
        reducedValue =
          values.sort((a, b) => values.filter(v => v === a).length - values.filter(v => v === b).length).pop() || 0
        break
      case CompositeFunction.COMPOSITE_FUNCTION_STDDEV:
        const mean = values.reduce((acc, curr) => acc + curr, 0) / values.length
        const variance = values.reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0) / values.length
        reducedValue = Math.sqrt(variance)
        break
      case CompositeFunction.COMPOSITE_FUNCTION_SUM:
        reducedValue = values.reduce((acc, curr) => acc + curr, 0)
        break
      case CompositeFunction.COMPOSITE_FUNCTION_COUNT:
        reducedValue = values.length
        break
      default:
        reducedValue = 0
    }
  } else {
    reducedValue = rawValue ? Number.parseFloat(rawValue) : 0
  }

  return {
    functionUsed,
    rawValue,
    reducedValue,
  }
}
