import type { Theme } from '@mui/material'
import { Box, LinearProgress, linearProgressClasses, styled, Tooltip, Typography } from '@mui/material'
import ComponentDisplay from 'components/ComponentDisplay'
import ComponentDisplayTooltip from 'components/ComponentDisplayTooltip'
import React from 'react'
import type { CompositeFunction, MetricReductionResult, Target } from 'types/components'
import { ComponentSourceType, ComponentType } from 'types/components'
import { ThemeMode } from 'types/config'

interface TargetRowProps {
  target: Target
  findMetricValue: (targetComponentId: string, targetPeriodId: string) => MetricReductionResult
  calculateProgress: (targetComponentId: string, targetValue: string, targetPeriodId: string) => number
  theme: Theme
  componentType: string
  hideLabel?: boolean
  targetLabel?: string
  periodLabel?: string
}

const TargetRow: React.FC<TargetRowProps> = ({
  target,
  findMetricValue,
  calculateProgress,
  theme,
  componentType,
  hideLabel = false,
  targetLabel,
  periodLabel,
}) => {
  const rawProgress = calculateProgress(target.componentId || '', target.value || '', target.periodId || '')
  const progress = isNaN(rawProgress) ? 0 : rawProgress
  const metricResult = findMetricValue(target.componentId || '', target.periodId || '')
  // const metricValue = metricResult.reducedValue

  const baseBarColor = theme.palette.mode === ThemeMode.DARK ? theme.palette.info : theme.palette.primary
  const barColor = theme.palette.mode === ThemeMode.DARK ? 'primary' : 'primary'
  const barBackgroundColor = theme.palette.mode === ThemeMode.DARK ? baseBarColor.darker : baseBarColor.lighter
  const barBackgroundColor2 = theme.palette.mode === ThemeMode.DARK ? baseBarColor.light : baseBarColor.darker

  const OverTargetLinearProgress = styled(LinearProgress)({
    backgroundColor: barBackgroundColor,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: barBackgroundColor2,
    },
  })

  const DefaultLinearProgress = styled(LinearProgress)({
    backgroundColor: barBackgroundColor,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: barBackgroundColor,
    },
  })

  // Convert string componentType to enum
  const getComponentTypeEnum = (type: string): ComponentType => {
    switch (type) {
      case 'currency':
        return ComponentType.COMPONENT_TYPE_CURRENCY
      case 'percent':
        return ComponentType.COMPONENT_TYPE_PERCENTAGE
      case 'integer':
        return ComponentType.COMPONENT_TYPE_INTEGER
      case 'float':
        return ComponentType.COMPONENT_TYPE_FLOAT
      case 'bool':
        return ComponentType.COMPONENT_TYPE_BOOL
      default:
        return ComponentType.COMPONENT_TYPE_FLOAT
    }
  }

  const componentTypeEnum = getComponentTypeEnum(componentType)
  const overTarget = progress > 0 ? (100 / progress) * 100 : 0

  console.log(overTarget, ' ', progress)

  const linearProgress =
    progress > 100 ? (
      <OverTargetLinearProgress
        color={barColor}
        variant="determinate"
        value={overTarget}
        sx={{ height: 12, borderRadius: 5 }}
      />
    ) : (
      <DefaultLinearProgress
        color={barColor}
        variant="determinate"
        value={progress}
        sx={{ height: 12, borderRadius: 5 }}
      />
    )

  return (
    <Box sx={{ mb: hideLabel ? 0 : 2 }}>
      {!hideLabel && <Typography variant="body1">{target.label}</Typography>}
      {targetLabel && (
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary, display: 'flex', justifyContent: 'space-between' }}
        >
          {targetLabel}
        </Typography>
      )}
      <Tooltip
        title={
          <ComponentDisplayTooltip
            componentType={componentTypeEnum}
            value={metricResult.rawValue}
            sourceType={
              metricResult.functionUsed !== 'none'
                ? ComponentSourceType.COMPONENT_SOURCE_COMPOSITE
                : ComponentSourceType.COMPONENT_SOURCE_MANUAL
            }
          />
        }
        arrow
        enterDelay={200}
        placement="top"
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              maxWidth: 'none',
              padding: 0,
              background: 'transparent',
              border: 'none',
              borderRadius: 0,
              boxShadow: 'none',
            },
          },
        }}
      >
        {linearProgress}
      </Tooltip>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.text.secondary, display: 'flex', justifyContent: 'space-between' }}
      >
        <span>
          Actual:{' '}
          <ComponentDisplay
            componentType={componentTypeEnum}
            value={metricResult.rawValue}
            sourceType={
              metricResult.functionUsed !== 'none'
                ? ComponentSourceType.COMPONENT_SOURCE_COMPOSITE
                : ComponentSourceType.COMPONENT_SOURCE_MANUAL
            }
            compositeFunction={metricResult.functionUsed as CompositeFunction}
            componentLabel={target.label}
            periodLabel={periodLabel}
            fontSize="0.75rem"
          />{' '}
          / Target:{' '}
          <ComponentDisplay componentType={componentTypeEnum} value={target.value || '0'} fontSize="0.75rem" />
        </span>
        <span>{`${Math.round(progress)}%`}</span>
      </Typography>
    </Box>
  )
}

export default TargetRow
