import { useCallback } from 'react'
import { fetchWithToken, postWithToken } from './http'

export interface PeriodComponentTarget {
  id: string
  componentId: string
  organizationId: string
  rootOrganizationId: string
  periodId: string
  rootPeriodId: string
  userId: string | null
  label: string
  value: number
  slug: string
  revision: number
}

export interface PeriodComponentTargetDTO {
  componentId: string
  organizationId: string
  rootOrganizationId: string
  periodId: string
  rootPeriodId: string
  userId: string | null
  label: string
  value: number
}

export interface TargetPair {
  originalTarget: PeriodComponentTarget
  nextTarget: PeriodComponentTargetDTO | null
  existingTarget: PeriodComponentTarget | null
}

export interface ExpandedCompensationTemplate {
  template: {
    id: string
    revision: number
    label: string
    position: string
    rootOrganizationId: string
    rootPeriodId: string
    periodId: string
    periodProration: number
    compensationModel: 'BASE_PLUS' | 'BONUS_INCLUDED' | 'BONUS_ONLY'
    variablePercent: number
    variableCap: number
    minVariablePercent: number
    maxVariablePercent: number
    minVariableCap: number
    maxVariableCap: number
    variableAllocations: any[]
    componentIds: string[]
    organizationIds: string[]
  }
  components: any[]
  targets: any[]
  period: any
}

export interface PrepareRolloverResponse {
  existingTemplate: {
    template: ExpandedCompensationTemplate
  } | null
  targetPairs: TargetPair[]
  organizationId: string
  templateId: string
  nextRootPeriodId: string
  nextPeriodId: string
}

export interface RolloverTemplateRequest {
  targetPairs: TargetPair[]
  rootOrganizationId: string
  templateId: string
  templateRevision: number
  nextRootPeriodId: string
  nextPeriodId: string
  prevRootPeriodId: string
  prevPeriodId: string
}

export interface RolloverTemplateResponse {
  template: ExpandedCompensationTemplate
  targets: PeriodComponentTarget[]
}

export const useTemplateRollover = (token: string) => {
  const prepareTemplateRollover = useCallback(
    async (templateId: string, nextRootPeriodId: string, nextPeriodId: string, revision: number) => {
      try {
        const params: [string, string][] = [
          ['nextRootPeriodId', nextRootPeriodId],
          ['nextPeriodId', nextPeriodId],
          ['revision', revision.toString()],
        ]
        const response = (await fetchWithToken(
          `rollovers/templates/${templateId}/prepare`,
          token,
          params
        )) as PrepareRolloverResponse
        return response
      } catch (error) {
        throw error
      }
    },
    [token]
  )

  const executeTemplateRollover = useCallback(
    async (templateId: string, request: RolloverTemplateRequest) => {
      try {
        const response = (await postWithToken(
          `rollovers/templates/${templateId}/rollover`,
          token,
          request
        )) as RolloverTemplateResponse
        return response
      } catch (error) {
        throw error
      }
    },
    [token]
  )

  return {
    prepareTemplateRollover,
    executeTemplateRollover,
  }
}
