import type { SelectChangeEvent, Theme } from '@mui/material'
import {
  Box,
  Button,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { TextField as SearchField } from 'components/@extended/TextField'
import CircularLoader from 'components/CircularLoader'
import ComponentDisplay from 'components/ComponentDisplay'
import ComponentField from 'components/ComponentField'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import { useSearch } from 'contexts/SearchContext'
import useAuth from 'hooks/useAuth'
import { useSearchExpandedTargets } from 'hooks/useComponents'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { RowType } from 'types/comp-allocations'
import type { ExpandedTarget } from 'types/components'
import { ThemeMode } from 'types/config'
import AllocationAdjuster from './AlocationAdjuster'

const getSteps = (showAchievementStep: boolean) => {
  if (showAchievementStep) {
    return ['Select a Component', 'Set a Hurdle', 'Choose Achievement Calculation', 'Adjust Allocation']
  } else {
    return ['Select a Component', 'Set a Hurdle', 'Adjust Allocation']
  }
}

interface SimpleRowEditorProps {
  organizationLabels: Record<string, string>
  periodId: string
  periodLabel: string
  exampleSalary: number
  variableCompensation: number
  acceleratorInitial: string
  handleUpdateAccelerator: (tableIndex: number, rowIndex: number, accelerator: string) => void
  handleCloseModal: () => void
  handleFormulaChange: (requirements: string[], options: string[]) => void
  handleUpdateAllocations: (
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number
  ) => void
  tableIndex: number
  rowIndex: number
  handleRowUpdate: (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[],
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number,
    newAccelerator: string
  ) => void
  addTargetIfNotExists: (newTarget: ExpandedTarget) => void
  theme: Theme
  currency: string
  currencyDecimals: number
  rowType: RowType
}

const SimpleRowEditor: FC<SimpleRowEditorProps> = ({
  organizationLabels,
  periodId,
  periodLabel,
  exampleSalary,
  variableCompensation,
  acceleratorInitial,
  handleUpdateAccelerator,
  handleCloseModal,
  handleFormulaChange,
  handleUpdateAllocations,
  tableIndex,
  rowIndex,
  handleRowUpdate,
  addTargetIfNotExists,
  theme,
  currency,
  currencyDecimals,
  rowType,
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const { search, setSearch } = useSearch()
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const { token, profile, isLoading: isLoadingProfile } = useAuth()
  const { targets, isLoading, isError } = useSearchExpandedTargets(
    token!,
    {
      searchTerm: search,
      organizationIds: selectedOrganizations,
      periodId: [periodId],
      userIds: ['00000000-0000-0000-0000-000000000000'],
      perPage: 100,
    },
    true
  )
  const [selectedTarget, setSelectedTarget] = useState<ExpandedTarget | null>(null)
  const [hurdleValue, setHurdleValue] = useState('')
  const [allocationValues, setAllocationValues] = useState([0.1, 0.2, 0.3])
  const [maxPayout, setMaxPayout] = useState([1.1, 1.5, 1.9])
  const [comparisonOperator, setComparisonOperator] = useState('>=')
  const [achievementCalculation, setAchievementCalculation] = useState('standard')
  const [adjustmentValue, setAdjustmentValue] = useState('1')
  const [accelerator, setAccelerator] = useState(acceleratorInitial)
  const [showAchievementStep, setShowAchievementStep] = useState(false)

  const steps = getSteps(showAchievementStep)

  const isNextDisabled = activeStep === 0 && !selectedTarget
  const isFinishEnabled = activeStep === steps.length - 1

  const hoverColor =
    theme.palette.mode == ThemeMode.DARK ? theme.palette.secondary.lighter : theme.palette.secondary.main

  const handleFinish = () => {
    // Set formula requirements and options
    let requirements: string[] = []
    let options: string[] = []

    if (selectedTarget) {
      // Determine the formula based on whether lowerIsBetter is true
      let formula

      if (showAchievementStep) {
        // Use the selected achievement calculation method
        switch (achievementCalculation) {
          case 'inverse-adjusted':
            formula = `(${selectedTarget.target.slug}+${adjustmentValue})/(${selectedTarget.component.slug}+${adjustmentValue})`
            break
          case 'inverse-simple':
            formula = `${selectedTarget.target.slug}/${selectedTarget.component.slug}`
            break
          case 'linear':
            formula = `${adjustmentValue}-(${selectedTarget.component.slug}/${selectedTarget.target.slug})`
            break
          default:
            formula = `${selectedTarget.component.slug}/${selectedTarget.target.slug}`
        }
      } else {
        // For higher is better, always use standard calculation
        formula = `${selectedTarget.component.slug}/${selectedTarget.target.slug}`
      }

      options = [formula]

      if (hurdleValue) {
        requirements = [`${selectedTarget.component.slug} ${comparisonOperator} ${hurdleValue}`]
      }
    }

    handleFormulaChange(requirements, options)

    // Sort allocation values and max payout values
    const sortedAllocationValues = [...allocationValues].sort((a, b) => a - b)
    const sortedMaxPayout = [...maxPayout].sort((a, b) => a - b)

    const minAllocationPercentage = sortedAllocationValues[0]
    const allocationPercentage = sortedAllocationValues[1]
    const maxAllocationPercentage = sortedAllocationValues[2]
    const minPayoutPercent = sortedMaxPayout[0]
    const defaultPayoutPercent = sortedMaxPayout[1]
    const maxPayoutPercent = sortedMaxPayout[2]

    handleUpdateAllocations(
      allocationPercentage,
      minAllocationPercentage,
      maxAllocationPercentage,
      defaultPayoutPercent,
      minPayoutPercent,
      maxPayoutPercent
    )

    handleUpdateAccelerator(tableIndex, rowIndex, accelerator)

    handleRowUpdate(
      tableIndex,
      rowIndex,
      requirements,
      options,
      allocationPercentage,
      minAllocationPercentage,
      maxAllocationPercentage,
      defaultPayoutPercent,
      minPayoutPercent,
      maxPayoutPercent,
      accelerator
    )

    handleCloseModal()
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    console.log('search', search)
  }, [search])

  const handleNext = () => {
    if (activeStep === 1 && selectedTarget && hurdleValue) {
      const requirement = `${selectedTarget.component.slug} ${comparisonOperator} ${hurdleValue}`

      // Determine the formula based on whether lowerIsBetter is true
      let option
      if (showAchievementStep) {
        // Use the selected achievement calculation method
        switch (achievementCalculation) {
          case 'inverse-adjusted':
            option = `(${selectedTarget.target.slug}+${adjustmentValue})/(${selectedTarget.component.slug}+${adjustmentValue})`
            break
          case 'inverse-simple':
            option = `${selectedTarget.target.slug}/${selectedTarget.component.slug}`
            break
          case 'linear':
            option = `${adjustmentValue}-(${selectedTarget.component.slug}/${selectedTarget.target.slug})`
            break
          default:
            option = `${selectedTarget.component.slug}/${selectedTarget.target.slug}`
        }
      } else {
        // For higher is better, always use standard calculation
        option = `${selectedTarget.component.slug}/${selectedTarget.target.slug}`
      }

      handleFormulaChange([requirement], [option])
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
  }

  const handleSelectTarget = (target: ExpandedTarget) => {
    setSelectedTarget(target)
    addTargetIfNotExists(target)
    setHurdleValue('')
    setAllocationValues([0.1, 0.2, 0.3])
    setMaxPayout([1.1, 1.5, 1.9])

    // Check if the component has lowerIsBetter set to true
    const isLowerBetter = target.component.lowerIsBetter || false
    setShowAchievementStep(isLowerBetter)

    // If lowerIsBetter is true, set a default achievement calculation method
    if (isLowerBetter) {
      setAchievementCalculation('inverse-adjusted')
      setAdjustmentValue('1')
    } else {
      // For higher is better (default), use standard calculation
      setAchievementCalculation('standard')
    }

    handleNext()
  }

  const handleHurdleChange = (value: string) => {
    console.log('value', value)
    setHurdleValue(value)
  }

  const handleOperatorChange = (event: SelectChangeEvent) => {
    setComparisonOperator(event.target.value)
  }

  const handleAchievementCalculationChange = (event: SelectChangeEvent) => {
    const newCalculation = event.target.value
    setAchievementCalculation(newCalculation)

    // Set default adjustment values based on calculation method
    if (newCalculation === 'linear') {
      setAdjustmentValue('2')
    } else if (newCalculation === 'inverse-adjusted') {
      setAdjustmentValue('1')
    }
  }

  const handleAdjustmentValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // Allow empty string, numbers, and decimal points
    if (value === '' || /^-?\d*\.?\d*$/.test(value)) {
      setAdjustmentValue(value)
    }
  }

  function getTargetLabel(target: ExpandedTarget) {
    const targetLabel = target.target.label
    const orgLabel = organizationLabels[target.target.organizationId!]
    const targetWithOrgRemoved = targetLabel.replace(orgLabel, '')
    return `${orgLabel} ${targetWithOrgRemoved}`
  }

  const selectComponentStep = () => {
    const targetSection =
      isLoading || isError ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
            p: 2,
          }}
        >
          {targets.targets.map((target, index) => (
            <Box
              key={index}
              p={1}
              sx={{
                '&:hover': {
                  backgroundColor: hoverColor,
                  transition: 'background-color 0.3s',
                },
              }}
            >
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{getTargetLabel(target)}</Box>
                <Button variant="contained" color="primary" onClick={() => handleSelectTarget(target)}>
                  Select
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      )

    return (
      <Grid container>
        <Grid size={12}>
          <Grid container spacing={2} p={2}>
            <Grid size={3}>
              <SearchField
                value={search}
                onChange={handleSearchChange}
                placeholder="Enter Search Term"
                label="Search"
                fullWidth
                pill
                shrink
              />
            </Grid>
            <Grid size={3}>
              <MultiOrganizationSelector
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
                userProfile={profile!}
                labelBackgroundColor={theme.palette.background.default}
              />
            </Grid>
            <Grid size={6}> </Grid>
          </Grid>
          <Grid container spacing={2} p={2}>
            <Grid size={12}>{targetSection}</Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const setHurdleStep = () => {
    if (!selectedTarget) {
      return <div>No target selected</div>
    }

    const { target, component } = selectedTarget
    const componentLabel = component.label.trimEnd()
    // const targetValue = formatValue(Number(target.value), selectedTarget.component.componentType)

    return (
      <Box p={2} m={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <Box p={2} m={2} display="flex" flexDirection="column">
          <Box mb={8}>
            <Typography component="span" fontWeight="bold" color="primary">
              {componentLabel}
            </Typography>{' '}
            has a target of{' '}
            <ComponentDisplay
              value={target.value}
              componentType={component.componentType}
              currency={currency}
              currencyDecimals={currencyDecimals}
              fontWeight="bold"
              color="primary"
            />{' '}
            in{' '}
            <Typography component="span" fontWeight="bold" color="primary">
              {periodLabel}
            </Typography>
            .
          </Box>
          <Typography variant="h5" gutterBottom>
            Set an achievement hurdle for {componentLabel}:
          </Typography>
          <Box display="flex" alignItems="center" width="100%">
            <FormControl sx={{ minWidth: 200, mr: 4 }}>
              <InputLabel
                shrink
                sx={{
                  '&.MuiInputLabel-shrink': {
                    background: theme.palette.background.paper,
                  },
                }}
              >
                Comparison Operator
              </InputLabel>
              <Select
                value={comparisonOperator}
                onChange={handleOperatorChange}
                label="Comparison Operator"
                style={{ width: '100%', borderRadius: '21px' }}
              >
                <MenuItem value=">">Greater Than</MenuItem>
                <MenuItem value=">=">Greater Than or Equal</MenuItem>
                <MenuItem value="<">Less Than</MenuItem>
                <MenuItem value="<=">Less Than or Equal</MenuItem>
                <MenuItem value="=">Equal To</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ width: '200px' }}>
              <ComponentField
                value={hurdleValue}
                onChange={handleHurdleChange}
                componentType={component.componentType}
                name=""
                label="Hurdle Value"
                placeholder="Enter a value"
                currency={currency}
                currencyDecimals={currencyDecimals}
                variant="outlined"
                shrink
                pill
              />
            </Box>
          </Box>
          <Typography variant="body2" color="textSecondary" mt={1}>
            You can leave it blank for no hurdle.
          </Typography>
        </Box>
      </Box>
    )
  }

  const chooseAchievementCalculationStep = () => {
    if (!selectedTarget) {
      return <div>No target selected</div>
    }

    return (
      <Box
        p={2}
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        sx={{ overflowY: 'auto' }}
      >
        <Box p={2} m={2} display="flex" flexDirection="column" maxWidth="800px">
          <Typography variant="h5" color="primary" gutterBottom>
            Choose Achievement Ratio Calculation
          </Typography>

          <Typography variant="body1" paragraph>
            The Achievement Ratio determines how performance is calculated relative to target. This ratio affects how
            payouts scale when performance is above or below target.
          </Typography>

          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <InputLabel
              shrink
              sx={{
                '&.MuiInputLabel-shrink': {
                  background: theme.palette.background.paper,
                },
              }}
            >
              Achievement Calculation Method
            </InputLabel>
            <Select
              labelId="achievement-calculation-label"
              value={achievementCalculation}
              onChange={handleAchievementCalculationChange}
              label="Achievement Calculation Method"
              style={{ width: '100%', borderRadius: '21px' }}
            >
              <MenuItem value="standard">
                <Typography>Standard (Actual / Target)</Typography>
              </MenuItem>
              <MenuItem value="inverse-adjusted">
                <Typography>Inverse with Adjustment ((Target + c) / (Actual + c))</Typography>
              </MenuItem>
              <MenuItem value="inverse-simple">
                <Typography>Simple Inverse (Target / Actual)</Typography>
              </MenuItem>
              <MenuItem value="linear">
                <Typography>Linear Adjustment (2 - (Actual / Target))</Typography>
              </MenuItem>
            </Select>
          </FormControl>

          {(achievementCalculation === 'inverse-adjusted' || achievementCalculation === 'linear') && (
            <Box sx={{ mb: 2 }}>
              <SearchField
                label={achievementCalculation === 'linear' ? 'Linear Adjustment Value' : 'Constant Value (c)'}
                value={adjustmentValue}
                onChange={handleAdjustmentValueChange}
                fullWidth
                pill
                shrink
                placeholder={achievementCalculation === 'linear' ? 'Default: 2' : 'Default: 1'}
                helperText={
                  achievementCalculation === 'linear'
                    ? 'The value used in the formula: [Value] - (Actual ÷ Target). Enter a decimal or integer value.'
                    : 'The constant added to both Target and Actual to prevent division by zero. Enter a decimal or integer value.'
                }
                error={adjustmentValue !== '' && isNaN(Number(adjustmentValue))}
                sx={{ mb: 2 }}
              />
            </Box>
          )}

          <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1, border: 1, borderColor: 'divider', mb: 3 }}>
            {achievementCalculation === 'standard' && (
              <>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Standard: Actual / Target
                </Typography>
                <Typography variant="body2" paragraph>
                  This is the default calculation, best for metrics where higher values are better (e.g., sales,
                  revenue).
                </Typography>
                <Typography variant="body2" paragraph>
                  <strong>Example:</strong> If the target is $100,000 in sales and actual performance is $120,000, the
                  achievement ratio is 120,000 / 100,000 = 1.2 (120%).
                </Typography>
                <Typography variant="body2">
                  • When Actual = Target: Achievement Ratio = 1 (100% payout)
                  <br />• When Actual {'>'} Target: Achievement Ratio {'>'} 1 (payout increases)
                  <br />• When Actual {'<'} Target: Achievement Ratio {'<'} 1 (payout decreases)
                </Typography>
              </>
            )}

            {achievementCalculation === 'inverse-adjusted' && (
              <>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Inverse with Adjustment: (Target + c) / (Actual + c)
                </Typography>
                <Typography variant="body2" paragraph>
                  Best for metrics where lower values are better (e.g., accidents, errors, ranking). The constant c
                  (default = 1) prevents division by zero.
                </Typography>
                <Typography variant="body2" paragraph>
                  <strong>Example:</strong> If the target is 5 accidents and actual performance is 1 accident, with c ={' '}
                  {adjustmentValue}, the achievement ratio is (5 + {adjustmentValue}) / (1 + {adjustmentValue}) ={' '}
                  {(5 + Number(adjustmentValue)).toFixed(2)} / {(1 + Number(adjustmentValue)).toFixed(2)} ={' '}
                  {((5 + Number(adjustmentValue)) / (1 + Number(adjustmentValue))).toFixed(2)}.
                </Typography>
                <Typography variant="body2">
                  • When Actual = Target: Achievement Ratio = 1 (100% payout)
                  <br />• When Actual {'<'} Target: Achievement Ratio {'>'} 1 (payout increases)
                  <br />• When Actual {'>'} Target: Achievement Ratio {'<'} 1 (payout decreases)
                  <br />• When Actual = 0: Achievement Ratio = (Target + c) ÷ c ={' '}
                  {adjustmentValue !== '0'
                    ? `(Target + ${adjustmentValue}) ÷ ${adjustmentValue}`
                    : 'undefined (division by zero)'}
                </Typography>
              </>
            )}

            {achievementCalculation === 'inverse-simple' && (
              <>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Simple Inverse: Target ÷ Actual
                </Typography>
                <Typography variant="body2" paragraph>
                  Simpler calculation for metrics where lower values are better, but requires special handling when
                  Actual = 0.
                </Typography>
                <Typography variant="body2" paragraph>
                  <strong>Example:</strong> If the target is 10 defects and actual performance is 2 defects, the
                  achievement ratio is 10 ÷ 2 = 5 (500%).
                </Typography>
                <Typography variant="body2">
                  • When Actual = Target: Achievement Ratio = 1 (100% payout)
                  <br />• When Actual {'<'} Target: Achievement Ratio {'>'} 1 (payout increases)
                  <br />• When Actual {'>'} Target: Achievement Ratio {'<'} 1 (payout decreases)
                  <br />• When Actual = 0: Special case - typically set to a maximum value
                </Typography>
              </>
            )}

            {achievementCalculation === 'linear' && (
              <>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Linear Adjustment: {adjustmentValue} - (Actual ÷ Target)
                </Typography>
                <Typography variant="body2" paragraph>
                  Alternative approach for metrics where lower values are better. With default value of 2, caps maximum
                  achievement at 200%.
                </Typography>
                <Typography variant="body2" paragraph>
                  <strong>Example:</strong> If the target is 5 errors and actual performance is 2 errors, the
                  achievement ratio is {adjustmentValue} - (2 ÷ 5) = {adjustmentValue} - 0.40 ={' '}
                  {(Number(adjustmentValue) - 0.4).toFixed(2)}.
                </Typography>
                <Typography variant="body2">
                  • When Actual = Target: Achievement Ratio = {adjustmentValue} - 1 ={' '}
                  {(Number(adjustmentValue) - 1).toFixed(2)}
                  <br />• When Actual {'<'} Target: Achievement Ratio {'>'} {(Number(adjustmentValue) - 1).toFixed(2)}{' '}
                  (payout increases)
                  <br />• When Actual {'>'} Target: Achievement Ratio {'<'} {(Number(adjustmentValue) - 1).toFixed(2)}{' '}
                  (payout decreases)
                  <br />• When Actual = 0: Achievement Ratio = {adjustmentValue}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  const adjustAllocationsStep = () => (
    <AllocationAdjuster
      allocationValues={allocationValues}
      setAllocationValues={setAllocationValues}
      maxPayout={maxPayout}
      setMaxPayout={setMaxPayout}
      exampleSalary={exampleSalary}
      variableCompensation={variableCompensation}
      accelerator={accelerator}
      setAccelerator={setAccelerator}
      theme={theme}
      currency={currency}
      currencyDecimals={currencyDecimals}
      rowType={rowType}
    />
  )

  const renderStepContent = (step: number) => {
    if (!showAchievementStep && step >= 2) {
      switch (step) {
        case 0:
          return <div>{selectComponentStep()}</div>
        case 1:
          return <div>{setHurdleStep()}</div>
        case 2:
          return adjustAllocationsStep()
        default:
          return <div>Unknown step</div>
      }
    } else {
      switch (step) {
        case 0:
          return <div>{selectComponentStep()}</div>
        case 1:
          return <div>{setHurdleStep()}</div>
        case 2:
          return <div>{chooseAchievementCalculationStep()}</div>
        case 3:
          return adjustAllocationsStep()
        default:
          return <div>Unknown step</div>
      }
    }
  }

  console.log(activeStep)

  if (isLoadingProfile) return <div>Loading profile...</div>

  return (
    <Box id="simple-row-editor-box" display="flex" flexDirection="column" height="100%">
      <Stepper activeStep={activeStep} orientation="horizontal">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        mt={2}
        flexGrow={1}
        height="100%"
        overflow="auto"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0, // This is important to make the scrolling work properly
        }}
      >
        {renderStepContent(activeStep)}
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="warning" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Box>
          <Button variant="outlined" sx={{ mr: 3 }} disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={isFinishEnabled ? handleFinish : handleNext}
            disabled={isNextDisabled}
          >
            {isFinishEnabled ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SimpleRowEditor
