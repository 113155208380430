import type { Theme } from '@mui/material/styles'

export default function Autocomplete(theme: Theme) {
  const { palette } = theme
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: '3px 9px',
          },
        },
        popupIndicator: {
          width: 'auto',
          height: 'auto',
        },
        clearIndicator: {
          width: 'auto',
          height: 'auto',
        },
        option: {
          '&.MuiAutocomplete-option.Mui-focused': {
            backgroundColor: palette.action.hover,
          },
        },
      },
    },
  }
}
