import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { Box } from '@mui/system'
import { createPlan } from 'api/plan'
import AnimateButton from 'components/@extended/AnimateButton'
import useAuth from 'hooks/useAuth'
import type { FC } from 'react'
import React, { Suspense } from 'react'
import { useNavigate } from 'react-router'
import type { CompensationPlanCreateDTO } from 'types/comp-plan'
import type { Component } from 'types/components'
import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'
import CompensationPlanDetailForm from './CompensationPlanDetailForm'
import ComponentSelectionForm from './ComponentSelectionForm'
import EmployeeDetailForm from './EmployeeDetailForm'
import ReviewPlan from './ReviewPlan'

function CreatePlanWizard() {
  const { token } = useAuth()
  const navigate = useNavigate()
  const { plan, validPlanConfig, setMode, targetDTOs, compensationTemplate, review, setValidPlanConfig } =
    useCreatePlanContext()

  // Wizard data
  const [activeStep, setActiveStep] = React.useState(review ? 3 : 0)
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false)
  const [isMutating, setIsMutating] = React.useState(false)
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  })

  // Add effect to validate plan when in review mode
  React.useEffect(() => {
    if (review) {
      // First check if we have all required data
      if (!plan.userId || !plan.effectiveDate || !compensationTemplate) {
        setValidPlanConfig(false)
        return
      }

      // Validate employee information
      if (plan.userId === '' || plan.effectiveDate === '') {
        setValidPlanConfig(false)
        return
      }

      // Validate compensation plan details
      if (
        !(plan.baseSalary > 0) ||
        !(plan.variablePercent > 0) ||
        !(plan.variableCap >= 0) ||
        !(plan.variablePercent <= plan.variableCap) ||
        !(plan.periodProration > 0)
      ) {
        setValidPlanConfig(false)
        return
      }

      // Validate allocations
      let allocationTotal = 0
      plan.variableAllocations.forEach(component => {
        component.rows.forEach(row => {
          const disabled = row.disabled !== undefined && row.disabled != null && row.disabled
          if (row.rowType !== 'DISPLAY_ROW' && !disabled) {
            allocationTotal += row.allocationPercentage * 100
          }
        })
      })

      if (allocationTotal !== 100) {
        setValidPlanConfig(false)
        return
      }

      // If we get here, all validations passed
      setValidPlanConfig(true)
    }
  }, [review, plan, compensationTemplate, setValidPlanConfig])

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  const stepLabels = ['Employee Information', 'Compensation Plan', 'Components', 'Review Plan']

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        setMode('EDIT')
        return <EmployeeDetailForm />
      case 1:
        setMode('EDIT')
        return (
          <>
            <CompensationPlanDetailForm />{' '}
          </>
        )
      case 2:
        setMode('EDIT')
        return (
          <>
            <ComponentSelectionForm />
          </>
        )
      case 3:
        setMode('REVIEW')
        return <ReviewPlan />
      default:
        throw new Error('Unknown Comp Plan Creation Step')
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const validateStep = () => {
    switch (activeStep) {
      case 0:
        if (plan.userId === '' || plan.effectiveDate === '') {
          return false
        }
        return true
      case 1:
        if (
          plan.baseSalary > 0 &&
          plan.variablePercent > 0 &&
          plan.variableCap >= 0 &&
          plan.variablePercent <= plan.variableCap &&
          plan.periodProration > 0
        ) {
          return true
        }
        return false
      case 2:
        let allocationTotal = 0
        plan.variableAllocations.forEach(component => {
          component.rows.forEach(row => {
            const disabled = row.disabled !== undefined && row.disabled != null && row.disabled

            if (row.rowType !== 'DISPLAY_ROW' && !disabled) {
              allocationTotal += row.allocationPercentage * 100
            }
          })
        })

        if (allocationTotal === 100) {
          return true
        }
        return false
      case 3:
        return validPlanConfig
      default:
        return false
    }
  }

  const savePlan = async () => {
    setIsMutating(true)

    const activeTargetComponentIds = plan.variableAllocations.reduce((acc: Set<string>, table) => {
      const activeTargetsForTable = table.rows.reduce((rowAcc: Set<string>, row) => {
        if (!(row.disabled ?? false)) {
          ;(row.componentIds ?? []).forEach(id => {
            rowAcc.add(id)
          })
        }
        return rowAcc
      }, new Set<string>())

      activeTargetsForTable.forEach(id => acc.add(id))
      return acc
    }, new Set<string>())
    // Convert Set back to array if needed elsewhere as array
    const uniqueActiveTargetComponentIds = Array.from(activeTargetComponentIds)

    const componentMap =
      compensationTemplate ?
        compensationTemplate.components.reduce(
          (acc, component) => {
            if (component.id != null) {
              acc[component.id] = component
            }
            return acc
          },
          {} as Record<string, Component>
        )
      : {}

    const planTargets = targetDTOs
      .filter(
        target => componentMap[target.componentId] != null && !componentMap[target.componentId].isOrganizationLevel
      )
      .filter(target => {
        return plan.variableAllocations.some(table => {
          return table.rows.some(row => {
            return (
              row.formulaRequirements.some(formula => {
                if (formula.includes(target.slug)) {
                  return true
                }
                return false
              }) ||
              row.formulaOptions.some(formula => {
                if (formula.includes(target.slug)) {
                  return true
                }
                return false
              })
            )
          })
        })
      })
      .map(target => {
        target.userId = plan.userId

        if (target.value != null) {
          target.value = target.value.toString()
        }
        return target
      })

    const planComponentIds = compensationTemplate?.components
      .filter(component => {
        return plan.variableAllocations.some(table => {
          return table.rows.some(row => {
            return (
              row.formulaRequirements.some(formula => {
                if (formula.includes(component.slug)) {
                  return true
                }
                return false
              }) ||
              row.formulaOptions.some(formula => {
                if (formula.includes(component.slug)) {
                  return true
                }
                return false
              })
            )
          })
        })
      })
      .map(component => component.id)
      .filter((id): id is string => id != null)

    const targetComponentIds = planTargets.map(target => target.componentId)

    const allComponentIds = [
      ...new Set([...(planComponentIds ?? []), ...uniqueActiveTargetComponentIds, ...targetComponentIds]),
    ]

    const tempPlan: CompensationPlanCreateDTO = {
      plan,
      components: allComponentIds != null ? allComponentIds : [],
      targets: planTargets,
    }

    tempPlan.plan.templateId = compensationTemplate?.template.id ?? null
    tempPlan.plan.templateRevision = compensationTemplate?.template.revision ?? null

    createPlan(token!, tempPlan)
      .then(() => {
        setSnackbar({ open: true, message: 'Plan created successfully!', severity: 'success' })
        React.startTransition(() => {
          navigate('/admin/templates')
        })
      })
      .catch(() => {
        setSnackbar({ open: true, message: 'Failed to create plan.', severity: 'error' })
      })
      .finally(() => {
        setIsMutating(false)
      })
  }

  const handleCancelConfirmation = () => {
    setOpenCancelDialog(false)
    navigate('/admin/templates')
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <>
        <div>
          <Stepper
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
          >
            {stepLabels.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {getStepContent(activeStep)}

          <Box mt={3}>
            <Stack
              direction='row'
              justifyContent='space-between'
            >
              <AnimateButton>
                <Button
                  disabled={isMutating}
                  variant='contained'
                  color='warning'
                  onClick={() => {
                    setOpenCancelDialog(true)
                  }}
                >
                  Cancel
                </Button>
              </AnimateButton>

              <Stack
                direction='row'
                spacing={2}
              >
                {activeStep !== 0 ?
                  <AnimateButton>
                    <Button
                      variant='outlined'
                      disabled={isMutating}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  </AnimateButton>
                : <div></div>}
                <AnimateButton>
                  <Button
                    variant='contained'
                    disabled={!validateStep() || isMutating}
                    onClick={activeStep === 3 ? savePlan : handleNext}
                  >
                    {activeStep === 3 ?
                      'Save Plan'
                    : activeStep === 2 ?
                      'Review'
                    : 'Next'}
                  </Button>
                </AnimateButton>
              </Stack>
            </Stack>
          </Box>
        </div>

        <Dialog
          open={openCancelDialog}
          onClose={() => setOpenCancelDialog(false)}
        >
          <DialogTitle>Confirm cancellation</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to cancel building this plan?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCancelDialog(false)}>No</Button>
            <Button
              onClick={handleCancelConfirmation}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </>
    </Suspense>
  )
}

const LoadingSpinner: FC = () => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    height='100vh'
  >
    <CircularProgress />
  </Box>
)
export default CreatePlanWizard
