import { useTheme } from '@mui/material/styles'
import type { ApexOptions } from 'apexcharts'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ThemeMode } from 'types/config'

interface CompensationChartProps {
  salary: number
  variable: number
  currency?: string
  currencyDecimals?: number
}

const CompensationChart: FC<CompensationChartProps> = ({
  salary,
  variable,
  currency = 'USD',
  currencyDecimals = 2,
}: CompensationChartProps) => {
  const theme = useTheme()
  const [chartOptions, setChartOptions] = useState<ApexOptions>({})
  const [series, setSeries] = useState<ApexNonAxisChartSeries>([0, 0])

  useEffect(() => {
    const chartColors =
      theme.palette.mode === ThemeMode.LIGHT
        ? [theme.palette.primary.light, theme.palette.warning.main]
        : [theme.palette.info.light, theme.palette.primary.main]

    const options: ApexOptions = {
      chart: {
        type: 'donut',
        animations: {
          enabled: true,
        },
      },
      colors: chartColors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                color: theme.palette.text.primary,
                fontSize: '1rem',
                fontWeight: 'bold',
                showAlways: true,
                show: true,
                formatter(w) {
                  try {
                    if (!w?.globals?.seriesTotals?.length) return formatCurrency(0, currency, currencyDecimals)
                    const total = w.globals.seriesTotals.reduce((a: number, b: number) => a + (isNaN(b) ? 0 : b), 0)
                    return formatCurrency(total, currency, currencyDecimals)
                  } catch (error) {
                    console.error('Error in total formatter:', error)
                    return formatCurrency(0, currency, currencyDecimals)
                  }
                },
              },
              value: {
                color: theme.palette.text.primary,
                fontSize: '1rem',
                show: true,
              },
              name: {
                color: theme.palette.text.primary,
                fontSize: '1rem',
                show: true,
              },
            },
          },
        },
      },
      legend: {
        position: 'bottom',
        fontSize: '14px',
        labels: {
          colors: theme.palette.text.primary,
        },
        formatter(val, opts) {
          try {
            const seriesValue = opts?.w?.globals?.series?.[opts.seriesIndex]
            if (typeof seriesValue === 'number' && !isNaN(seriesValue)) {
              return `${val} - ${formatCurrency(seriesValue, currency, currencyDecimals)}`
            }
            return `${val} - N/A`
          } catch (error) {
            console.error('Error in legend formatter:', error)
            return `${val} - N/A`
          }
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter(val) {
            try {
              if (typeof val === 'number' && !isNaN(val)) {
                return formatCurrency(val, currency, currencyDecimals)
              }
              return 'N/A'
            } catch (error) {
              console.error('Error in tooltip formatter:', error)
              return 'N/A'
            }
          },
        },
      },
      labels: ['Base', 'Variable'],
    }

    let tempSeries: ApexNonAxisChartSeries = [salary, variable]

    setChartOptions(options)
    setSeries(tempSeries)
  }, [salary, variable, theme.palette.mode])

  const formatCurrency = (value: number, cur: string, dec: number) => {
    if (typeof value !== 'number' || isNaN(value)) return `${cur} 0`

    try {
      return new Intl.NumberFormat('default', {
        style: 'currency',
        currency: cur,
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
      }).format(value)
    } catch (error) {
      console.error('Error formatting currency:', error)
      return `${cur} ${value.toFixed(dec)}`
    }
  }

  return (
    <>
      <ReactApexChart
        key={`apex-salary-chart-${series.join('-')}`}
        options={chartOptions}
        series={series}
        type="donut"
      />
    </>
  )
}

export default CompensationChart
