import { Container, Typography } from '@mui/material'
import Card from 'components/@extended/Card'
import { FC } from 'react'

const NotActiveUser: FC = () => {
  return (
    <Container
      maxWidth='sm'
      sx={{ mt: 16 }}
    >
      <Card sx={{ p: 4, textAlign: 'center' }}>
        <Typography
          variant='h4'
          component='h1'
          gutterBottom
        >
          Account Not Active
        </Typography>
        <Typography
          variant='body1'
          paragraph
        >
          Your account has been setup by your administrator, but has not yet been activated.
        </Typography>
        <Typography
          variant='body2'
          color='text.secondary'
        >
          Thank you for your patience!
        </Typography>
      </Card>
    </Container>
  )
}

export default NotActiveUser
