import useSWR, { mutate } from 'swr'
import type { PeriodOverviewsParams, PeriodOverviewsResponse } from 'types/dashboard'
import { postWithToken } from './http'

export function usePeriodOverviews(token: string, params: PeriodOverviewsParams, shouldFetch: boolean) {
  const key = shouldFetch ? ['period-overviews', token, JSON.stringify(params)] : null

  const { data, error, isValidating } = useSWR(
    key,
    async ([url, token, paramsString]) => {
      const parsedParams = JSON.parse(paramsString)
      return postWithToken(url, token, parsedParams)
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const revalidate = () => {
    if (key) {
      mutate(key)
    }
  }

  return {
    periodOverviews: data as PeriodOverviewsResponse,
    isLoading: !data && isValidating,
    isError: !!error,
    revalidate,
  }
}
