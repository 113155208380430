import { FormControl, InputLabel, MenuItem, Select, Stack, useTheme } from '@mui/material'
import { RichTreeView } from '@mui/x-tree-view'
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks'
import type { TreeViewBaseItem } from '@mui/x-tree-view/models'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { ThemeMode } from 'types/config'
import type { Organization } from 'types/organization'
import type { UserProfile } from 'types/user-profile'
import { getUniqueOrganizationRoles } from 'types/user-profile'
import { TextField } from '../@extended/TextField'
import './SingleOrganizationSelector.css'

interface SingleOrganizationSelectorProps {
  userProfile: UserProfile
  selectedOrganizationId: string
  handleChange: (selected: string) => void
  className?: string
  style?: React.CSSProperties
  width?: string | number
  label?: string
  showLabelAbove?: boolean
  disabled?: boolean
  memberRolesOnly?: boolean
  space?: number
  variant?: 'standard' | 'outlined'
  labelColor?: string
  labelBackgroundColor?: string
}

const SingleOrganizationSelector: FC<SingleOrganizationSelectorProps> = ({
  userProfile,
  selectedOrganizationId,
  handleChange,
  className,
  style,
  width = '100%',
  label = 'Select Team',
  showLabelAbove = false,
  disabled = false,
  memberRolesOnly = false,
  space = 0,
  variant = 'outlined',
  labelColor = undefined,
  labelBackgroundColor = undefined,
}) => {
  const theme = useTheme()
  const apiRef = useTreeViewApiRef()
  const [open, setOpen] = useState(false)

  const uniqueUserRoles = getUniqueOrganizationRoles(userProfile, memberRolesOnly)

  const handleItemSelectionToggle = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
    if (isSelected) {
      handleChange(itemId)
      setOpen(false) // Close the dropdown after selection
    }
  }

  const renderTree = (nodes: Organization[]): TreeViewBaseItem[] => {
    const organizationMap = new Map(uniqueUserRoles.map(role => [role.organization.id, role.organization]))

    const isTopLevelOrg = (org: Organization) => !org.parentId || !organizationMap.has(org.parentId)

    const buildTree = (org: Organization): TreeViewBaseItem => ({
      id: org.id,
      label: org.name,
      children: uniqueUserRoles
        .filter(role => role.organization.parentId === org.id)
        .map(role => buildTree(role.organization))
        .sort((a, b) => a.label.localeCompare(b.label)),
    })

    return nodes
      .filter(isTopLevelOrg)
      .map(buildTree)
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  const rootOrganizations = uniqueUserRoles
    .map(role => role.organization)
    .filter(org => !org.parentId || !uniqueUserRoles.some(role => role.organization.id === org.parentId))

  const firstRootItemId = rootOrganizations[0]?.id

  const getSelectedOrganizationName = () => {
    const selectedOrg = uniqueUserRoles.find(role => role.organization.id === selectedOrganizationId)
    return selectedOrg ? selectedOrg.organization.name : label
  }

  const getSelectedOrganizationNameWithId = (id: string) => {
    const selectedOrg = uniqueUserRoles.find(role => role.organization.id === id)
    return selectedOrg ? selectedOrg.organization.name : label
  }

  const hoverColor = theme.palette.mode === ThemeMode.DARK ? theme.palette.action.hover : theme.palette.action.hover

  useEffect(() => {
    if (selectedOrganizationId === '' && firstRootItemId) {
      handleChange(firstRootItemId)
    }
  }, []) // Empty dependency array means this runs once on mount

  return disabled ?
      <>
        <Stack
          spacing={space}
          sx={{ flexGrow: 1 }}
        >
          <TextField
            key='employee-organization-select'
            fullWidth
            variant='outlined'
            label={label}
            pill
            shrink
            slotProps={{
              input: {
                readOnly: true,
              },
            }}
            value={getSelectedOrganizationNameWithId(selectedOrganizationId)}
            sx={{
              '& .MuiInputBase-input': {
                color: 'text.secondary',
              },
            }}
          />
        </Stack>
      </>
    : <div
        className={className}
        style={{ ...style, marginBottom: 0, paddingBottom: 0, width }}
      >
        <FormControl
          variant='outlined'
          sx={{ width: '100%' }}
        >
          <InputLabel
            shrink
            variant={variant}
            sx={{
              '&.MuiInputLabel-shrink': {
                background: labelBackgroundColor || undefined,
                color: labelColor || undefined,
              },
            }}
          >
            {label}
          </InputLabel>
          <Select
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={selectedOrganizationId}
            renderValue={getSelectedOrganizationName}
            style={{ width: '100%', borderRadius: '21px' }}
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            label={showLabelAbove ? undefined : label}
            disabled={disabled}
          >
            <MenuItem
              value={selectedOrganizationId}
              style={{ display: 'none' }}
            ></MenuItem>
            <RichTreeView
              multiSelect={false}
              checkboxSelection
              style={{ width: '100%' }}
              apiRef={apiRef}
              items={renderTree(rootOrganizations)}
              selectedItems={selectedOrganizationId}
              onItemSelectionToggle={handleItemSelectionToggle}
              defaultExpandedItems={firstRootItemId ? [firstRootItemId] : []}
              sx={{
                '& .MuiTreeItem-content:hover': {
                  backgroundColor: hoverColor,
                },
                '& .MuiTreeItem-content.Mui-selected:hover': {
                  backgroundColor: theme.palette.action.selected,
                },
              }}
            />
          </Select>
        </FormControl>
      </div>
}

export default SingleOrganizationSelector
