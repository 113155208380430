import { parseISO } from 'date-fns'
import type { ID, Label, OrganizationIDs, PaginationInfo, Timestamps } from './common'

type DateFormat = string // Expected format: MM-DD-YYYY

export interface Period extends ID, Omit<OrganizationIDs, 'rootOrganizationId'>, Label, Timestamps {
  rootPeriodId?: string
  periodProration?: number
  startDate: DateFormat
  endDate: DateFormat
  children: Period[]
  isClosed: boolean
  status?: string
}

export interface PeriodsResponse {
  periods: Period[]
  paginationInfo: PaginationInfo
}

export function getOrderedLabels(period: Period): string[] {
  const labels: string[] = []
  const queue: Period[] = [period]

  while (queue.length > 0) {
    const currentPeriod = queue.shift()!
    labels.push(currentPeriod.label)

    if (currentPeriod.children) {
      queue.push(...currentPeriod.children)
    }
  }

  return labels
}

export function createIdToPeriod(period: Period): Record<string, Period> {
  if (!period || typeof period !== 'object') {
    console.error('Invalid period data:', period)
    return {}
  }

  const result: Record<string, Period> = {}

  const traverse = (p: Period) => {
    if (p.id) {
      result[p.id] = p
    }
    if (p.children && Array.isArray(p.children)) {
      p.children.forEach(traverse)
    }
  }

  traverse(period)
  return result
}

export function createLabelToIdMap(period: Period): Record<string, string> {
  const map: Record<string, string> = {}

  const traverse = (period: Period) => {
    map[period.label] = period.id || ''
    if (period.children && period.children.length > 0) {
      period.children.forEach(traverse)
    }
  }

  traverse(period)
  return map
}

export function createIdLabelMap(period: Period): Record<string, string> {
  const map: Record<string, string> = {}

  const traverse = (period: Period) => {
    if (period.id) {
      map[period.id] = period.label || ''
    }
    if (period.children && period.children.length > 0) {
      period.children.forEach(traverse)
    }
  }

  traverse(period)
  return map
}

export function createMergedIdLabelMap(periods: Period[]): Record<string, string> {
  const map: Record<string, string> = {}

  // Process each period and merge the results
  periods.forEach(period => {
    const periodMap = createIdLabelMap(period)
    Object.assign(map, periodMap)
  })

  return map
}

export function parsePeriodDate(date: string): Date {
  const dateParts = date.split('-')
  const length = dateParts[0].length
  if (length !== 4) {
    const formattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
    return parseISO(formattedDate)
  }
  return parseISO(date)
}

export function createPeriodToRootPeriodMap(periods: Period[]): Record<string, string> {
  const map: Record<string, string> = {}

  const traverse = (period: Period, rootPeriodId: string) => {
    if (period.id) {
      map[period.id] = rootPeriodId
    }
    if (period.children && period.children.length > 0) {
      period.children.forEach(child => traverse(child, rootPeriodId))
    }
  }

  periods.forEach(rootPeriod => {
    if (rootPeriod.id) {
      traverse(rootPeriod, rootPeriod.id)
    }
  })

  return map
}

function differenceInDays(startDate: Date, endDate: Date): number {
  const oneDay = 24 * 60 * 60 * 1000
  const diffDays = Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay))
  return diffDays
}

function getDaysInPeriod(period: Period): number {
  const startDate = parsePeriodDate(period.startDate)
  const endDate = parsePeriodDate(period.endDate)
  return differenceInDays(endDate, startDate)
}

export function createIdDaysMap(period: Period): Record<string, number> {
  const map: Record<string, number> = {}

  const traverse = (period: Period) => {
    if (period.id) {
      map[period.id] = getDaysInPeriod(period)
    }
    if (period.children && period.children.length > 0) {
      period.children.forEach(traverse)
    }
  }

  traverse(period)
  return map
}

export function createIdToPeriodFromList(periods: Period[]): Record<string, Period> {
  const result: Record<string, Period> = {}

  const traverse = (period: Period, rootPeriodId: string, proration: number): Period => {
    const { children, ...periodWithoutChildren } = period
    const updatedChildren = children?.map(child => traverse(child, rootPeriodId, proration / children.length)) || []

    const updatedPeriod: Period = {
      ...periodWithoutChildren,
      rootPeriodId,
      children: updatedChildren,
      periodProration: proration,
    }

    if (period.id) {
      result[period.id] = updatedPeriod
    }

    return updatedPeriod
  }

  periods.forEach(rootPeriod => {
    if (rootPeriod.id) {
      traverse(rootPeriod, rootPeriod.id, 1.0) // Root period starts with proration of 1.0
    }
  })

  return result
}

export function getFlattenedSubtree(periods: Period[], rootPeriodId: string, periodId: string): Period[] {
  const result: Period[] = []

  if (!rootPeriodId || !periodId || !periods || rootPeriodId === '' || periodId === '') {
    return result
  }

  const findSubtree = (period: Period): Period | null => {
    if (period.id === periodId && period.rootPeriodId === rootPeriodId) {
      return period
    }
    for (const child of period.children) {
      const found = findSubtree(child)
      if (found) {
        return found
      }
    }
    return null
  }

  const flattenSubtree = (period: Period) => {
    const { children, ...periodWithoutChildren } = period
    result.push({ ...periodWithoutChildren, children: [] })
    for (const child of children) {
      flattenSubtree(child)
    }
  }

  for (const rootPeriod of periods) {
    if (rootPeriod.id === rootPeriodId) {
      const subtree = findSubtree(rootPeriod)
      if (subtree) {
        flattenSubtree(subtree)
        break
      }
    }
  }

  return result
}

export function findPeriodById(periods: Period[], periodId: string): Period | null {
  for (const period of periods) {
    if (period.id === periodId) {
      return period
    }
    if (period.children && period.children.length > 0) {
      const found = findPeriodById(period.children, periodId)
      if (found) {
        return found
      }
    }
  }
  return null
}

export interface ReopenPeriodRequest {
  rootOrganizationId: string
  rootPeriodId: string
  periodsToOpen: string[]
}

export interface LockPeriodRequest {
  rootOrganizationId: string
  rootPeriodId: string
  periodsToLock: string[]
}

export function getClosedPeriodIds(periods: Period[]): string[] {
  const closedIds: string[] = []

  const traverse = (period: Period) => {
    if (period.id && period.isClosed) {
      closedIds.push(period.id)
    }
    if (period.children && period.children.length > 0) {
      period.children.forEach(traverse)
    }
  }

  periods.forEach(traverse)
  return closedIds
}
