import type { Theme } from '@mui/material'
import { Box, CardContent, Divider, Grid2 as Grid, Typography } from '@mui/material'
import Card from 'components/@extended/Card'
import Paper from 'components/@extended/Paper'
import VariableCompensationChart from 'components/VariableCompensationChart'
import React, { useEffect, useState } from 'react'
import type { ExpandedCompensationPlan } from 'types/comp-plan'
import type { MetricReductionResult } from 'types/components'
import { CompositeFunction, reduceMetricValue } from 'types/components'
import type { Period } from 'types/periods'
import { createIdToPeriodFromList } from 'types/periods'
import type { User } from 'types/user'
import ProgressOverview from './ProgressOverview'
import TargetRow from './TargetRow'
import VariableEarnedProgress from './VariableEarnedProgress'

interface EmployeeDashboardProps {
  user: User
  theme: Theme
  status: number
  plan: ExpandedCompensationPlan | undefined
}

const EmployeeDashboard: React.FC<EmployeeDashboardProps> = ({ theme, status, plan }) => {
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [periodMap, setPeriodMap] = useState<Record<string, Period>>({})

  // Set initial selected periods when plan is loaded
  useEffect(() => {
    if (plan) {
      // Get all period IDs from the plan
      const allPeriodIds = getAllPeriodIds(plan.period)
      setSelectedPeriods(allPeriodIds)

      // Create period map
      const periodList = [plan.period]
      const periodMap = createIdToPeriodFromList(periodList)
      setPeriodMap(periodMap)
    }
  }, [plan])

  // Helper function to get all period IDs from a period tree
  const getAllPeriodIds = (period: Period): string[] => {
    const ids: string[] = []
    if (period.id) {
      ids.push(period.id)
    }
    if (period.children) {
      period.children.forEach((child) => {
        ids.push(...getAllPeriodIds(child))
      })
    }
    return ids
  }

  useEffect(() => {
    if (plan) {
      console.log('Expanded Plan with Accruals:', plan)
    }
  }, [plan])

  if (!plan || !plan.period || status === 400) {
    return (
      <Grid size={{ xs: 12 }}>
        <Card>
          <CardContent>
            <Typography variant="h5">No valid compensation plan found</Typography>
            <Typography variant="body1">Please contact your manager or HR department for assistance.</Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const findMetricValue = (targetComponentId: string, targetPeriodId: string): MetricReductionResult => {
    if (!plan || !plan.metrics) {
      return {
        functionUsed: CompositeFunction.COMPOSITE_FUNCTION_NONE,
        rawValue: '',
        reducedValue: 0,
      }
    }

    const metric = plan.metrics.find((m) => m.componentId === targetComponentId && m.periodId === targetPeriodId)
    const comp = plan.components.find((c) => c.id === targetComponentId)

    console.log('metric', metric)
    console.log('comp', comp)

    if (metric && comp && metric.value) {
      return reduceMetricValue(metric, comp)
    }

    return {
      functionUsed: CompositeFunction.COMPOSITE_FUNCTION_NONE,
      rawValue: '',
      reducedValue: 0,
    }
  }

  const calculateProgress = (targetComponentId: string, targetValue: string, targetPeriodId: string): number => {
    const metricValue = findMetricValue(targetComponentId, targetPeriodId)
    const safeTargetValue = Number.parseFloat(targetValue) || 1
    const progress = (metricValue.reducedValue / safeTargetValue) * 100
    return progress
  }

  return !plan ? (
    <></>
  ) : (
    <Grid container spacing={2}>
      {/* Main content */}
      <Grid size={{ xs: 9 }}>
        <ProgressOverview key="progress-overview" plan={plan} selectedPeriods={selectedPeriods} periodMap={periodMap} />

        {/* Add Table Summaries */}
        {plan.plan.variableAllocations
          .filter((table) => {
            // Only show tables that have at least one row matching selected periods
            return table.rows.some((row) => selectedPeriods.includes(row.periodId as string))
          })
          .map((table) => (
            <Paper key={table.id} sx={{ mb: 2, px: 3, py: 3 }}>
              <Grid container spacing={2}>
                {/* Header Row */}
                <Grid size={{ xs: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Component
                  </Typography>
                </Grid>
                <Grid size={{ xs: 5 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {table.label}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 5 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Variable Compensation Earnings
                  </Typography>
                </Grid>

                {/* Divider */}
                <Grid size={{ xs: 12 }}>
                  <Divider />
                </Grid>

                {/* Table Rows */}
                {table.rows.map((row) => {
                  // Find matching ledger or progress entry
                  const ledgerEntry = plan.ledger?.find(
                    (entry) => entry.allocationRowId === row.id && entry.allocationTableId === table.id
                  )

                  const progressEntry = plan.progress?.find(
                    (accrual) => accrual.allocationRowId === row.id && accrual.allocationTableId === table.id
                  ) || { proratedPayoutAmount: 0 }

                  console.log('row', row)
                  console.log('periodMap', periodMap)

                  const period = periodMap[row.periodId as string]

                  // Use ledger if period is closed and ledger exists, otherwise use progress
                  const amount =
                    period.isClosed && ledgerEntry != null
                      ? ledgerEntry.balance || 0
                      : progressEntry.proratedPayoutAmount

                  const onTargetPayout = plan.plan.baseSalary * plan.plan.variablePercent * row.allocationPercentage

                  // Handle multiple components per row
                  const componentRows = (row.componentIds || [])
                    .map((componentId) => {
                      const target = plan.targets.find(
                        (t) => t.componentId === componentId && t.periodId === row.periodId
                      )
                      // Skip if target is not found
                      if (!target) return null

                      const progress = calculateProgress(
                        target.componentId || '',
                        target.value || '',
                        target.periodId || ''
                      )
                      const componentType = plan.components.find((c) => c.id === componentId)?.componentType || 'float'
                      const component = plan.components.find((c) => c.id === componentId)

                      return {
                        componentId,
                        label: component?.label || '',
                        target,
                        progress,
                        componentType,
                      }
                    })
                    .filter(Boolean) // Remove null entries

                  // Skip rendering if no valid component rows
                  if (componentRows.length === 0) return null

                  console.log('amount', amount, 'onTargetPayout', onTargetPayout)

                  const rowLableMargin = componentRows.length > 1 ? 4 : 1
                  const variableEarnedProgressMargin = componentRows.length > 1 ? 4.4 : 2

                  return (
                    <React.Fragment key={row.id}>
                      {componentRows
                        .filter((row) => row !== null)
                        .map((componentRow, index) => (
                          <React.Fragment key={`${row.id}-${componentRow.componentId}`}>
                            <Grid size={{ xs: 2 }}>
                              <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: rowLableMargin }}>
                                {index === 0 ? row.label : ''}
                              </Typography>
                            </Grid>
                            <Grid size={{ xs: 5 }}>
                              <Box sx={{ mt: 2, pr: 2 }}>
                                <TargetRow
                                  target={componentRow.target}
                                  findMetricValue={findMetricValue}
                                  calculateProgress={() => componentRow.progress}
                                  theme={theme}
                                  componentType={componentRow.componentType}
                                  hideLabel
                                  targetLabel={
                                    componentRows.length > 1
                                      ? `${periodMap[row.periodId as string]?.label || ''} - ${componentRow.label}`
                                      : undefined
                                  }
                                  periodLabel={periodMap[row.periodId as string]?.label || ''}
                                />
                              </Box>
                            </Grid>
                            <Grid size={{ xs: 5 }}>
                              {index === 0 && amount != null && (
                                <Box sx={{ mt: variableEarnedProgressMargin, pr: 2 }}>
                                  <VariableEarnedProgress
                                    earnedAmount={amount}
                                    targetAmount={onTargetPayout * plan.plan.periodProration}
                                    theme={theme}
                                    hideLabel
                                  />
                                </Box>
                              )}
                            </Grid>
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  )
                })}
              </Grid>
            </Paper>
          ))}
      </Grid>

      {/* Right sidebar */}
      <Grid size={{ xs: 3 }}>
        <Paper sx={{ mb: 2, py: 3, px: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Annual Variable Compensation Progress
          </Typography>
          <Box sx={{ mt: 3 }}>
            {(() => {
              const approvedAccruals = plan.progress || []

              // Calculate total earned considering both ledger and accruals
              const totalEarned = plan.plan.variableAllocations
                .flatMap((table) => table.rows)
                .filter((row) => selectedPeriods.includes(row.periodId!))
                .reduce((acc, row) => {
                  const ledgerEntry = plan.ledger?.find((entry) => entry.allocationRowId === row.id)
                  const progressEntry = approvedAccruals.find((accrual) => accrual.allocationRowId === row.id)
                  const amount =
                    ledgerEntry != null ? ledgerEntry.balance || 0 : progressEntry?.proratedPayoutAmount || 0
                  return acc + amount
                }, 0)

              // Calculate on-target total
              const onTargetTotal = plan.plan.variableAllocations
                .flatMap((table) => table.rows)
                .filter(
                  (row) => selectedPeriods.includes(row.periodId!) && row.rowType === 'CALCULATION_ROW' && !row.disabled
                )
                .reduce((acc, row) => {
                  const rowTarget = plan.plan.baseSalary * plan.plan.variablePercent * row.allocationPercentage
                  return acc + rowTarget
                }, 0)

              return (
                <VariableCompensationChart
                  variableEarned={totalEarned}
                  variableTotal={onTargetTotal}
                  currency="USD"
                  currencyDecimals={2}
                  showRemaining={false}
                />
              )
            })()}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default EmployeeDashboard
